import { FullscreenExit, Fullscreen } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'

const FullScreenButton = () => {
    const { fullScreen, dispatch } = React.useContext(VideoPlayerContext)
    if (fullScreen) return (
        <Box>
            < IconButton color='inherit' onClick={dispatch.exitFullScreen} >
                <FullscreenExit color='inherit' />
            </IconButton >
        </Box >
    )
    else return (
        <Box>
            <IconButton color='inherit' onClick={dispatch.fullScreen}>
                <Fullscreen color='inherit' />
            </IconButton>
        </Box>
    )
}

export default FullScreenButton