import { TabPanel } from '@mui/lab'
import React from 'react'
import InputField from '../../../InputField'
import { KanbanContext } from '../../useKanban'
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { KanbanCard } from '../../../../Stores/KanbanType'

const OverviewTab = () => {
    const { editedCard, dispatch, tags } = React.useContext(KanbanContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const [tag, setTag] = React.useState<string>('')

    const handleOpen = (event: any) => setAnchorEl(event.target)
    const handleAddTag = (tag: string) => {
        handleUpdate({ tags: [...editedCard!.tags ?? [], tag] })
        setAnchorEl(null)
        setOpen(false)
    }
    const handleDeleteTag = (tag: string) => handleUpdate({ tags: editedCard!.tags?.filter(t => t !== tag) })
    const handleCreateTag = () => {
        setOpen(true)
        setAnchorEl(null)
    }

    const handleClose = () => setOpen(false)

    const handleUpdate = (partial: Partial<KanbanCard>) => dispatch.setEditedCard({ ...editedCard!, ...partial })

    React.useEffect(() => {
        setTag('')
    }, [open])
    return (
        <TabPanel value='overview'>
            <Box display={'flex'} flexDirection={'column'} gap={4}>
                <Box display={'flex'} alignItems={'center'}>
                    <Box width={100}>
                        <Typography variant="caption" color="GrayText" >
                            Créer par
                        </Typography>
                    </Box>
                    <Box>
                        <Avatar sx={{ width: 30, height: 30, fontSize: 12 }}>PL</Avatar>
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box width={100}>
                        <Typography variant="caption" color="GrayText" >
                            Créer le
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='caption'>{editedCard && new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(editedCard?.createdAt))}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box width={100}>
                        <Typography variant="caption" color="GrayText" >
                            Mis à jour le
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='caption'>{editedCard && new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(editedCard?.updatedAt))}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box width={100}>
                        <Typography variant="caption" color="GrayText">
                            Tags
                        </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} gap={2} flexWrap={'wrap'} alignItems={'center'}>
                        {editedCard?.tags?.map(tag => <Chip label={tag} size='small' onDelete={() => handleDeleteTag(tag)} />)}
                        <IconButton size='small' onClick={handleOpen}><Add fontSize='small' /></IconButton>
                    </Box>
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        anchorPosition={undefined}
                        onClose={() => setAnchorEl(null)}
                    >
                        {tags.filter(tag => !editedCard!.tags?.includes(tag)).map(tag => <MenuItem onClick={() => handleAddTag(tag)}>{tag}</MenuItem>)}
                        {tags.filter(tag => !editedCard!.tags?.includes(tag)).length > 0 && <Divider />}
                        <MenuItem onClick={handleCreateTag}>Ajouter un tag</MenuItem>
                    </Menu>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Ajouter un tag</DialogTitle>
                        <DialogContent>
                            <InputField
                                autoFocus
                                value={tag}
                                onChange={({ target: { value } }) => setTag(value)}
                                margin="dense"
                                label="Nom du tag"
                                type="email"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Annuler</Button>
                            <Button onClick={() => handleAddTag(tag)}>Ajouter</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            <Divider sx={{ ml: -4, mr: -4 }} />
            <InputField
                fullWidth
                multiline
                minRows={3}
                value={editedCard!.description}
                onChange={({ target: { value: description } }) => handleUpdate({ description })}
                label='Description'
                sx={{ mt: 2 }}
            />
        </TabPanel>
    )
}

export default OverviewTab