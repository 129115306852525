import { Image } from '@mui/icons-material'
import { Avatar, Dialog, IconButton, ListItemAvatar, ListItemText, ListSubheader, Menu, MenuItem, MenuList, Tooltip } from '@mui/material'
import React from 'react'
import { UseEpEditContext } from '../useEpsEdit'
import FileType from '../../../../../Stores/FileType'

const ImageList = () => {
    const { ep } = React.useContext(UseEpEditContext)
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
    const [file, setFile] = React.useState<null | FileType>(null)
    const hasFiles = [...ep?.gdsFiles ?? [], ...ep?.radioFiles ?? [], ...ep?.respiFiles ?? [], ...ep?.scopeFiles ?? []].length > 0

    const handleClick = (file: FileType) => {
        setFile(file)
        setAnchor(null)
    }
    return (
        <>
            <Tooltip title={hasFiles ? 'Liste des images' : 'Aucune image'}>
                <span>
                    <IconButton
                        onClick={event => setAnchor(event.currentTarget)} color={Boolean(anchor) ? 'primary' : undefined}
                        disabled={!hasFiles}
                    >
                        <Image />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ maxHeight: 200 }}
                onClick={console.log}
            >
                {ep!.gdsFiles.length > 0 && <>
                    <ListSubheader>Gazométrie</ListSubheader>
                    {ep!.gdsFiles.map(f => <MenuItem key={f.id} onClick={() => handleClick(f)}>
                        <ListItemAvatar>
                            <Avatar variant="square" src={`/download/${f.id}`} />
                        </ListItemAvatar>
                        <ListItemText primary={f.name} />
                    </MenuItem>)}
                </>}
                {ep!.radioFiles.length > 0 && <>
                    <ListSubheader>Imagerie</ListSubheader>
                    {ep!.radioFiles.map(f => <MenuItem key={f.id} onClick={() => handleClick(f)}>
                        <ListItemAvatar>
                            <Avatar variant="square" src={`/download/${f.id}`} />
                        </ListItemAvatar>
                        <ListItemText primary={f.name} />
                    </MenuItem>)}
                </>}
                {ep!.respiFiles.length > 0 && <>
                    <ListSubheader>Respirateur</ListSubheader>
                    {ep!.respiFiles.map(f => <MenuItem key={f.id} onClick={() => handleClick(f)}>
                        <ListItemAvatar>
                            <Avatar variant="square" src={`/download/${f.id}`} />
                        </ListItemAvatar>
                        <ListItemText primary={f.name} />
                    </MenuItem>)}
                </>}
                {ep!.scopeFiles.length > 0 && <>
                    <ListSubheader>Monitorage</ListSubheader>
                    {ep!.scopeFiles.map(f => <MenuItem key={f.id} onClick={() => handleClick(f)}>
                        <ListItemAvatar>
                            <Avatar variant="square" src={`/download/${f.id}`} />
                        </ListItemAvatar>
                        <ListItemText primary={f.name} />
                    </MenuItem>)}
                </>}

            </Menu >
            <Dialog
                open={Boolean(file)}
                onClose={() => setFile(null)}
            >
                {file && <img src={`/download/${file.id}`} style={{ maxWidth: '100vw', maxHeight: '100vh' }} />}
            </Dialog>
        </>
    )
}

export default ImageList