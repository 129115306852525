import { Add, Check, Close, Delete, DragHandle } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Typography } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { v4 } from 'uuid'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DocumentContext } from '../useDocument'
import { QuestionType, ResponseType } from '../../../../../Stores/CoursType'



interface CreateQuestionProps {
    slideNumber: number
}
const CreateQuestion = ({
    slideNumber
}: CreateQuestionProps) => {
    const { dispatch } = React.useContext(DocumentContext)
    const [open, setOpen] = React.useState(false)
    const [question, setQuestion] = React.useState<Omit<QuestionType, 'id' | 'createdAt' | 'updatedAt' | 'coursId'>>({ question: '', reponses: [], type: 0, slideNumber })

    const handleAddQuestion = () => setQuestion(prev => ({
        ...prev,
        reponses: [...prev.reponses, { id: v4(), value: '', correct: false }]
    }))

    const onDragEnd = (event: DragEndEvent) => {
        console.log(event.over)

        const activeId = event.active.data.current!.sortable.index
        const overId = event.over!.data.current!.sortable.index
        setQuestion(prev => ({
            ...prev,
            reponses: arrayMove(prev.reponses, activeId as number, overId as number)
        }))
    }

    const handleCreateQuestion = () => {
        dispatch.createQuestion(question)
        setOpen(false)
    }

    React.useEffect(() => {
        setQuestion({ question: '', reponses: [], type: 0, slideNumber })
    }, [open])
    return (
        <React.Fragment>
            <IconButton onClick={() => setOpen(true)}>
                <Add />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="lg">
                <DialogTitle>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography>
                            Créer une question
                        </Typography>
                        <Box>
                            <IconButton onClick={() => setOpen(false)}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <InputField
                            label="Enoncé de la question"
                            value={question.question}
                            onChange={({ target: { value } }) => setQuestion(prev => ({ ...prev, question: value }))}
                            multiline
                            fullWidth
                        />
                        <InputField
                            label="Type de question"
                            value={question.type}
                            onChange={({ target: { value } }) => setQuestion(prev => ({ ...prev, type: +value, reponses: +value === 2 ? [{ id: v4(), value: 'Vrai', correct: false }, { id: v4(), value: 'Faux', correct: false }] : [] }))}
                            fullWidth
                            error={question.type === 0 && question.reponses.filter(el => el.correct).length > 1}
                            select
                        >
                            {['Choix unique', 'Choix multiple', 'Vrai/Faux'].map((el, i) => <MenuItem key={i} value={i}>{el}</MenuItem>)}
                        </InputField>
                        <Divider />
                        <Box>
                            <Button onClick={handleAddQuestion}>
                                Ajouter une réponse
                            </Button>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                            <DndContext
                                onDragEnd={onDragEnd}
                            >
                                <SortableContext
                                    items={question.reponses.map((el, i) => el.id)}
                                >
                                    {question.reponses.map((el, i) => <QuestionItem reponse={el} setQuestion={setQuestion} key={i} />)}
                                </SortableContext>
                            </DndContext>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        Annuler
                    </Button>
                    <Button disabled={
                        !question.question ||
                        (question.type === 0 && question.reponses.filter(el => el.correct).length > 1) ||
                        question.reponses.filter(el => el.correct).length === 0 ||
                        question.reponses.filter(el => !el.value).length > 0
                    } onClick={handleCreateQuestion}>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}
interface QuestionItemProps {
    reponse: ResponseType
    setQuestion: React.Dispatch<React.SetStateAction<Omit<QuestionType, 'id' | 'createdAt' | 'updatedAt' | 'coursId'>>>
}
const QuestionItem = ({
    reponse,
    setQuestion
}: QuestionItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: reponse.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };

    const handleChangeReponse = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(prev => ({
            ...prev,
            reponses: prev.reponses.map(el => el.id === reponse.id ? ({ ...el, value }) : el)
        }))
    }

    const handleCorrect = () => {
        setQuestion(prev => ({
            ...prev,
            reponses: prev.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: true }) : el)
        }))
    }
    const handleInCorrect = () => {
        setQuestion(prev => ({
            ...prev,
            reponses: prev.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: false }) : el)
        }))
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
            style={style}
            ref={setNodeRef}
            {...attributes}
            sx={{
                '& .hoverable': { visibility: 'hidden' },
                '&:hover .hoverable': { visibility: 'visible' },
                '& .hoverable[data-byPass="true"]': { visibility: 'visible' },
            }}>
            <Box className={'hoverable'}>
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                <InputField
                    label="Enoncé de la reponse"
                    value={reponse.value}
                    onChange={handleChangeReponse}
                    multiline
                    fullWidth
                />
            </Box>
            <Box >
                <IconButton className={'hoverable'} data-byPass={reponse.correct} color={reponse.correct ? 'primary' : undefined} onClick={handleCorrect}>
                    <Check />
                </IconButton>
                <IconButton className={'hoverable'} data-byPass={!reponse.correct} color={!reponse.correct ? 'secondary' : undefined} onClick={handleInCorrect}>
                    <Close />
                </IconButton>
            </Box>
            <Box>
                <IconButton color={'error'} className={'hoverable'} onClick={() => setQuestion(prev => ({ ...prev, reponses: prev.reponses.filter(el => el.id !== reponse.id) }))}>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    )
}

export default CreateQuestion