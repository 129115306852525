import React from 'react'
import Page from '../../Components/PageInterface'
import ChuvLogo from '../../assets/favicon.ico'

const CHUVDashboard = () => {
    return (
        <Page
            loading={false}
            title={'Dashboard CHUV'}
            breadcrumb={[
                { name: 'CHUV', url: '/chuv', src: ChuvLogo }
            ]} />
    )
}

export default CHUVDashboard