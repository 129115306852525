import React from 'react'
import { VideoPlayerProps } from './types'
import useVideoPlayer, { VideoPlayerContext } from './useVideoPlayer'
import { Box } from '@mui/material'
import MainContainer from './MainContainer'


const VideoPlayer = ({
    videoId
}: VideoPlayerProps) => {
    const contextValue = useVideoPlayer({
        videoId
    })
    return (
        <VideoPlayerContext.Provider value={contextValue}>
            <MainContainer />
        </VideoPlayerContext.Provider>
    )
}

export default VideoPlayer