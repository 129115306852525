import { Autocomplete, Box, IconButton, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { InvoiceEditContext } from './useInvoiceEdit'
import InputField from '../../../Components/InputField'
import InvoiceClientRep from '../InvoiceClientRep'
import { Delete } from '@mui/icons-material'

const ClientSelector = () => {
    const { invoice, invoiceClients, dispatch } = React.useContext(InvoiceEditContext)
    return (
        invoice!.client
            ? <Box display={'flex'} width={'100%'} alignItems={'center'}>
                <Box>
                    <IconButton onClick={() => dispatch.setClient(null)}>
                        <Delete />
                    </IconButton>
                </Box>
                <Box flexGrow={1}>
                    <InvoiceClientRep invoiceClient={invoice!.client} />
                </Box>
            </Box>
            : <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", gap: 2, flexWrap: 'nowrap' }}>
                <Autocomplete
                    disablePortal
                    options={invoiceClients ?? []}
                    fullWidth
                    renderInput={(params) => <InputField  {...params} label="Client" fullWidth />}
                    renderOption={(props, option) => <MenuItem {...props}><InvoiceClientRep invoiceClient={option} /></MenuItem>}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value, reason) =>
                        (reason === 'clear' && dispatch.setClient(null)) || (reason === 'selectOption' && dispatch.setClient(value))
                    }
                />
            </Box>
    )
}

export default ClientSelector