import { useMemo } from "react";
import CoursType, { QuestionType } from "../../../../Stores/CoursType";
import React from "react";
import { AppContext } from "../../../../App";
import FileType from '../../../../Stores/FileType'
import ThematicType from "../../../../Stores/ThematicType";
import IntervenantType from "../../../../Stores/IntervenantType";
import { arrayMove } from "@dnd-kit/sortable";

type useDocumentType = (id: CoursType['id']) => useDocumentContextType
type useDocumentContextType = {
    cours: CoursType | undefined
    openQuizzDrawer: boolean
    activeSlide: number
    dispatch: useDocumentDispatchType
}
type useDocumentDispatchType = {
    deleteCours: () => Promise<void>
    updateField: (field: Extract<keyof CoursType, string | number>, value: string | number | string[]) => Promise<CoursType>
    addFile: (file: FileType) => void
    selectFile: (file: FileType) => Promise<void>
    unSelectFile: () => Promise<void>
    deleteFile: (file: FileType) => void
    setComment: (position: number, value: string, isFavorite: boolean) => Promise<void>
    setThematic: (thematic: ThematicType) => Promise<void>
    createIntervenant: (firstname: IntervenantType['firstname'], lastname: IntervenantType['lastname'], email: IntervenantType['email']) => Promise<void>
    addIntervenant: (intervenant: IntervenantType) => Promise<void>
    setIntervenantImage: (id: string, file: FileType) => void
    removeIntervenant: (intervenant: IntervenantType) => Promise<void>
    addObjectif: () => Promise<void>
    sortObjectifs: (oldIndex: number, newIndex: number) => Promise<void>
    updateObjectif: (index: number, value: string) => Promise<void>
    removeObjectif: (index: number) => Promise<void>
    selectStream: (file: FileType) => Promise<void>
    removeStream: () => Promise<void>
    setFavorite: (id: string, isFavorite: boolean) => Promise<void>
    createQuestion: (question: {}) => Promise<void>
    deleteQuestion: (questionId: string) => Promise<void>
    saveQuestion: (question: QuestionType) => Promise<void>

    toggleQuizzDrawer: () => void
    setActiveSlide: (slide: number) => void
}

export const DocumentContext = React.createContext<useDocumentContextType>({} as useDocumentContextType)

const useDocument: useDocumentType = (id) => {
    const { fetch, dispatch: appDispatch } = React.useContext(AppContext)
    const [cours, setCours] = React.useState<CoursType | undefined>()
    const [openQuizzDrawer, setOpenQuizzDrawer] = React.useState(false)
    const [activeSlide, setActiveSlide] = React.useState<number>(0)


    const sC = useMemo(() => (c: (prev: CoursType) => CoursType) => {
        setCours(prev => c(prev!))
    }, [cours])

    const dispatch: useDocumentDispatchType = useMemo(() => ({
        deleteCours: () => fetch(`/api/admin/cours/${id}`, 'DELETE').then(console.log),
        updateField: (field, value) => fetch(`/api/admin/cours/${id}`, 'PATCH', { [field]: value }).then((el) => {
            sC(prev => ({ ...prev, [field]: el[field] }))
            console.log(el)
            return el
        }),
        addFile: (file: FileType) => sC(prev => ({ ...prev, files: [...prev.files, file] })),
        deleteFile: (file: FileType) => sC(prev => ({ ...prev, files: prev.files.filter(f => f.id !== file.id) })),
        selectFile: (file: FileType) => dispatch.updateField('fileId', file.id)
            .then((el) => sC(prev => ({ ...prev, file: el.file, fileId: el.file?.id ?? null }))),
        unSelectFile: () => fetch(`/api/admin/cours/${id}`, 'PATCH', { fileId: null })
            .then((el) => {
                sC(prev => ({ ...prev, fileId: el.fileId, file: el.file }))
                return el
            }),
        setComment: async (pos, value, isFavorite) => {
            const newComment = cours?.comments.find(c => c.id === pos)
                ? cours!.comments.map(c => c.id === pos ? ({ ...c, value, isFavorite }) : c)
                : [...cours!.comments, { id: pos, value, isFavorite }]
            return fetch(`/api/admin/cours/${id}`, 'PATCH', { comments: newComment }).then((el) => {
                sC(prev => ({ ...prev, comments: el.comments, isFavorite: el.isFavorite }))
                return el
            })
        },
        setThematic: (thematic) => fetch(`/api/admin/cours/${id}`, 'PATCH', { thematicId: thematic.id })
            .then((el) => {
                sC(prev => ({ ...prev, thematicId: el.thematicId, thematic: el.thematic }))
                return el
            }),
        createIntervenant: (firstname, lastname, email) => fetch(`/api/admin/cours/${id}/intervenants/create`, 'POST', { firstname, lastname, email })
            .then(el => {
                appDispatch.intervenants.post(el)
                sC(prev => ({ ...prev, intervenants: [...prev.intervenants, el] }))
            }),
        addIntervenant: (intervenant) => fetch(`/api/admin/cours/${id}/intervenants`, 'POST', { id: intervenant.id })
            .then((el) => {
                sC(prev => ({ ...prev, intervenants: [...prev.intervenants, intervenant] }))
                return el
            }),
        setIntervenantImage: (id, file) => sC(p => ({
            ...p, intervenants: p.intervenants.map(i => {
                if (i.id === id) {
                    const newI = ({ ...i, imageId: file.id, image: file })
                    appDispatch.intervenants.post(newI)
                    return newI
                } else {
                    return i
                }
            })
        })),
        removeIntervenant: (intervenant) => fetch(`/api/admin/cours/${id}/intervenants`, 'DELETE', { id: intervenant.id })
            .then((el) => {
                sC(prev => ({ ...prev, intervenants: prev.intervenants.filter(i => i.id !== intervenant.id) }))
                return el
            }),
        addObjectif: () => fetch(`/api/admin/cours/${id}`, 'PATCH', { objectifs: [...cours!.objectifs, ''] })
            .then((el) => {
                sC(prev => ({ ...prev, objectifs: el.objectifs }))
                return el
            }),
        sortObjectifs: (oldIndex: number, newIndex: number) => {
            const newObj = arrayMove(cours!.objectifs, oldIndex, newIndex)
            setCours(prev => ({ ...prev!, objectifs: newObj }))
            return fetch(`/api/admin/cours/${id}`, 'PATCH', { objectifs: newObj })
                .then((el) => {
                    sC(prev => ({ ...prev, objectifs: el.objectifs }))
                    return el
                })
        },
        updateObjectif: (index, value) => fetch(`/api/admin/cours/${id}`, 'PATCH', { objectifs: cours!.objectifs.map((obj, i) => i === index ? value : obj) })
            .then((el) => {
                sC(prev => ({ ...prev, objectifs: el.objectifs }))
                return el
            }),
        removeObjectif: (index) => fetch(`/api/admin/cours/${id}`, 'PATCH', { objectifs: cours!.objectifs.filter((obj, i) => i !== index) })
            .then((el) => {
                sC(prev => ({ ...prev, objectifs: el.objectifs }))
                return el
            }),
        selectStream: (file: FileType) => dispatch.updateField('streamId', file.id)
            .then((el) => sC(prev => ({ ...prev, stream: el.stream, streamId: el.stream?.id ?? null }))),
        removeStream: () => fetch(`/api/admin/cours/${id}`, 'PATCH', { streamId: null })
            .then((el) => {
                sC(prev => ({ ...prev, stream: null, streamId: null }))
                return el
            }),
        setFavorite: (intervenantId, isFavorite) => fetch(`/api/admin/cours/${id}/intervenants/update`, 'PATCH', { id: intervenantId, isFavorite })
            .then(el => {
                appDispatch.intervenants.post(el)
                sC(prev => ({ ...prev, intervenants: prev.intervenants.map(i => i.id === intervenantId ? el : i) }))
            }),
        createQuestion: (question) => fetch(`/api/admin/cours/${id}/questions`, 'POST', { ...question, coursId: id }).then(el => {
            sC(prev => ({ ...prev, questions: [...prev.questions, el] }))
        }),
        deleteQuestion: (questionId) => fetch(`/api/admin/cours/${id}/questions`, 'DELETE', { id: questionId }).then(el => {
            sC(prev => ({ ...prev, questions: prev.questions.filter(q => q.id !== questionId) }))
        }),
        saveQuestion: (question) => fetch(`/api/admin/cours/${id}/questions/${question.id}`, 'PATCH', question).then(el => {
            sC(prev => ({ ...prev, questions: prev.questions.map(q => q.id === el.id ? el : q) }))
        }),

        toggleQuizzDrawer: () => setOpenQuizzDrawer(prev => !prev),
        setActiveSlide,
    }), [cours])

    React.useEffect(() => {
        appDispatch.cours.get(id!, setCours).then(setCours)
        appDispatch.thematics.getAll()
        appDispatch.intervenants.getAll()


    }, [id])

    React.useEffect(() => {
        let timer: ReturnType<typeof setInterval> | null = null

        if (cours) {
            appDispatch.cours.post(cours)
            timer && clearInterval(timer)
            timer = setInterval(() => fetch(`/api/admin/cours/${id}`, 'GET').then(c => c && c.streamId !== cours.streamId && sC(p => ({ ...p, stream: c.stream, streamId: c.streamId })))
                , 60000)
        }

        return () => { timer && clearInterval(timer) }
    }, [cours])

    return {
        cours,
        openQuizzDrawer,
        activeSlide,
        dispatch
    }
}

export default useDocument