import React from 'react'
import { CardTablePropsType } from './types'
import { Box, Button, Card, Divider, IconButton, InputAdornment, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import useTable, { TableContext } from './useTable'
import CustomFilters from './customFilters/CustomFilters'
import Body from './body/Body'
import Search from './search/Search'
import Head from './Head'
import { SortableContext } from '@dnd-kit/sortable'
import { DndContext, DragEndEvent } from '@dnd-kit/core'

function CardTable<T>({ columns, datas, customFilters, noCard, buttons, secondaryFilters, footer, collapse, sortable }: CardTablePropsType<T>) {
    const contextValue = useTable({ columns, datas, customFilters, noCard, buttons, secondaryFilters, collapse, sortable })

    const sortEnd = (event: DragEndEvent) => {
        if (!contextValue.isSortable) return
        const activeIndex = event.active.data.current?.sortable?.index
        const overIndex = event.over?.data.current?.sortable?.index
        if (activeIndex === undefined || overIndex === undefined || activeIndex === overIndex) return
        sortable!.onSortEnd(activeIndex, overIndex)
    }
    return (
        <TableContext.Provider value={contextValue}>
            <Card variant='outlined' sx={{ ...(noCard && { border: 'none', borderRadius: 0 }), height: '100%', display: 'flex', flexDirection: 'column' }}>
                {customFilters && <CustomFilters />}
                {columns.filter(c => c.search).length > 0 && <Search />}

                <TableContainer sx={{ flexGrow: 1 }}>
                    <DndContext
                        onDragEnd={sortEnd}>
                        <SortableContext items={(contextValue.datas ?? []).map(d => d.id)}>
                            <Table stickyHeader>
                                <Head />
                                <Body />
                            </Table>
                        </SortableContext>
                    </DndContext>
                </TableContainer>
                {footer && <Box sx={{ borderTop: theme => `1px solid ${theme.palette.grey[300]}` }}>
                    {footer}
                </Box>}
            </Card>
        </TableContext.Provider>
    )
}

export default CardTable