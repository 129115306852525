import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { QuizzContext } from './useQuizz'
import { Check, Close } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const DisplayQuestion = () => {
    const { activeQuestion, canNext, dispatch } = React.useContext(QuizzContext)


    const changeCheckResponse = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch.setResponse(event.target.value)
    }

    const changeRadioResponse = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        dispatch.setResponse(value)
    }

    return (
        <Card variant={'outlined'} component={Box} display={'flex'} flexDirection={"column"} gap={2} alignItems={'center'} justifyContent={'center'} minWidth={{ xs: '100%', md: 'auto' }}>
            {activeQuestion && <React.Fragment>
                <Typography variant='h6'>{activeQuestion.question}</Typography>

                {(activeQuestion.type === 0 || activeQuestion.type === 2) && <RadioGroup onChange={changeRadioResponse}>
                    {activeQuestion.reponses.map((reponse, index) =>
                        <FormControlLabel key={reponse.id} value={reponse.id} control={<Radio disabled={activeQuestion.correct !== undefined} />} label={reponse.value} />)}
                </RadioGroup>}

                {(activeQuestion.type === 1) && <FormGroup>
                    {activeQuestion.reponses.map((reponse, index) =>
                        <FormControlLabel
                            key={reponse.id}
                            value={reponse.id}
                            control={<Checkbox onChange={changeCheckResponse} disabled={activeQuestion.correct !== undefined} />}
                            label={reponse.value}
                        />)}
                </FormGroup>}

                {activeQuestion.correct !== undefined &&
                    <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                        {activeQuestion.correct ? <Check /> : <Close />}
                        {activeQuestion.correct ? 'Bonne réponse' : 'Mauvaise réponse'}
                    </Box>}

                {activeQuestion.correct === false &&
                    <Button component={Link} to={`/epdsi/cours/${activeQuestion.coursId}?slideNumber=${activeQuestion.slideNumber}`}>
                        Acceder au cours
                    </Button>}

                {activeQuestion.correct === undefined
                    ? <Button onClick={dispatch.proceedResponse}>
                        Valider
                    </Button>
                    : canNext ? <Button onClick={dispatch.next}>Suivant</Button> : <Button onClick={dispatch.restart}>Recommencer</Button>}

            </React.Fragment>}
        </Card>
    )
}

export default DisplayQuestion