import { Add, Delete, DragHandle, Edit, Save } from '@mui/icons-material'
import { Box, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import InputField from './InputField'
import { DocumentContext } from '../Pages/EPDSI/Cours/Edit/useDocument'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const CoursObjectifs = () => {
    const { cours, dispatch } = React.useContext(DocumentContext)

    const [objectifs, setObjectifs] = React.useState<string[]>(cours?.objectifs ?? [])
    const [edit, toggleEdit] = React.useReducer(o => !o, false)

    const onDragEnd = (event: DragEndEvent) => {
        const activeId = event.active.id
        const overId = event.over?.id
        //dispatch.sortObjectifs(activeId as number, overId as number)
        setObjectifs((prev) => arrayMove(prev, activeId as number, overId as number))
    }

    const addObjectif = () => setObjectifs((prev) => [...prev, ''])

    const setObjectfif = (index: number, value: string) => {
        setObjectifs((prev) => {
            const newObjectifs = [...prev]
            newObjectifs[index] = value
            return newObjectifs
        })
    }

    const saveObjectifs = () => {
        dispatch.updateField('objectifs', objectifs)
        toggleEdit()
    }

    React.useEffect(() => {
        setObjectifs(cours?.objectifs ?? [])
    }, [cours])

    if (!cours) return (<></>)
    if (!edit) return (
        <Box
            sx={{
                position: 'relative',
                border: theme => `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme => theme.spacing(1.25),
                p: 1,
                background: theme => theme.palette.grey[50],
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-root': {
                    color: theme => theme.palette.grey[700] + ' !important'
                },
                '&:hover': {
                    background: 'unset'
                },
                '& .hoverable': {
                    visibility: 'hidden'
                },
                '&:hover .hoverable': {
                    visibility: 'visible'
                }
            }}
        >
            <Box className={'hoverable'} sx={{
                position: 'absolute',
                top: 2,
                right: 2
            }}>
                <IconButton size='small' onClick={toggleEdit}>
                    <Edit fontSize='small' />
                </IconButton>
            </Box>
            <Typography role="label" variant={cours.objectifs.length > 0 ? 'caption' : undefined}>Objectifs du cours</Typography>
            {objectifs.length === 0
                ? <Typography variant="caption">Aucun objectif.</Typography>
                : <List>
                    {objectifs.map((obj, i) => <ListItem key={obj + i}>
                        <ListItemText primary={obj} />
                    </ListItem>)}
                </List>}
        </Box>
    )
    else return (
        <Box
            sx={{
                border: theme => `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme => theme.spacing(1.25),
                p: 1,
                background: theme => theme.palette.grey[50],
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-root': {
                    color: theme => theme.palette.grey[700] + ' !important'
                },
                '&:hover': {
                    background: 'unset'
                },
            }}
        >
            <Typography role="label" variant={objectifs.length > 0 ? 'caption' : undefined}>Objectifs du cours</Typography>
            {objectifs.length === 0 && <Typography variant="caption">Aucun objectif.</Typography>}
            <DndContext
                onDragEnd={onDragEnd}>
                <SortableContext items={objectifs.map((_, i) => i.toString())}>
                    {objectifs.map((obj, i) => <Element key={obj + i} obj={obj} index={i} setObjectif={setObjectfif} />)}
                </SortableContext>
            </DndContext>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
            >
                <Box>
                    <IconButton onClick={addObjectif}>
                        <Add />
                    </IconButton>
                </Box>
                <Box>
                    <IconButton onClick={saveObjectifs}>
                        <Save />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}

const Element = ({ obj: initialObj, index, setObjectif }: { obj: string, index: number, setObjectif: (index: number, value: string) => void }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: index.toString() });


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };
    const { dispatch } = React.useContext(DocumentContext)
    const [obj, setObj] = React.useState<string>(initialObj)

    const handleChange = () => setObjectif(index, obj)

    return (
        <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center',
            '& .hideable': { visibility: 'hidden' },
            '&:hover .hideable': { visibility: 'visible' },
        }}
            style={style}
            ref={setNodeRef}
            {...attributes}
        >
            <Box className={'hideable'}>
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                <InputField
                    label={'Objectif'}
                    value={obj}
                    onChange={({ target: { value } }) => setObj(value)}
                    onBlur={handleChange}
                    multiline
                    fullWidth
                    sx={{ mb: 1 }}
                />
            </Box>
            <Box className={'hideable'}>
                <IconButton onClick={() => dispatch.removeObjectif(index)} color='error'>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    )
}

export default CoursObjectifs