import React from 'react'
import InvoiceItemType from '../../../Stores/InvoiceItemType'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import { InvoiceEditContext } from './useInvoiceEdit'
import InputField from '../../../Components/InputField'

const InvoiceModalItemEdit = () => {
    const { editedItem, dispatch } = React.useContext(InvoiceEditContext)
    const handleClose = () => dispatch.editItem(null)
    const handleSave = () => dispatch.updateItem({
        id: editedItem!.id,
        title: editedItem!.title,
        quota: editedItem!.quota,
        unit: editedItem!.unit,
        unitPrice: editedItem!.unitPrice,
        tva: editedItem!.tva,
    })
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={Boolean(editedItem)}
        //   onClose={handleClose}
        >
            <DialogTitle>Edition d'un élément</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                    <InputField label="Désignation" value={editedItem?.title ?? ''} onChange={({ target: { value } }) => dispatch.editItem({ ...editedItem!, title: value })} fullWidth />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                        <InputField type={'number'} label="Quantité" value={editedItem?.quota ?? ''} onChange={({ target: { value } }) => dispatch.editItem({ ...editedItem!, quota: +value })} fullWidth />
                        <InputField label="Unité" value={editedItem?.unit ?? ''} onChange={({ target: { value } }) => dispatch.editItem({ ...editedItem!, unit: value })} fullWidth />
                        <InputField type={'number'} label="Prix unitaire" value={editedItem?.unitPrice ?? ''} onChange={({ target: { value } }) => dispatch.editItem({ ...editedItem!, unitPrice: +value })} fullWidth />
                    </Box>
                    <InputField type={'number'} label="TVA" value={editedItem?.tva ?? ''} onChange={({ target: { value } }) => dispatch.editItem({ ...editedItem!, tva: +value })} fullWidth />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleSave}>Sauvegarder</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoiceModalItemEdit