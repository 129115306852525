import React from "react"
import InvoiceType from "../../../Stores/InvoiceType"
import { AppContext } from "../../../App"
import InvoiceClient from "../../../Stores/InvoiceClientType"
import InvoiceItemType from "../../../Stores/InvoiceItemType"

export type useInvoiceEditType = (id: InvoiceType['id']) => useInvoiceEditContextType
export type useInvoiceEditDispatchType = {
    addItem: () => void,
    editItem: (item: InvoiceItemType | null) => void
    updateItem: (partial: Partial<InvoiceItemType> & { id: InvoiceItemType['id'] }) => void
    showInvoicePdf: () => void
    deleteItem: (itemId: InvoiceItemType['id']) => void
    update: (partial: Partial<InvoiceType>) => void
    setClient: (client: InvoiceClient | null) => void
}
export type useInvoiceEditContextType = {
    invoice: InvoiceType | undefined
    invoiceClients: InvoiceClient[] | undefined
    editedItem: null | InvoiceItemType
    invoicePdf: null | InvoiceType
    dispatch: useInvoiceEditDispatchType
}

export const InvoiceEditContext = React.createContext<useInvoiceEditContextType>({} as useInvoiceEditContextType)
const dateMonth = new Date()
dateMonth.setMonth(dateMonth.getMonth() + 1)

const useInvoiceEdit: useInvoiceEditType = (id) => {
    const { invoiceClients, dispatch: appDispatch, fetch } = React.useContext(AppContext)
    const [invoice, setInvoice] = React.useState<InvoiceType>()
    const [editedItem, setEditedItem] = React.useState<InvoiceItemType | null>(null)
    const [invoicePdf, setInvoicePdf] = React.useState<InvoiceType | null>(null)
    const dispatch: useInvoiceEditDispatchType = {
        addItem: () => fetch(`/api/admin/invoices/${id}/invoice-item`, 'POST')
            .then((item: InvoiceItemType) => setInvoice((prev) => ({ ...prev!, items: [...prev!.items, item] as InvoiceType['items'] }))),
        editItem: (item) => setEditedItem(item),
        showInvoicePdf: () => setInvoicePdf(prev => (prev ? null : invoice) ?? null),
        updateItem: (partial) => {
            setEditedItem(null)
            fetch(`/api/admin/invoice-items/${partial.id}`, 'PATCH', partial).then(invoiceItem => setInvoice(prev => ({
                ...prev!,
                items: prev!.items.map(item => item.id === invoiceItem.id ? invoiceItem : item) as InvoiceType['items']
            })))
        },
        deleteItem: (itemId) => fetch(`/api/admin/invoice-items/${itemId}`, 'DELETE')
            .then(() => setInvoice(prev => ({ ...prev!, items: prev?.items.filter(i => i.id !== itemId) as InvoiceType['items'] }))),
        update: (partial) => {
            setInvoice(prev => ({ ...prev!, ...partial }))
            fetch(`/api/admin/invoices/${id}`, 'PATCH', partial).then(setInvoice)
        },
        setClient: (client) => {
            fetch(`/api/admin/invoices/${id}`, 'PATCH', { clientId: client?.id ?? null }).then(setInvoice)
        },
    }

    React.useEffect(() => {
        fetch(`/api/admin/invoices/${id}`, 'GET').then(setInvoice)
        appDispatch.invoiceClients.getAll()
    }, [])

    return {
        invoice, invoiceClients, editedItem, invoicePdf, dispatch
    }
}

export default useInvoiceEdit