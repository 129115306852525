import { ButtonProps, SxProps } from "@mui/material"
import React from "react"

export type CardTablePropsType<T> = {
    columns: CardTableColumnConfigObject<T>[]
    datas: T[]
    customFilters?: customFilter<T>[]
    secondaryFilters?: customFilter<T>[]
    noCard: boolean
    buttons?: Button<T>[]
    footer?: React.ReactNode
    collapse?: (data: T) => React.ReactNode
    sortable?: {
        onSortEnd: (oldIndex: number, newIndex: number) => void
    }
    initialSort?: {
        field: keyof T & string,
        order: keyof typeof SortOrderEnum
    }
}

export type CardTableColumnConfigObject<T> = {
    field: keyof T & string,
    header: CardTableColumnHeaderConfigObject<T>,
    body?: string | number | ((data: T) => React.ReactNode)
    search?: (data: T, search: string) => boolean,
    order?: (data: T[], sortOrder: 1 | -1) => T[]
    initialOrder?: keyof typeof SortOrderEnum
}

export type CardTableColumnHeaderConfigObject<T> = {
    label: string | React.ReactNode,
    style?: SxProps
}


export type customFilter<T> = {
    name: string,
    filter: (datas: T[]) => T[]
}

export type Button<T> = {
    isHeader?: boolean
    icon?: React.ElementType,
    onClick: (data: T) => void,
    rounded?: boolean
    label?: string
    color?: ButtonProps['color'] | ((data: T) => ButtonProps['color'])
    role?: (data: T) => string | undefined
    disabled?: (data: T) => boolean
} | ((data: T) => React.ReactNode)

export type useTableType<T> = (init: CardTablePropsType<T>) => useTableContextType<T>

export type useTableDispatchType = {
    setTab: React.Dispatch<React.SetStateAction<string>>
    setSearch: React.Dispatch<React.SetStateAction<string>>
    setSecondaryFilter: React.Dispatch<React.SetStateAction<number>>
    sort: (field: string, order: keyof typeof SortOrderEnum) => void
    toggleCollapse: (id: string) => void
}

export type useTableContextType<T> = {
    dispatch: useTableDispatchType
    tab: string, search: string, secondaryFilterSelected: number
    sort: {
        field: string,
        order: keyof typeof SortOrderEnum
    },
    collapsed: string[]
    isSortable: boolean
} & CardTablePropsType<T>

export enum SortOrderEnum {
    asc = -1,
    desc = 1
}