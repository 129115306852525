import React, { PropsWithChildren } from 'react'
import { PageContext } from './Page'
import { Box, Paper } from '@mui/material'


interface ContentProps extends PropsWithChildren {
    fullPage?: boolean
}
const Content = ({ fullPage, children }: ContentProps) => {
    return (
        <Box
            flexGrow={1}
            sx={theme => ({
                borderTop: theme => `1px solid ${theme.palette.divider}`,
                backgroundColor: theme => theme.palette.common.white,
                [theme.breakpoints.up('sm')]: {
                    ...(fullPage ? { overflowY: 'hidden', height: '100%' } : {})
                },
            })}
        >
            {children}
        </Box >
    )
}

export default Content