import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Delete, DragHandle } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import InputField from './InputField'

interface TextEditProps {
    value: string
    onSave: (value: string) => void
}
const TextEdit = ({ value, onSave }: TextEditProps) => {

    const [texts, setTexts] = React.useState<string[]>([''])


    const setText = (index: number, text: string) => {
        const newTexts = [...texts]
        newTexts[index] = text
        setTexts(newTexts)
    }
    const deleteText = (index: number) => {
        const newTexts = [...texts]
        newTexts.splice(index, 1)
        setTexts(newTexts)
        saveTexts(newTexts.join('\n'))
    }

    const onDragEnd = (event: DragEndEvent) => {
        const activeId = event.active.id
        const overId = event.over?.id
        const newValue = arrayMove(texts, activeId as number, overId as number)
        setTexts(newValue)
        saveTexts(newValue.join('\n'))
    }

    const saveTexts = (value?: string) => {
        onSave(value ?? texts.join('\n'))
    }

    React.useEffect(() => {
        setTexts(value?.split('\n') ?? [''])
    }, [value])

    return (
        <Box
            height={'100%'}
            sx={{
                overflowY: 'auto',
            }}
            p={1}
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            gap={1}
        >
            <DndContext
                onDragEnd={onDragEnd}>
                <SortableContext items={texts.map((_, i) => i.toString())}>
                    {texts.map((text, i) => <SortableEl
                        key={i}
                        text={text}
                        index={i}
                        onChange={setText}
                        onBlur={() => saveTexts()}
                        onDelete={deleteText}
                        canDelete={
                            texts.length === 1 && i === 0 && text === ''
                        }
                    />)}
                </SortableContext>
            </DndContext>
        </Box>
    )
}

interface SortableElProps {
    text: string
    index: number
    onChange: (index: number, value: string) => void
    onBlur: () => void
    onDelete: (index: number) => void
    canDelete: boolean
}
const SortableEl = ({
    text: v,
    index: i,
    onChange,
    onBlur,
    onDelete,
    canDelete
}: SortableElProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: i.toString() });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };
    return (
        <Box
            key={i}
            display={'flex'}
            alignItems={'center'}
            gap={1}
            sx={{
                '& .hoverable': {
                    visibility: 'hidden',
                },
                '&:hover .hoverable': {
                    visibility: 'visible',
                }
            }}
            style={style}
            ref={setNodeRef}
            {...attributes}
        >
            <Box className={'hoverable'} ml={1}>
                <DragHandle {...listeners} />
            </Box>
            <InputField
                label='Ligne'
                value={v}
                onChange={({ target: { value } }) => onChange(i, value)}
                onBlur={onBlur}
                fullWidth
                multiline
            />

            <Box className={'hoverable'} mr={1}>
                <IconButton size='small' color='error' onClick={() => onDelete(i)} disabled={canDelete}>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TextEdit