import React from 'react'
import useSolution from './useSolution'
import SolutionContext from './SolutionContext'
import Page from '../../../../Components/PageInterface/Page'
import ChuvIcon from '../../../../assets/favicon.ico'
import { Calculate, Restaurant } from '@mui/icons-material'
import { Box } from '@mui/material'
import InputField from '../../../../Components/InputField'
import Adder from './Adder'

const NECalculator = () => {
    const contextValue = useSolution()
    const { cibleCalorique, selectedSolutions, dispatch } = contextValue
    return (
        <SolutionContext.Provider value={contextValue}>
            <Page
                title={'Calcul des apports en nutriments'}
                loading={!contextValue.availableSolutions}
                breadcrumb={[
                    { url: '/chuv', name: 'CHUV', src: ChuvIcon },
                    { url: '/chuv/nutritive-solutions', name: 'Solutions nutritives', icon: Restaurant },
                    { url: `/chuv/nutritive-solutions/calculator`, name: 'Calcul des apports en nutriments', icon: Calculate }
                ]}
                fullPage
                children={
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'auto',
                        p: 2,
                        gap: 2
                    }}>
                        <Box display={'flex'} flexDirection={'row'} gap={1}>
                            <Box flexGrow={1}>
                                <InputField
                                    label={'Cible calorique'}
                                    type={'number'}
                                    value={cibleCalorique ?? 0}
                                    onChange={({ target: { value } }) => dispatch.setCibleCalorique(+value)}
                                    fullWidth
                                />
                            </Box>
                            <Box>
                                <Adder />
                            </Box>
                        </Box>
                        {selectedSolutions.map(solution => <Box key={solution.id}>{solution.name}</Box>)}
                    </Box>
                }
            />
        </SolutionContext.Provider>
    )
}

export default NECalculator