import React from 'react'
import Page from '../Components/PageInterface/Page'
import { AppContext } from '../App'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { ToggleOff, ToggleOn } from '@mui/icons-material'

type AppType = {
    pid: number
    name: string
    status: 'online' | 'stopped'
}

export const Dashboard = () => {

    const { apps } = React.useContext(AppContext)

    return (
        <Page title='Applications' loading={false} children={
            <>
                <Box display={'grid'} gridTemplateColumns={'repeat(4, 1Fr)'} gap={2} justifyContent={'center'} p={2}>
                    {apps?.map(app => <Tuile key={app.pid} app={app} />)}
                </Box>
            </>
        } fullPage />
    )
}

const Tuile = ({ app }: { app: AppType }) => {
    return (
        <Card>
            <CardContent>
                <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'stretch'} >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        {app.status === 'online' && <ToggleOn color="primary" fontSize={'large'} />}
                        {app.status === 'stopped' && <ToggleOff color="error" fontSize={'large'} />}
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <Typography>{app.name}</Typography>
                        <Typography>{app.status}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
