import { ArrowLeft } from '@mui/icons-material'
import { Button, ButtonGroup, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'

interface DropdownButtonProps {
    menuItems: { value: number, primary: string }[]
    defaultIndex?: number
    placeholder?: string
    onClick: (value: { value: number, primary: string } | undefined) => void
}
const DropdownButton = ({ menuItems, defaultIndex, placeholder, onClick }: DropdownButtonProps) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
    const [value, setValue] = React.useState<number>(defaultIndex ?? menuItems.at(0)?.value ?? 0)

    const handleChange = (value: number) => {
        setValue(value)
        setAnchor(null)
    }

    const handleClick = () => onClick(menuItems.find(i => i.value === value))

    const sxLeft = {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
    }
    const sxRight = {
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
    }
    return (
        <ButtonGroup variant='contained' sx={{ ...sxLeft, ...sxRight }}>
            <Button sx={sxLeft} onClick={handleClick}>
                {placeholder && <><strong>{placeholder}</strong> : </>}{menuItems.find(i => i.value === value)!.primary}
            </Button>
            <Button onClick={({ target }) => setAnchor(target as HTMLElement)} sx={sxRight}>
                <ArrowLeft sx={{ transform: 'rotate(-90deg)', m: -1 }} />
            </Button>
            <Menu
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                {menuItems.map((item) =>
                    <MenuItem key={item.value} onClick={() => handleChange(item.value)}>{item.primary}</MenuItem>)}
            </Menu>
        </ButtonGroup>
    )
}

export default DropdownButton