import { Settings } from '@mui/icons-material'
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, Switch } from '@mui/material'
import React from 'react'
import { UseEpEditContext } from './useEpsEdit'

const ConfigMenu = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (value: string) => () => dispatch.toggleConfig(value)
    return (
        <React.Fragment>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)} color={Boolean(anchorEl) ? 'primary' : undefined}>
                <Settings />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <ListSubheader>Configuration des onglets</ListSubheader>
                {[
                    { value: 'dds', label: 'Démarche de soins' },
                    { value: 'ecg', label: 'ECGs' },
                    { value: 'images', label: 'Images' },
                    { value: 'ttt', label: 'Traitements' },
                    { value: 'plannif', label: 'Plannification' }
                ].map(({ value, label }) =>
                    <ListItemButton key={value} onClick={handleClick(value)}>
                        <ListItemIcon>
                            <Switch checked={ep?.config.includes(value)} />
                        </ListItemIcon>
                        <ListItemText primary={label} />
                    </ListItemButton>)}

            </Menu>
        </React.Fragment>
    )
}

export default ConfigMenu