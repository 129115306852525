import React from 'react'
import CardTable from '../../../../../../Components/CardTable/CardTable'
import SystemSelector from '../SystemSelector'
import { Add, ArrowDownward, ArrowUpward, ChevronRight, Delete, Edit, PriorityHigh } from '@mui/icons-material'
import InputField from '../../../../../../Components/InputField'
import { UseEpEditContext } from '../../useEpsEdit'
import ProblemSelector from '../ProblemSelector'
import Footer from './Footer'
import { Box, Chip, List, ListItem, ListSubheader, Tooltip, Typography } from '@mui/material'
import Problem from './Problem'
import Adder from './Adder'
import systemNames from '../../systemNames'

const AnaPrimaire = () => {
    const { ep, dispatch, anaEdit, problems } = React.useContext(UseEpEditContext)
    const gePbName = (id: string) => problems?.map(group => group.problems).flat().find(g => g.id === id)?.name ?? ''
    const textSplitter = (text: string) => text.split("\n\n").map((paragraph, i) => <Box key={i}>
        {paragraph.split("\n").map((line, i) => <Typography key={i}>{line}</Typography>)}
    </Box>)

    const width = document.
        body.offsetWidth / 4 ?? 100
    return (
        <React.Fragment>
            <CardTable
                columns={[
                    {
                        field: 'system',
                        header: { label: 'Système' },
                        // body: (data) => <SystemSelector value={data.system} onChange={system => dispatch.changePrimeAna(data.id, { system })} />
                        body: ({ system }) => <Typography variant='overline'>{systemNames.find(s => s.id === system)?.name ?? ''}</Typography>
                    },
                    {
                        field: 'data',
                        header: { label: 'Recueil de données objectives/ subjectives (antécédents, histoire de la maladie, diagnostics, examen clinique et paraclinique, équipements, traitements)', style: { width } },
                        // body: (data) => <InputField key={data.id} label="Donnée" defaultValue={data.data} onBlur={({ target: { value } }) => dispatch.changePrimeAna(data.id, { data: value })} fullWidth multiline />
                        body: ({ data }) => <Box display={'flex'} flexDirection={'column'} gap={2}>
                            {typeof data === 'string'
                                ? textSplitter(data)
                                : Object.entries(data)
                                    .filter(([key, value]) => value !== '')
                                    .sort((a, b) => a[0] < b[0] ? -1 : 1)
                                    // .map(([key, value]) => <Box><Typography variant='overline'>{key}</Typography>{textSplitter(value)}</Box>
                                    .map(([key, value]) => <List key={key} dense>
                                        <ListSubheader>{key}</ListSubheader>
                                        {value.split("\n").map((line, i) => <ListItem key={key + i}>{line}</ListItem>)}
                                    </List>
                                    )}
                        </Box>
                    },
                    {
                        field: 'explication',
                        header: { label: 'Expliquez les données objectives et subjectives marquantes de cette situation en les mettant en lien avec les hypothèses la/les plus probable(s) clairement nommées', style: { width } },
                        // body: data => <InputField key={data.id} onBlur={({ target: { value } }) => dispatch.changePrimeAna(data.id, { explication: value })} label="Explication" defaultValue={data.explication} fullWidth multiline />
                        body: ({ explication }) => <Box display={'flex'} flexDirection={'column'} gap={2}>{textSplitter(explication)}</Box>
                    },
                    {
                        field: 'problem',
                        header: { label: 'Déterminez les problèmes réels', style: { width } },
                        body: ({ problem }) => <Box style={{ width }} display={'flex'} flexDirection={'column'} gap={2}>
                            {typeof problem === 'string'
                                ? <Box textAlign={'center'}>
                                    <Tooltip title={gePbName(problem)}>
                                        <Chip label={gePbName(problem)} />
                                    </Tooltip>
                                </Box>
                                : problem.map((p, i) => <Box key={i} textAlign={'center'}>
                                    <Tooltip key={i} title={gePbName(p)}>
                                        <Chip label={gePbName(p)} />
                                    </Tooltip>
                                </Box>)}
                        </Box>
                    },
                ]}
                datas={ep?.primeAna.sort((a, b) => (a.position ?? 0) < (b.position ?? 0) ? - 1 : 1) ?? []}
                buttons={[
                    // { isHeader: true, label: 'Ajouter', icon: Add, onClick: dispatch.addPrimeAna, rounded: true },
                    { isHeader: true, label: 'Ajouter', icon: Add, onClick: (d) => dispatch.setAnaEdit('add'), rounded: true },
                    { label: 'Editer', icon: Edit, onClick: (data) => dispatch.setAnaEdit(data), rounded: true },
                    { label: 'Problème prioritaire', icon: PriorityHigh, onClick: (data) => typeof data.prioritaire === 'number' ? dispatch.changePrimeAna(data.id, { prioritaire: undefined }) : dispatch.setPrimeAnaPriority(data.id), color: (data) => typeof data.prioritaire === 'number' ? 'primary' : undefined, rounded: true, role: (data) => typeof data.prioritaire === 'number' ? 'visible' : undefined },
                    { label: 'Supprimer', icon: Delete, onClick: (data) => dispatch.removePrimeAna(data.id), color: 'error', rounded: true },
                ]}
                noCard={true}
                footer={<Footer />}
                sortable={{
                    onSortEnd: (oldIndex, newIndex) => dispatch.sortPrimeAna(oldIndex, newIndex)
                }}
            />
            {anaEdit && <Adder />}
            {/* <Adder /> */}
        </React.Fragment>
    )
}

export default AnaPrimaire