import { OndemandVideo } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React from 'react'
import File from '../../Stores/FileType'

interface VideoPreviewProps {
    video: File
}
const VideoPreview = ({ video }: VideoPreviewProps) => {
    const [open, toggle] = React.useReducer(o => !o, false)
    return (
        <>
            <IconButton onClick={toggle}>
                <OndemandVideo />
            </IconButton>
            <Dialog open={open} onClose={toggle}>
                <video src={`/download/${video.id}`} controls />
            </Dialog>
        </>
    )
}

export default VideoPreview