import { Box, IconButton, LinearProgress, ListItemAvatar, ListItemText, MenuItem } from '@mui/material'
import React from 'react'
import Slider from '../../../../../Components/Slider/Slider'
import { DocumentContext } from '../useDocument'
import InputField from '../../../../../Components/InputField'
import { FirstPage, ChevronLeft, ChevronRight, LastPage, Favorite } from '@mui/icons-material'
import Avatar169 from '../../../../../Components/Avatar169'
import TextTooltip from './TextTooltip'
import TextEdit from '../../../../../Components/TextEdit'
import CreateQuestion from './CreateQuestion'

const TabSlides = () => {
    const { cours, activeSlide, dispatch } = React.useContext(DocumentContext)
    const [comment, setComment] = React.useState<string>(cours?.comments.find(c => c.id === 0)?.value ?? '')
    const [isFavorite, toggleFavorite] = React.useReducer(o => !o, cours?.comments.find(c => c.id === 0)?.isFavorite ?? false)


    const handleSaveComment = (value: string) => {
        setComment(value)
        dispatch.setComment(activeSlide, value, isFavorite)
    }

    const images = () => {
        let images = []
        if (cours?.file && cours.file.pages) {
            for (let i = 0; i < cours.file.pages; i++) {
                images.push(`/download/${cours.file.id}/${i + 1}`)
                const link = document.createElement('link')
                link.setAttribute('rel', 'preload')
                link.setAttribute('as', 'image')
                link.setAttribute('href', `/download/${cours.file.id}/${i + 1}`)
                document.head.appendChild(link)
            }
        }
        return images
    }

    const handleChangeDiap = (to: number) => {
        const from = activeSlide;
        const value = comment;

        dispatch.setActiveSlide(to)
        setComment(cours?.comments.find(c => c.id === to)?.value ?? '')
        if ((cours?.comments.find(c => c.id === to)?.isFavorite ?? false) !== isFavorite) toggleFavorite()

        dispatch.setComment(from, value, isFavorite)
    }

    // const transformText = (value: string) => {
    //     const transformers = [
    //         { from: 'id', to: 'infirmier diplômé' },
    //         { from: 'ids', to: 'infirmiers diplômés' }
    //     ]
    //     transformers.map(t => value = value.replaceAll(`$${t.from} `, t.to))
    //     return value
    // }
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} alignItems={'stretch'} height={'100%'} >
            <Box width={'60vw'} p={1} >
                <Slider
                    images={images() ?? []}
                    active={activeSlide}
                    controls={false}
                />
            </Box>
            <Box width={'40vw'} p={1} display={'flex'} flexDirection={'column'} gap={2}>
                <InputField
                    label={'Diapositive'}
                    select
                    fullWidth
                    value={activeSlide}
                    onChange={({ target: { value } }) => handleChangeDiap(+value)}
                >
                    {images().map((src, i) => <MenuItem key={src} value={i}>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <ListItemAvatar sx={{ aspectRatio: '16/9' }}>
                                <Avatar169 src={src} />
                            </ListItemAvatar>
                            <ListItemText primary={`Slide ${i + 1}`} />
                        </Box>
                    </MenuItem>)}
                </InputField>
                <Box display={'flex'} gap={2}>
                    <IconButton onClick={() => handleChangeDiap(0)} disabled={activeSlide === 0}>
                        <FirstPage />
                    </IconButton>
                    <IconButton onClick={() => handleChangeDiap(activeSlide - 1)} disabled={activeSlide === 0}>
                        <ChevronLeft />
                    </IconButton>
                    <IconButton onClick={() => handleChangeDiap(activeSlide + 1)} disabled={activeSlide === (cours?.file?.pages ?? -1) - 1}>
                        <ChevronRight />
                    </IconButton>
                    <IconButton onClick={() => handleChangeDiap((cours?.file?.pages ?? 1) - 1)} disabled={activeSlide === (cours?.file?.pages ?? -1) - 1}>
                        <LastPage />
                    </IconButton>
                    <IconButton onClick={toggleFavorite}>
                        <Favorite color={isFavorite ? 'error' : undefined} />
                    </IconButton>
                    <CreateQuestion slideNumber={activeSlide} />
                    <Box sx={{ ml: 'auto' }}>
                        <TextTooltip />
                    </Box>
                </Box>
                <LinearProgress variant="determinate" value={(activeSlide / (cours?.file?.pages ?? 1)) * 100} />
                {/* <InputField
                    label={'Commentaires'}
                    value={comment}
                    onChange={({ target: { value } }) => setComment(transformText(value))}
                    onBlur={() => dispatch.setComment(activeSlide, comment, isFavorite)}
                    minRows={4}
                    maxRows={15}
                    multiline
                    fullWidth
                /> */}
                <TextEdit value={(comment ?? '') as string} onSave={handleSaveComment} />
            </Box>
        </Box>
    )
}

export default TabSlides