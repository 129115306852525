import React from 'react'
import Page from '../../../../Components/PageInterface/Page'
import CardTable from '../../../../Components/CardTable/CardTable'
import { AppContext } from '../../../../App'
import Adder from './Adder'
import EPDSIList from '../../EPDSIList'
import ExamenCliniqueType from '../../../../Stores/ExamenClinqueType'
import { customFilter } from '../../../../Components/CardTable/types'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../../assets/epdsi.png'
const ECsList = () => {
    const { examensCliniques, dispatch } = React.useContext(AppContext)
    const navigate = useNavigate()
    const [open, toggleOpen] = React.useReducer((o) => !o, false)

    React.useEffect(() => {
        dispatch.examensCliniques.getAll()
    }, [])
    return (
        <Page
            title={'Lise des examens cliniques'}
            rightPlace={<Adder open={open} toggle={toggleOpen} />}
            loading={false}
            breadcrumb={[
                { url: '/epdsi', name: 'EPD SI', src: Logo },
                { url: '/epdsi/ecs', name: 'Enseignements cliniques', letters: 'EC' },
            ]}
            children={
                <CardTable
                    customFilters={
                        !examensCliniques ? undefined : examensCliniques.map(e => e.period).filter((e, i, s) => s.indexOf(e) === i).sort((a, b) => a < b ? -1 : 1).map(p => ({ name: `Période ${p}`, filter: (datas) => datas.filter((d: any) => d.period! === p) })) as customFilter<ExamenCliniqueType>[]
                    }
                    columns={[
                        { field: 'date', header: { label: 'Date' }, body: (data) => data.date ? new Intl.DateTimeFormat('fr-FR').format(new Date(data.date)) : 'Non définie' },
                        { field: 'examinateur', header: { label: 'Examinateur' }, body: (data) => `${EPDSIList.examinateurs.find(exam => exam.id === data.examinateur)?.firstname} ${EPDSIList.examinateurs.find(exam => exam.id === data.examinateur)?.lastname}` },
                        { field: 'name', header: { label: 'Nom' } }
                    ]}
                    datas={examensCliniques?.sort((a, b) => `${a.period} ${a.date}` < `${b.period} ${b.date}` ? -1 : 1) ?? []}
                    buttons={[
                        {
                            icon: Edit,
                            onClick: (data) => navigate(`/epdsi/ecs/${data.id}`),
                            rounded: true,
                            label: 'Editer',
                        }
                    ]}
                    noCard={true}
                />
            }
        />
    )
}

export default ECsList