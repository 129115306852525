import React from 'react'
import { NutritiveSolutionContext } from '../useNutritiveSolution'
import { Box } from '@mui/material'
import InputField from '../../../../../Components/InputField'
import NutritiveSolution from '../../../../../Stores/NutritiveSolutionType'

const CompositionTab = () => {
    const { nutritiveSolution, dispatch } = React.useContext(NutritiveSolutionContext)
    const [volume, setVolume] = React.useState<number>()

    const handleChange = () => volume && dispatch.update({ volume })


    React.useEffect(() => {
        setVolume(nutritiveSolution?.volume)
    }, [])
    return (
        <Box display={'flex'} flexDirection={'column'} gap={2} columnGap={2} p={1}>
            <Box>
                <InputField
                    label={'Volume de la solution'}
                    value={volume}
                    onChange={({ target: { value } }) => setVolume(Number(value))}
                    onBlur={handleChange}
                    onKeyUp={({ key }) => key === 'Enter' && handleChange()} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'calories'} label={'Calories'} unit={nutritiveSolution?.volume === 1 ? 'kcal/ml' : 'kcal'} />
                <Input keyName={'proteines'} label={'Protéines'} unit={nutritiveSolution?.volume === 1 ? 'g/ml' : 'g'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'glucides'} label={'Glucides'} unit={nutritiveSolution?.volume === 1 ? 'g/ml' : 'g'} />
                <Input keyName={'lipides'} label={'Lipides'} unit={nutritiveSolution?.volume === 1 ? 'g/ml' : 'g'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'fibres'} label={'Fibres'} unit={nutritiveSolution?.volume === 1 ? 'g/ml' : 'g'} />
                <Input keyName={'vitC'} label={'Vitamine C'} unit={nutritiveSolution?.volume === 1 ? 'g/ml' : 'g'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'Na'} label={'Sodium'} unit={nutritiveSolution?.volume === 1 ? 'mg/ml' : 'mg'} />
                <Input keyName={'K'} label={'Potassium'} unit={nutritiveSolution?.volume === 1 ? 'mg/ml' : 'mg'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'P'} label={'Phosphore'} unit={nutritiveSolution?.volume === 1 ? 'mg/ml' : 'mg'} />
                <Input keyName={'Se'} label={'Sélénium'} unit={nutritiveSolution?.volume === 1 ? 'µg/ml' : 'µg'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'Zn'} label={'Zinc'} unit={nutritiveSolution?.volume === 1 ? 'mg/ml' : 'mg'} />
                <Input keyName={'Cu'} label={'Cuivre'} unit={nutritiveSolution?.volume === 1 ? 'mg/ml' : 'mg'} />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Input keyName={'H2O'} label={'Eau'} unit={nutritiveSolution?.volume === 1 ? 'g' : 'g'} />
            </Box>
        </Box>
    )
}

type NumberKey<T> = {
    [K in keyof T]: T[K] extends number ? T[K] : never
}
type NSNumberProperties = NumberKey<NutritiveSolution>

interface InputProps {
    label: string
    unit: string
    keyName: keyof NSNumberProperties
}
const Input = ({ label, unit, keyName }: InputProps) => {
    const { nutritiveSolution, dispatch } = React.useContext(NutritiveSolutionContext)
    const [data, setData] = React.useState<number>()

    const handleChange = () => data && dispatch.update({ [keyName]: data })



    React.useEffect(() => {
        if (nutritiveSolution) setData(nutritiveSolution[keyName] as number)
    }, [])
    return (
        <Box flexGrow={1}>
            <InputField label={`${label} (${unit})`} value={data} onChange={({ target: { value } }) => setData(Number(value))} onBlur={handleChange} onKeyUp={({ key }) => key === 'Enter' && handleChange()} fullWidth />
        </Box>
    )
}

export default CompositionTab