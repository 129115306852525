import { Avatar, Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import InputField from '../../Components/InputField'
import LogoAleph from '../../Components/LogoAleph'

import GradientBg from '../../assets/gradient-bg.svg'
import { AppContext } from '../../App'

const Login = () => {
    const { dispatch } = React.useContext(AppContext)
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')


    const handleLogin = () => {
        setIsFetching(!isFetching)
        dispatch.auth.logIn(email, password).finally(() => setIsFetching(false))
    }
    return (
        <Box sx={{
            display: 'flex',
            position: 'fixed',
            top: 0, bottom: 0, left: 0, right: 0,
            backgroundColor: 'rgb(28, 37, 54)',
            backgroundImage: `url('${GradientBg}')`,
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat'
        }}        >
            <Box flexGrow={6} height={'100%'} display={'flex'} alignItems={'center'} padding={5}>
                <Box sx={{ marginTop: -5, color: theme => theme.palette.common.white }}>
                    <Typography variant={'h3'}>
                        Bienvenue sur AlephIT.fr
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    flexGrow: 1,
                    backgroundColor: theme => theme.palette.background.default,
                    gap: 5,
                    padding: 10,
                    justifyContent: 'center'
                }}
            >
                <Typography variant='h3' sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: -10 }} color={'primary'} gutterBottom={false}>
                    <LogoAleph fontSize={'inherit'} color={'secondary'} /> Aleph<Typography variant='h3' component={'span'} color={'secondary'} gutterBottom={false}>IT</Typography>
                </Typography>
                <Typography variant={'h5'}>Se connecter</Typography>
                <InputField name='email' type={'email'} value={email} onChange={({ target: { value } }) => setEmail(value)} label={'Adresse email'} autoFocus />
                <InputField name='password' type={'password'} value={password} onChange={({ target: { value } }) => setPassword(value)} label={'Mot de passe'} onKeyUp={({ key }) => key === 'Enter' && handleLogin()} />
                <Button variant={'contained'} size='large' onClick={handleLogin} disabled={isFetching}>{isFetching ? <CircularProgress size={27} color={'primary'} /> : 'Se connecter'}</Button>
            </Box>
        </Box>
    )
}

export default Login