import { Add, Close, Settings } from '@mui/icons-material';
import { Slide, IconButton, Button, Dialog, AppBar, Toolbar, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'
import CardTable from '../../../../../Components/CardTable/CardTable';
import { AppContext } from '../../../../../App';
import { MedicamentPresentationType } from '../../../../../Stores/MedicamentType';
import MedicamentEditeur from './MedicamentEditeur';
import { UseEpEditContext } from '../useEpsEdit';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ListMedicamentsProps {
    open: boolean
    setOpen: (open: boolean) => void
}
const ListMedicaments = ({ open, setOpen }: ListMedicamentsProps) => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    const { medicaments } = React.useContext(AppContext)
    const [mode, setMode] = React.useState<'settings' | 'list'>('settings')

    const handleClose = () => setOpen(false)

    // const handleSettings = () => {
    //     setMode('settings')
    //     setOpen(true)
    // }

    const selectedPresentations = React.useMemo(() => ep?.medicaments.map(m => m.presentationId) ?? [], [ep?.medicaments])

    // React.useEffect(() => {
    //     if (!open) setMode('list')
    // }, [open])
    return (
        <React.Fragment>
            {/* <IconButton onClick={handleSettings}>
                <Settings />
            </IconButton> */}
            <Dialog
                fullScreen
                open={open && mode === 'settings'}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Médicaments
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CardTable<MedicamentPresentationType>
                    columns={[
                        {
                            field: 'medicament',
                            header: { label: 'DCI' },
                            body: (d) => d.medicament?.dci,
                            search: (data, search) => data.medicament?.dci.toLocaleLowerCase().includes(search) ?? false,
                        },
                        {
                            field: 'name',
                            header: { label: 'Nom' },
                            search: (data, search) => data.name.toLocaleLowerCase().includes(search) ?? false,
                        },
                        {
                            field: 'voie',
                            header: { label: 'Voie' },
                            search: (data, search) => data.voie.toLocaleLowerCase().includes(search) ?? false,
                        },
                        {
                            field: 'dilution',
                            header: { label: 'Dilution' },
                            body: (d) => d.dilution && d.dilutionUnit ? `${d.dilution} ${d.dilutionUnit}` : '',
                            search: (data, search) => `${data.dilution} ${data.dilutionUnit}`.toLocaleLowerCase().includes(search) ?? false,
                        },
                        {
                            field: 'volume',
                            header: { label: 'Volume' },
                            body: (d) => d.volume ? `${d.volume} mL` : '',
                            search: (data, search) => `${data.volume} mL`.toLocaleLowerCase().includes(search) ?? false,
                        },
                    ]}
                    datas={(medicaments && medicaments.map(m => m.presentations.map(p => ({ ...p, medicament: { ...m, presentations: [] } }))).flat()) ?? []}
                    noCard={true}
                    collapse={(d) => <MedicamentEditeur data={d} />}
                    buttons={[
                        { icon: Add, onClick: (d) => dispatch.addPresentation(d.id), rounded: true, disabled: (d) => selectedPresentations.includes(d.id) }
                    ]}
                />
            </Dialog>
        </React.Fragment>
    )
}

export default ListMedicaments