import React from "react"
import { AuthTokensType, FetchType } from "./types"
import { AppContext } from "../App"

type usePm2Type = (tokens: AuthTokensType | undefined, fetch: FetchType<AppType[]>) => {
    apps: AppType[] | undefined,
    dispatch: usePm2DispatchType
}

export type AppType = {
    pid: number
    name: string
    status: 'online' | 'stopped'
}

export type usePm2DispatchType = {
    start: (appName: string) => Promise<void>
    stop: (appName: string) => Promise<void>
}

const usePm2: usePm2Type = (tokens, fetch) => {
    let interval: ReturnType<typeof setInterval> | undefined = undefined
    const [apps, setApps] = React.useState<AppType[] | undefined>()

    const dispatch: usePm2DispatchType = React.useMemo(() => ({
        start: (appName) => fetch('/api/admin/dashboard/start', 'POST', { appName }).then(apps => apps && setApps(apps.length > 0 ? apps : undefined)),
        stop: (appName) => fetch('/api/admin/dashboard/stop', 'POST', { appName }).then(apps => apps && setApps(apps.length > 0 ? apps : undefined)),
    }), [apps])

    React.useEffect(() => {
        if (tokens) {
            fetch('/api/admin/dashboard', 'GET').then(resp => resp && setApps(resp.length > 0 ? resp : undefined))
            if (interval) clearInterval(interval)

            interval = setInterval(function () {
                fetch('/api/admin/dashboard', 'GET').then(resp => resp && setApps(resp.length > 0 ? resp : undefined))
            }, 30000)
        } else {
            if (interval) clearInterval(interval)
        }
        return () => interval && clearInterval(interval)
    }, [tokens])

    return { apps, dispatch }
}

export default usePm2