import React from 'react'
import { PageContext } from './Page'
import { Avatar, Box, Breadcrumbs, Chip, emphasize, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { Home } from '@mui/icons-material'

const BreadCrumb = () => {
    const { breadcrumb } = React.useContext(PageContext)

    if (!breadcrumb) return (<></>)
    return (
        <Box>
            <Breadcrumbs separator={'›'}>
                <StyledBreadcrumb
                    component={Link}
                    to={'/'}
                    label={'Dashboard'}
                    icon={<Home fontSize="small" />}
                />
                {breadcrumb.map((br, index) =>
                    <StyledBreadcrumb
                        key={br.url ?? index}
                        component={Link}
                        to={br.url ?? '#'}
                        label={br.name}
                        icon={br.icon ? <br.icon fontSize="small" /> : br.src ? <Avatar src={br.src} sx={{ width: 20, height: 20 }} /> : undefined}
                        avatar={br.letters ? <Avatar>{br.letters}</Avatar> : undefined}
                    />)}
            </Breadcrumbs>
        </Box>
    )
}


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip;

export default BreadCrumb