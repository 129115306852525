import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import React from 'react'
import InputField from '../../../../Components/InputField'
import EPDSIList from '../../EPDSIList'
import { AppContext } from '../../../../App'
import { useNavigate } from 'react-router-dom'

interface AdderProps {
    open: boolean
    toggle: () => void
}
const Adder = ({ open, toggle }: AdderProps) => {
    const { fetch, dispatch } = React.useContext(AppContext)
    const navigate = useNavigate()

    const [date, setDate] = React.useState((new Date()).toISOString().split('T')[0])
    const [period, setPeriod] = React.useState(1)
    const [examinateur, setExaminateur] = React.useState(0)
    const [name, setName] = React.useState('')

    const changeDate = (date: string) => setDate(date)
    const changePeriod = (value: string) => setPeriod(+value)
    const changeExaminateur = (value: string) => setExaminateur(+value)

    const handleCreate = () => {
        fetch('/api/admin/examens-cliniques', 'POST', { date, period, name, examinateur })
            .then((ec) => dispatch.examensCliniques.post(ec)
                .then(() => navigate(`/epdsi/ecs/${ec.id}`))
            )
    }

    React.useEffect(() => {
        setDate((new Date()).toISOString().split('T')[0])
        setExaminateur(0)
        setPeriod(1)
        setName('')
    }, [open])
    return (
        <>
            <Button onClick={toggle}>
                Créer un enseignement clinique
            </Button>
            <Dialog
                open={open}
                fullWidth={true}
            >
                <DialogTitle>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='h5'>Créer un rapport d'enseignement clinique</Typography>
                        <IconButton onClick={toggle}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={6}>
                            <InputField
                                label={'Date de l\'enseignement clinique'}
                                type='date'
                                value={date}
                                onChange={({ target: { value } }) => changeDate(value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                label={'Période'}
                                value={period}
                                onChange={({ target: { value } }) => changePeriod(value)}
                                select
                                fullWidth
                            >
                                <MenuItem value={1}>Période 1</MenuItem>
                                <MenuItem value={2}>Période 2</MenuItem>
                                <MenuItem value={3}>Période 3</MenuItem>
                            </InputField>
                        </Grid>
                        <Grid item xs={12} >
                            <InputField
                                label={'Examinateur'}
                                value={examinateur !== 0 ? examinateur : ''}
                                onChange={({ target: { value } }) => changeExaminateur(value)}
                                select
                                fullWidth
                            >
                                {EPDSIList.examinateurs.sort((a, b) => (`${a.firstname} ${a.lastname}`).toLocaleLowerCase() < (`${a.firstname} ${a.lastname}`).toLocaleLowerCase() ? -1 : 1).map((exam) =>
                                    <MenuItem key={exam.id} value={exam.id}>{exam.firstname} {exam.lastname}</MenuItem>)}
                            </InputField>
                        </Grid>
                        <Grid item xs={12} >
                            <InputField
                                label={'Nom du patient'}
                                value={name}
                                onChange={({ target: { value } }) => setName(value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggle}>
                        Annuler
                    </Button>
                    <Button onClick={handleCreate} disabled={!date || !examinateur || !name}>
                        Créer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Adder