import { Box, Grid, ThemeOptions } from '@mui/material'
import React from 'react'
import FileUploader from '../../../../../Components/FileUploader'
import { UseEpEditContext } from '../useEpsEdit'
import FormArea from '../../../../../Components/FormArea'
import EmptyFile from './EmptyFile'
import FileList from './FileList'

const EPFiles = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid item xs={12} md={6} lg={4}>
                    <FormArea
                        label="Images du respirateur"
                        sx={{ height: '100%' }}
                        innerSx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
                    >
                        {ep?.respiFiles.length === 0 && <EmptyFile />}
                        <FileList
                            files={ep?.respiFiles ?? []}
                        />
                        <FileUploader entityName={'respiEp'} entityId={ep?.id ?? ''} onUploadEnd={dispatch.setRespiFiles} multiple accept='image/*' />
                    </FormArea>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormArea
                        label="Images du monitorage"
                        sx={{ height: '100%' }}
                        innerSx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
                    >
                        {ep?.scopeFiles.length === 0 && <EmptyFile />}
                        <FileList
                            files={ep?.scopeFiles ?? []}
                        />
                        <FileUploader entityName={'scopeEp'} entityId={ep?.id ?? ''} onUploadEnd={dispatch.setScopeFiles} multiple accept='image/*' />
                    </FormArea>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormArea
                        label="Imageries"
                        sx={{ height: '100%' }}
                        innerSx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
                    >
                        {ep?.radioFiles.length === 0 && <EmptyFile />}
                        <FileList
                            files={ep?.radioFiles ?? []}
                        />
                        <FileUploader entityName={'radioEp'} entityId={ep?.id ?? ''} onUploadEnd={dispatch.setRadioFiles} multiple accept='image/*' />
                    </FormArea>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormArea
                        label="Images de gazométrie"
                        sx={{ height: '100%' }}
                        innerSx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
                    >
                        {ep?.gdsFiles.length === 0 && <EmptyFile />}
                        <FileList
                            files={ep?.gdsFiles ?? []}
                        />
                        <FileUploader entityName={'gdsEp'} entityId={ep?.id ?? ''} onUploadEnd={dispatch.setGdsFiles} multiple accept='image/*' />
                    </FormArea>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                </Grid>

            </Grid>
        </Box>
    )
}

export default EPFiles