import React from 'react'
import InputField from '../../../../../Components/InputField'
import { MenuItem } from '@mui/material'
import systemNames from '../systemNames'


interface SystemSelectorProps {
    value: number | null
    onChange: (value: number) => void
}
const SystemSelector = ({ value, onChange }: SystemSelectorProps) => {
    return (
        <InputField
            fullWidth
            label="Système"
            value={value ?? ''}
            onChange={({ target: { value } }) => onChange(+value)}
            select>
            <MenuItem value="" sx={{ display: 'none' }}>Selectionner un système</MenuItem>
            {systemNames
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map((item) =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
        </InputField>
    )
}

export default SystemSelector