import { TextField } from '@mui/material'
import React from 'react'

interface LabelInputProps {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
    onValidate?: (value: string) => void
}
const LabelInput = ({ value, onChange, disabled, onValidate }: LabelInputProps) => {
    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => onChange(value)
    const handleBlur = () => onValidate && onValidate(value)
    const handleKeyUp = ({ key }: React.KeyboardEvent) => key === 'Enter' && onValidate && onValidate(value)
    return (
        <TextField
            variant='standard'
            type='text'
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            fullWidth
            InputProps={{
                disableUnderline: true
            }}
            disabled={disabled}
        />
    )
}

export default LabelInput