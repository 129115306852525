import React from 'react'
import CoursType from '../Stores/CoursType'
import { Card, CardActionArea, CardMedia, Box, Typography, Tooltip, Avatar, AvatarGroup, AvatarProps } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'

interface CoursRepProps {
    cours: CoursType
    date?: boolean
    hover?: boolean
}
const CoursRep = ({ cours, date = false, hover }: CoursRepProps) => {
    return (
        <Card
            sx={{
                position: 'relative',
                mb: 2,
                height: '100%',
                ...(hover ? {
                    '& .hoverable': {
                        top: '100%',
                        transition: 'top 2s ease 0s'

                    },
                    '&:hover .hoverable': {
                        top: 'auto',
                        transition: 'top 2s ease 0s'

                    }
                } : {})
            }}
        >
            <CardActionArea
                component={Link}
                to={`/epdsi/cours/${cours.id}`}
                sx={{ height: '100%' }}
            >
                {cours.file
                    ? <CardMedia
                        image={"/download/" + cours.file.id + "/1"}
                        sx={{ aspectRatio: '16/9' }}
                    />
                    : <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            aspectRatio: '16/9'
                        }}
                    />}
                <Box className={'hoverable'}
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        p: 1,
                        backgroundColor: 'rgba(0,0,0,.5)',
                        width: '100%',
                        color: "white"
                    }}
                >
                    <Typography
                        variant={'h5'}
                    >
                        {cours.name}
                    </Typography>
                    {cours.subname &&
                        <Typography
                            variant={'h6'}
                        >
                            {cours.subname}
                        </Typography>}
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        gap={2}
                        alignItems={'center'}
                    >
                        <Box>
                            {cours.thematic &&
                                <Tooltip
                                    title={cours.thematic.name}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        gap={1}
                                        alignItems={'center'}
                                    >
                                        {cours.thematic.image &&
                                            <Box
                                                sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%'
                                                }}
                                            >
                                                <LocalAvatar src={`/download/${cours.thematic.image.id}`} />
                                            </Box>}
                                    </Box>
                                </Tooltip>}
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}>
                            {date &&
                                <Typography
                                    variant={'caption'}
                                >
                                    {cours.date.slice(0, 10).split('-')[2]}/{cours.date.slice(0, 10).split('-')[1]}/{cours.date.slice(0, 10).split('-')[0]}
                                </Typography>}
                            <Typography
                                variant={'caption'}
                            >
                                {cours.date.slice(11, 16)}
                            </Typography>
                        </Box>
                        <Box>
                            {cours.intervenants.length === 1 && <Box
                                display={'flex'}
                                flexDirection={'row'} gap={2}
                                alignItems={'center'}>
                                <AvatarGroup>
                                    {cours.intervenants.map(I =>
                                        <Tooltip
                                            title={`${I.firstname} ${I.lastname}`}
                                            key={I.id}
                                        >
                                            <LocalAvatar src={`/download/${I.imageId}`} alt={`${I.firstname[0]}${I.lastname[0]}`} />
                                        </Tooltip>)}
                                </AvatarGroup>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    )
}

interface LocalAvatarProps {
    src: string
}
const LocalAvatar = React.forwardRef((props: LocalAvatarProps & AvatarProps, ref) => {
    const theme = useTheme() as { breakpoints: any }
    return (
        //@ts-ignore
        <Avatar
            ref={ref}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    width: 30,
                    height: 30
                }
            }}
            {...props}
        />
    )
})

export default CoursRep