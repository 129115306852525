import React from "react"
import { PageInterfaceProps } from "."
import { configSelectorType } from "../FileManager/types"

const usePage = (value: PageInterfaceProps) => {
    const [tab, setTab] = React.useState<string>((value.tabs && value.tabs.length > 0 && value.tabs[0].label) as string ?? '')
    const contextValue = React.useMemo(() => ({}), [])
    const [configFileSelector, setConfigFileSelector] = React.useState<configSelectorType>(null)

    React.useEffect(() => {
        if (value.resetTab !== undefined && (value.tabs && value.tabs.length > 0)) {
            setTab(value.tabs[0].label)
        }
    }, [value.resetTab])

    return { ...value, tab, setTab, openFileSelector: setConfigFileSelector, configFileSelector }
}

export default usePage