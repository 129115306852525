import { AccountCircleRounded, Add, ContentPaste, DragHandle, FormatListBulleted, FormatListNumbered, HighlightAlt, Image, ListOutlined, Title } from '@mui/icons-material'
import { Avatar, Box, Button, ButtonGroup, Divider, MenuItem, Typography, TypographyProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import InputField from '../../../../Components/InputField'
import { v4 } from 'uuid'
import { ChapitreAvatarImageContent, ChapitreAvatarTextContent, ChapitreFlexContent, ChapitreImageContent, ChapitreListContent, ChapitreTextContent, ContentBase, ContentItem } from '../../../../Stores/ChapitreType'
import DeleteButton from '../../../../Components/DeleteButton'
import { ChapitreContext } from '../useEditChapitre'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import EditDrawer from './EditDrawer'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import NoImage from '../../../../Components/NoImage'

const ContentTab = () => {
    const { chapitre, dispatch } = React.useContext(ChapitreContext)

    const onDrageEnd = ({ active, over }: DragEndEvent) => {
        const oldIndex = active.data.current?.sortable.index ?? undefined
        const newIndex = over?.data.current?.sortable.index ?? undefined
        const parentId: string | null = active.data.current?.parentId

        if (oldIndex === undefined || newIndex === undefined || oldIndex === newIndex) return
        dispatch.sortValueItem(parentId, oldIndex, newIndex)
    }
    return (
        <Box display={'flex'} flexDirection={'column'} maxHeight={'100%'} pb={2}>
            {chapitre!.value.length === 0 && <Typography variant="caption" color="GreyText">Aucun contenu.</Typography>}
            <DndContext onDragEnd={onDrageEnd}>
                <SortableContext items={chapitre!.value.map(v => v.id)}>
                    <Box p={2} gap={2} display={'flex'} flexDirection={'column'} sx={{ overflowY: 'auto' }}>
                        {chapitre!.value.map(v => <ChildSwitcher key={v.id} item={v} parentId={null} />)}
                    </Box>
                </SortableContext>
            </DndContext>
            <ContentAdder parentId={null} />
            <EditDrawer />
        </Box>
    )
}

const ChildSwitcher = ({ item, parentId }: { item: ContentItem, parentId: string | null }) => {
    switch (item.type) {
        case 1: return <ContentText item={item} parentId={parentId} />
        case 2: return <ContentList item={item} parentId={parentId} />
        case 3: return <ContentList item={item} parentId={parentId} />
        case 4: return <ContentFlex item={item} parentId={parentId} />
        case 5: return <ContentImage item={item} parentId={parentId} />
        case 6: return <ContentAvatar item={item} parentId={parentId} />
    }
}

interface ContentAdderProps {
    parentId: string | null,
}
const ContentAdder = ({ parentId }: ContentAdderProps) => {
    const { copyItem, dispatch } = React.useContext(ChapitreContext)

    const handleCopy = (event: any) => {
        event.preventDefault();
        event.stopPropagation()
        dispatch.pasteCopyItem(parentId)
    }

    const newItem = (type: number) => ({
        id: v4(),
        type,
        ...{
            1: {
                text: '',
                variant: 'body1'
            },
            2: {
                childs: []
            },
            3: {
                childs: []
            },
            4: {
                isFlex: true,
                flexGrow: 1,
                direction: 'row',
                gap: 0,
                alignItems: 'center',
                justifyContent: 'flex-start',
                childs: [],
            },
            5: {
                src: null
            },
            6: {}
        }[type] ?? {},
    })

    return (
        <Box display={'flex'} textAlign={'center'} gap={2} justifyContent={'center'}>
            <ButtonGroup>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(1) as ChapitreTextContent)}><Title /></Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(2) as ChapitreListContent)}><FormatListBulleted /></Button>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(3) as ChapitreListContent)}><FormatListNumbered /></Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(4) as ChapitreFlexContent)}><HighlightAlt /></Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(5) as ChapitreImageContent)}><Image /></Button>
                <Button onClick={() => dispatch.addValueItem(parentId, newItem(6) as ChapitreImageContent)}><AccountCircleRounded /></Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button onClick={handleCopy} disabled={!copyItem}><ContentPaste /></Button>
            </ButtonGroup>
        </Box>
    )
}

interface ContentItemProps {
    item: ContentItem,
    parentId: string | null
}
const SortableItem = ({ item, parentId, children }: ContentItemProps & PropsWithChildren) => {
    const { dispatch } = React.useContext(ChapitreContext)
    const handleClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch.setEditedItem(item.id)
    }
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: item.id, data: { parentId } })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Box sx={{
            '& fieldset': {
                borderRadius: 2
            },
            ...(item.type === 4 && { flexGrow: item.flexGrow })
        }}
            ref={setNodeRef} style={style} {...attributes}
        >
            <fieldset>
                <legend>{{
                    1: 'Texte',
                    2: 'Liste à puces',
                    3: 'Liste numérotée',
                    4: 'Flex',
                    5: 'Image',
                    6: 'Avatar',
                }[item.type]}</legend>
                <Box display={'flex'} gap={1} position={'relative'} alignItems={'center'} sx={{
                    [`& .hoverable-${item.id}`]: { visibility: 'hidden' },
                    [`&:hover .hoverable-${item.id}`]: { visibility: 'visible' },
                }} onClick={handleClick}>
                    <Box className={`hoverable-${item.id}`}>
                        <DragHandle {...listeners} />
                    </Box>
                    <Box flexGrow={1}>
                        {children}
                    </Box>
                    <Box className={`hoverable-${item.id}`}>
                        <DeleteButton onDelete={() => dispatch.deleteValueItem(item.id)} confirm={false} />
                    </Box>
                </Box>
            </fieldset>
        </Box>
    )
}

const ContentText = ({ item, parentId }: ContentItemProps) => {
    const { dispatch } = React.useContext(ChapitreContext)
    const [text, setText] = React.useState<string>((item as ChapitreTextContent).text)

    const handleSave = (event: React.KeyboardEvent | React.FocusEvent) => {
        if (event.type === 'blur' || (event.type === 'keyup' && (event as React.KeyboardEvent).key === 'Enter') && text !== (item as ChapitreTextContent).text) {
            dispatch.updateValueItem(item.id, { text })
        }
    }

    const handleSaveTb = (partial: Partial<ContentItem>) => dispatch.updateValueItem(item.id, partial)


    return (
        <SortableItem item={item} parentId={parentId}>
            <Typography variant={(item as ChapitreTextContent).variant} dangerouslySetInnerHTML={{ __html: (item as ChapitreTextContent).text.replaceAll("\n", '<br/>') }} />
        </SortableItem>
    )
}
const ContentList = ({ item, parentId }: ContentItemProps) => {
    const Component = item.type === 3 ? 'ol' : 'ul'
    return (
        <SortableItem item={item} parentId={parentId}>
            <SortableContext items={item!.childs!.map(v => v.id)}>
                <Component>
                    {item!.childs!.map(v => <li key={v.id}><ChildSwitcher item={v} parentId={item.id} /></li>)}
                </Component>
            </SortableContext>
            <ContentAdder parentId={item.id} />
        </SortableItem>
    )
}

const ContentFlex = ({ item, parentId }: ContentItemProps) => {
    return (
        <SortableItem item={item} parentId={parentId}>
            <SortableContext items={item!.childs!.map(v => v.id)}>
                <Box
                    display={(item as ChapitreFlexContent).isFlex ? 'flex' : undefined}
                    flexDirection={(item as ChapitreFlexContent).direction}
                    gap={(item as ChapitreFlexContent).gap}
                    flexGrow={(item as ChapitreFlexContent).flexGrow}
                    alignItems={(item as ChapitreFlexContent).alignItems}
                    justifyContent={(item as ChapitreFlexContent).justifyContent}>
                    {item!.childs!.map(v => <ChildSwitcher key={v.id} item={v} parentId={item.id} />)}
                </Box>
            </SortableContext>
            <ContentAdder parentId={item.id} />
        </SortableItem>
    )
}

const ContentImage = ({ item, parentId }: ContentItemProps) => {
    const { dispatch } = React.useContext(ChapitreContext)



    return (
        <SortableItem item={item} parentId={parentId}>
            {(item as ChapitreImageContent).src
                ? <img src={`/download/${(item as ChapitreImageContent).src}`} style={{
                    width: (item as ChapitreImageContent).width ?? undefined,
                    height: (item as ChapitreImageContent).height ?? undefined,
                    objectFit: (item as ChapitreImageContent).objectFit ?? undefined,
                }} />
                : <NoImage />
            }

        </SortableItem>
    )
}

const ContentAvatar = ({ item, parentId }: ContentItemProps) => {
    const { dispatch } = React.useContext(ChapitreContext)

    return (
        <SortableItem item={item} parentId={parentId}>
            <Avatar
                src={(item as ChapitreAvatarImageContent).src ? `/download/${(item as ChapitreAvatarImageContent).src}` : undefined}>
                {(item as ChapitreAvatarTextContent).text ?? ''}
            </Avatar>
        </SortableItem>
    )
}


export default ContentTab