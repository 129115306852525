import { PictureAsPdf } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React from 'react'
import File from '../../Stores/FileType'

interface PdfPreviewProps {
    pdf: File
}
const PdfPreview = ({ pdf }: PdfPreviewProps) => {
    const [open, toggle] = React.useReducer(o => !o, false)
    return (
        <>
            <IconButton onClick={toggle}>
                <PictureAsPdf />
            </IconButton>
            <Dialog open={open} onClose={toggle} fullWidth maxWidth="lg">
                <iframe src={`/download/${pdf.id}`} style={{ width: '100%', height: '80vh', border: 'none' }} />
            </Dialog>
        </>
    )
}

export default PdfPreview