import { Upload } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

interface FileBoxProps {
    visible: boolean
}
const FileBox = ({ visible }: FileBoxProps) => {
    if (!visible) return (<></>)
    return (
        <Box position={'fixed'} top={0} left={0} right={0} bottom={0} display={'flex'} zIndex={2000} sx={{ backgroundColor: theme => theme.palette.mode === 'light' ? '#808080a8' : '#111010a8' }} p={2}>
            <Box sx={{
                borderRadius: 4,
                border: `4px dashed blue`,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme => theme.palette.background.default
            }}>
                <Box><Upload sx={{ fontSize: 80 }} /></Box>
                <Box><Typography variant={'h6'}>Déposer un fichier pour le téléverser</Typography></Box>
            </Box>
        </Box>
    )
}

export default FileBox