import { TabContext, TabList } from '@mui/lab'
import { Divider, Tab } from '@mui/material'
import { tab } from '@testing-library/user-event/dist/tab'
import React from 'react'
import { TableContext } from '../useTable'

function CustomFilters() {
    const { tab, dispatch, customFilters } = React.useContext(TableContext)
    return (
        <TabContext value={tab} >
            <TabList onChange={(_, value) => dispatch.setTab(value)} variant='scrollable'>
                <Tab value={'all'} label={'tout'} sx={{ flexGrow: 0 }} />
                {customFilters!.map(f => <Tab key={f.name} value={f.name} label={f.name} sx={{ flexGrow: 0 }} />)}
            </TabList>
            <Divider />
        </TabContext>
    )
}

export default CustomFilters