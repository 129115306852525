import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem } from '@mui/material'
import React from 'react'
import { DocumentContext } from '../useDocument'
import { Check, Close, Delete, DragHandle, Edit } from '@mui/icons-material'
import DeleteButton from '../../../../../Components/DeleteButton'
import { QuestionType, ResponseType } from '../../../../../Stores/CoursType'
import InputField from '../../../../../Components/InputField'
import { v4 } from 'uuid'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const TabQuestions = () => {
    const { cours, dispatch } = React.useContext(DocumentContext)
    const [edit, setEdit] = React.useState<QuestionType | undefined>()

    const handleAddQuestion = () => setEdit(prev => ({
        ...prev!,
        reponses: [...prev!.reponses, { id: v4(), value: '', correct: false }]
    }))

    const onDragEnd = (event: DragEndEvent) => {
        console.log(event.over)

        const activeId = event.active.data.current!.sortable.index
        const overId = event.over!.data.current!.sortable.index
        setEdit(prev => ({
            ...prev!,
            reponses: arrayMove(prev!.reponses, activeId as number, overId as number)
        }))
    }

    const handleSave = () => {
        dispatch.saveQuestion(edit!)
        setEdit(undefined)
    }
    return (
        <Box ml={1} mr={1} p={1} display={'flex'} flexDirection={'column'} gap={1}>
            <Dialog open={Boolean(edit)} maxWidth={'lg'} fullWidth >
                <DialogTitle>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box>Edition de la question : {edit?.question ?? ''} </Box>
                        <Box>
                            <IconButton onClick={() => setEdit(undefined)}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <InputField
                            label="Enoncé de la question"
                            value={edit?.question ?? ''}
                            onChange={({ target: { value } }) => setEdit(prev => ({ ...prev!, question: value }))}
                            multiline
                            fullWidth
                        />
                        <InputField
                            label="Type de question"
                            value={edit?.type ?? 0}
                            onChange={({ target: { value } }) => setEdit(prev => ({ ...prev!, type: +value, reponses: +value === 2 ? [{ id: v4(), value: 'Vrai', correct: false }, { id: v4(), value: 'Faux', correct: false }] : [] }))}
                            fullWidth
                            error={edit?.type === 0 && edit?.reponses.filter(el => el.correct).length > 1}
                            select
                        >
                            {['Choix unique', 'Choix multiple', 'Vrai/Faux'].map((el, i) => <MenuItem key={i} value={i}>{el}</MenuItem>)}
                        </InputField>
                        <Divider />
                        <Box>
                            <Button onClick={handleAddQuestion}>
                                Ajouter une réponse
                            </Button>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                            <DndContext
                                onDragEnd={onDragEnd}
                            >
                                <SortableContext
                                    items={edit?.reponses.map((el, i) => el.id) ?? []}
                                >
                                    {edit?.reponses.map((el, i) => <QuestionItem reponse={el} setQuestion={setEdit} key={i} />)}
                                </SortableContext>
                            </DndContext>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEdit(undefined)}>
                        Annuler
                    </Button>
                    <Button disabled={
                        !edit?.question ||
                        edit?.reponses.length === 0 ||
                        ((edit?.type === 0 || edit?.type === 2) && edit?.reponses.filter(r => r.correct).length !== 1) ||
                        edit?.type === 2 && edit?.reponses.length !== 2
                    } onClick={handleSave}>
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            {cours?.questions.map((q, i) =>
                <Box key={q.id} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}
                    sx={{
                        p: 1,
                        '& .hoverable': {
                            visibility: 'hidden'
                        },
                        '&:hover .hoverable': {
                            visibility: 'visible',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                        }

                    }}>
                    <Box flexGrow={1}>{q.question}</Box>
                    <Box>
                        {q.type === 0 ? 'Choix unique' :
                            q.type === 1 ? 'Choix multiple' :
                                'Vrai/Faux'}
                    </Box>
                    <Box className={'hoverable'}>
                        <IconButton onClick={() => setEdit(q)}>
                            <Edit />
                        </IconButton>
                        <DeleteButton
                            onDelete={() => dispatch.deleteQuestion(q.id)}
                        />

                    </Box>
                </Box>)}
        </Box>
    )
}

interface QuestionItemProps {
    reponse: ResponseType
    setQuestion: React.Dispatch<React.SetStateAction<QuestionType | undefined>>
}
const QuestionItem = ({
    reponse,
    setQuestion
}: QuestionItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: reponse.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };

    const handleChangeReponse = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, value }) : el)
        }))
    }

    const handleCorrect = () => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: true }) : el)
        }))
    }
    const handleInCorrect = () => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: false }) : el)
        }))
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
            style={style}
            ref={setNodeRef}
            {...attributes}
            sx={{
                '& .hoverable': { visibility: 'hidden' },
                '&:hover .hoverable': { visibility: 'visible' },
                '& .hoverable[data-byPass="true"]': { visibility: 'visible' },
            }}>
            <Box className={'hoverable'}>
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                <InputField
                    label="Enoncé de la reponse"
                    value={reponse.value}
                    onChange={handleChangeReponse}
                    multiline
                    fullWidth
                />
            </Box>
            <Box >
                <IconButton className={'hoverable'} data-byPass={reponse.correct} color={reponse.correct ? 'primary' : undefined} onClick={handleCorrect}>
                    <Check />
                </IconButton>
                <IconButton className={'hoverable'} data-byPass={!reponse.correct} color={!reponse.correct ? 'secondary' : undefined} onClick={handleInCorrect}>
                    <Close />
                </IconButton>
            </Box>
            <Box>
                <IconButton color={'error'} className={'hoverable'} onClick={() => setQuestion(prev => ({ ...prev!, reponses: prev!.reponses.filter(el => el.id !== reponse.id) }))}>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TabQuestions