import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import { UseEpEditContext } from '../../useEpsEdit'
import { PrimeAnalyseType } from '../../../../../../Stores/ExploitationPratiqueType'
import { Warning } from '@mui/icons-material'
import { CSS } from '@dnd-kit/utilities'

const Footer = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    const els = ep?.primeAna.filter(p => typeof p.prioritaire === 'number').sort((a, b) => a.prioritaire! < b.prioritaire! ? -1 : 1) ?? []

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 2 } }),
        useSensor(TouchSensor),
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        const oldIndex = active.data.current?.sortable.index
        const newIndex = over!.data.current?.sortable.index

        const newEls = arrayMove([...els], oldIndex, newIndex).map((el, index) => ({ ...el, prioritaire: index }))
        dispatch.setPrimeAnaPriorityBulk(newEls)
    }

    return (
        <Box sx={{
            p: 1,
            pl: 2,
            pr: 2,
        }}>
            {els.length === 0
                ? <Typography variant='caption' color={'error'} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><Warning />Aucun problème prioritaire</Typography>
                : <Typography variant='button'>Problème{els.length > 1 ? 's' : ''} prioritaire{els.length > 1 ? 's' : ''}</Typography>}
            <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                <DndContext
                    sensors={sensors}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        items={els.map(p => p.id.toString())}
                    >
                        {els.map(e => <FooterElement key={e.id} el={e} />)}
                    </SortableContext>
                </DndContext>
            </Box>
        </Box>
    )
}

const FooterElement = ({ el }: { el: PrimeAnalyseType }) => {
    const { problems } = React.useContext(UseEpEditContext)
    const pb = problems?.map(p => p.problems).flat().find(p => p.id === el.problem)
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: el.id.toString() })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };
    return (
        <Chip
            label={pb?.name ?? ''}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        />
    )
}
export default Footer