import { TableBody, TableRow, TableCell, Typography, Box, Button, Tooltip, IconButton, Collapse } from '@mui/material'
import React from 'react'
import { TableContext } from '../useTable'
import NoDatas from './NoDatas'
import TableCollapserButton from './TableCollapserButton'
import Row from './Row'

const Body = () => {
    const { datas, columns, buttons, collapse, collapsed } = React.useContext(TableContext)
    return (
        <TableBody>
            {datas.length === 0 && <NoDatas />}
            {datas.map((data, index) => <Row key={data.id} data={data} index={index} />)}
        </TableBody>
    )
}

export default Body