import React from "react";
import FileBox from "./FileBox";
import FileTable from "./FileTable";
import { UseFileManagerType, configSelectorType } from "./types";
import { AppContext } from "../../App";
import FileType from "../../Stores/FileType";
import FileSelector from "./FileSelector";

const useFileManager: UseFileManagerType = (configObject, configSelector) => {
    const { fetch } = React.useContext(AppContext)

    const [dropZoneVisible, setDropZoneVisible] = React.useState<boolean>(false)

    const onDragEnter = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        dropZoneVisible !== true && setDropZoneVisible(true)
    }
    const onDragLeave = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDropZoneVisible(false)
    }
    const onDrop = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDropZoneVisible(false)
        if (event.dataTransfer?.files) {
            const { files } = event.dataTransfer
            for (let i = 0, mx = files.length; i < mx; i++) {
                const file = files[i]
                upload(file)
            }
        }
    }
    const upload = (file: File) => {

        let form = new FormData()
        form.append('file', file)
        return fetch(`/api/admin/files${configObject!.uploadUrl}`, 'POST', form)
            .then((file: FileType) => {
                configObject?.addFile(file)
            })
    }

    const handleDelete = (file: FileType) => {
        if (!configObject) return
        configObject.removeFile(file)
        fetch(`/api/admin/files/${file.id}`, 'DELETE')
            .then((resp) => resp.status !== 200 && configObject.addFile(file))
            .catch(() => configObject.addFile(file))
    }

    React.useEffect(() => {
        if (configObject) {
            window.addEventListener('dragover', onDragEnter)
            window.addEventListener('dragenter', onDragEnter)
            window.addEventListener('dragleave', onDragLeave)
            window.addEventListener('dragend', onDragLeave)
            window.addEventListener('drop', onDrop)
        } else {
            window.removeEventListener('dragover', onDragEnter)
            window.removeEventListener('dragenter', onDragEnter)
            window.removeEventListener('dragleave', onDragLeave)
            window.removeEventListener('dragend', onDragLeave)
            window.removeEventListener('drop', onDrop)
        }
        return () => {
            window.removeEventListener('dragover', onDragEnter)
            window.removeEventListener('dragenter', onDragEnter)
            window.removeEventListener('dragleave', onDragLeave)
            window.removeEventListener('dragend', onDragLeave)
            window.removeEventListener('drop', onDrop)
        }
    }, [configObject])

    return React.useMemo(() => configObject ? ({
        renderFileBox: <FileBox visible={dropZoneVisible} />,
        renderFileTable: <FileTable files={configObject?.files.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1) ?? []} handleDelete={handleDelete} />,
        renderSelector: <FileSelector config={configSelector ?? null} />
    }) : undefined, [configObject, configSelector])
}

export default useFileManager