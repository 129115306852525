import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import CardItem from "./CardItem";
import { KanbanCard } from "../../../Stores/KanbanType";

interface CardItemSortableProps {
    id: string
    card: KanbanCard
}
const CardItemSortable = ({ id, card }: CardItemSortableProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id, data: {
            card
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <div
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        >
            <CardItem id={id} card={card} dragOverlay={false} />
        </div>
    );
};

export default CardItemSortable;
