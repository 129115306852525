import React from 'react'
import Page from '../../../Components/PageInterface/Page'
import LogoEPDSI from '../../../assets/epdsi.png'
import { Box, Button, Divider, Grid, Tab, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Groupes from './Groupes'
import Plan from './Plan'
import { AppContext } from '../../../App'
import CoursRep from '../../../Components/CoursRep'
import { TabContext, TabList } from '@mui/lab'
import CoursType from '../../../Stores/CoursType'


const Dashboard = () => {
    const { dispatch } = React.useContext(AppContext)
    const [cours, setCours] = React.useState<CoursType[]>()
    const [coursTab, setCoursTab] = React.useState('today')

    const ref = React.useRef<HTMLElement | null>(null)

    const todayCours = cours?.filter(c => c.date.startsWith(new Date().toISOString().slice(0, 10))) ?? []
    const pastCours = cours?.filter(c => c.date.slice(0, 10) < new Date().toISOString().slice(0, 10)) ?? []
    const futureCours = cours?.filter(c => {
        if (c.date.slice(0, 10) < new Date().toISOString().slice(0, 10)) return false
        else if (
            c.date.slice(0, 10) === new Date().toISOString().slice(0, 10)
            &&
            +c.date.slice(11, 13) < new Date().getHours()
        ) return false
        return true
    }) ?? []
    React.useEffect(() => {
        dispatch.cours.getAll(setCours).then(setCours)
    }, [])
    return (
        <Page
            title={'Etudes PostDiplôme en Soins Intensifs'}
            subtitle='Volée 29 — Juin 2023/2025'
            image={{ asset: LogoEPDSI }}
            loading={false}
            fullPage
            children={<React.Fragment>
                <Box ref={ref} display={'flex'} flexDirection={'column'} gap={1} height={'100%'} sx={{ overflowY: 'auto' }} >
                    <Box display={'flex'} flexDirection={'row'} gap={2}>
                        <Button component={Link} to="https://oweb.chuv.ch/owa/#path=/calendar/view/Day" target='_blank'>
                            Calendrier
                        </Button>
                        <Button component={Link} to="https://eformation.chuv.ch/course/view.php?id=216" target='_blank'>
                            Moodle
                        </Button>
                        <Groupes />
                        <Plan />
                    </Box>
                    <Divider sx={{ mt: -1, mb: -1 }} />
                    <Box>
                        <TabContext value={coursTab}>
                            <TabList onChange={(_, value) => setCoursTab(value)}>
                                <Tab value="all" label="Tous les cours" />
                                <Tab value="past" label="Passés" />
                                <Tab value="today" label="Aujourd'hui" />
                                <Tab value="future" label="A venir" />
                            </TabList>
                            <Divider />
                        </TabContext>
                    </Box>
                    <Box flexGrow={1} sx={{ overflowY: 'hidden' }} ref={ref}>
                        <Grid container sx={{ height: '100%', overflowY: 'auto' }}>
                            {((coursTab === 'today' && todayCours) || (coursTab === 'past' && pastCours) || (coursTab === 'future' && futureCours) || (cours ?? []))
                                .sort((a, b) => coursTab === 'past'
                                    ? a.date > b.date ? -1 : 1
                                    : a.date < b.date ? -1 : 1)
                                .map(c => <Grid item key={c.id} xs={12} md={4} lg={3}>
                                    <Box pl={2} pr={2}>
                                        <CoursRep key={c.id} cours={c} date={'today' !== coursTab} />
                                    </Box>
                                </Grid>)}
                        </Grid>
                    </Box>
                </Box>
            </React.Fragment >}
        />
    )
}

export default Dashboard