import { Add } from '@mui/icons-material'
import { IconButton, ListSubheader, Menu, MenuItem } from '@mui/material'
import React from 'react'
import SolutionContext from './SolutionContext'

const Adder = () => {
    const { availableSolutions, selectedSolutions, dispatch } = React.useContext(SolutionContext)
    const [anchor, setAnchor] = React.useState<null | HTMLButtonElement>(null)
    return (
        <React.Fragment>
            <IconButton onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                <Add />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
            >
                {availableSolutions?.filter(s => s.isActif)
                    .filter(solution => !selectedSolutions.map(s => s.id).includes(solution.id))
                    .map(solution => solution.type)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map(type => <div key={type}>
                        <ListSubheader>{type}</ListSubheader>
                        {availableSolutions?.filter(solution => solution.type === type)
                            .filter(solution => !selectedSolutions.map(s => s.id).includes(solution.id))
                            .map(solution => <MenuItem key={solution.id} onClick={() => dispatch.addSolution(solution)}>{solution.name}</MenuItem>)}
                    </div>)}
            </Menu>
        </React.Fragment>
    )
}

export default Adder