import { Radio, TableCell } from '@mui/material'
import React from 'react'
import { EditEcContext } from '../useEditEc'
import EPDSIList from '../../../EPDSIList'

interface RadioEvalProps {
    processus: number
    competence: number
    item: number
    value: number
}
const RadioEval = ({ processus, competence, item, value }: RadioEvalProps) => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    const handleClick = () => dispatch.setEval(processus, competence, item, value)
    const isChecked = Boolean((ec!.evals ?? []).find(evaluation => evaluation.processus === processus && evaluation.competence === competence && evaluation.item === item && evaluation.value === value)) ?? false
    const isDefault = (EPDSIList.processus.find(proces => proces.id === processus)?.competences.find(comp => comp.id === competence)?.items.find(itemel => itemel.id === item)?.attended[ec!.period] ?? -1) === value

    return (
        <TableCell sx={{ backgroundColor: (theme) => isDefault ? theme.palette.primary.light : undefined }}>
            <Radio name={`radioEval-${processus}`} onClick={handleClick} checked={isChecked} />
        </TableCell>
    )
}

export default RadioEval