import { Add, Edit } from '@mui/icons-material'
import React from 'react'
import CardTable from '../../../Components/CardTable/CardTable'
import { ChapitreContext } from './useEditChapitre'
import { useNavigate } from 'react-router-dom'
import { Chip } from '@mui/material'

const SubChapters = () => {
    const { chapitre, dispatch } = React.useContext(ChapitreContext)
    const navigate = useNavigate()
    const handleEdit = (id: string) => navigate(`/chuv/guide-de-poche/${id}`)
    const handleAdd = () => dispatch.addChapter()
    return (
        <CardTable
            columns={[
                { field: 'position', header: { label: '#' }, body: (data) => <>{data.position + 1}</> },
                { field: 'name', header: { label: 'Nom du chapitre' }, search: (data, search) => data.name.toLocaleLowerCase('fr').includes(search) },
                { field: 'status', header: { label: '' }, body: (data) => data.status === 2 ? <Chip label="Finalisé" size='small' variant="outlined" color='success' /> : data.status === 1 ? <Chip label="MAJ" size='small' variant="outlined" color='warning' /> : <Chip label="A faire" size='small' variant="outlined" color='error' /> },
            ]}
            datas={chapitre!.chapitres ?? []}
            buttons={[
                { icon: Add, label: 'Créer un chapitre', onClick: handleAdd, rounded: true, isHeader: true },
                { icon: Edit, label: 'Editer', onClick: ({ id }) => handleEdit(id), rounded: true }
            ]}
            noCard
            customFilters={[
                { name: 'A faire', filter: (datas) => datas.filter(d => d.status === 0) },
                { name: 'A mettre à jour', filter: (datas) => datas.filter(d => d.status === 1) },
                { name: 'Finalisé', filter: (datas) => datas.filter(d => d.status === 2) }
            ]} />
    )
}

export default SubChapters