import React from "react"
import NutritiveSolution from "../../../../Stores/NutritiveSolutionType"
import { AppContext } from "../../../../App"

type useNutritiveSolutionType = (id: NutritiveSolution['id']) => NutritiveSolutionContextType
type useNutitriveSolutionDispatchType = {
    update: (fragment: Partial<NutritiveSolution>) => Promise<void>
}
type NutritiveSolutionContextType = {
    nutritiveSolution: NutritiveSolution | undefined,
    dispatch: useNutitriveSolutionDispatchType
}

export const NutritiveSolutionContext = React.createContext<NutritiveSolutionContextType>({} as NutritiveSolutionContextType)

const useNutritiveSolution: useNutritiveSolutionType = (id) => {
    const { nutritiveSolutions, fetch, dispatch: storeDispatch } = React.useContext(AppContext)
    const [solution, setSolution] = React.useState<NutritiveSolution>()

    const dispatch: useNutitriveSolutionDispatchType = React.useMemo(() => ({
        update: async (fragment) => fetch(`/api/admin/nutritive-solutions/${id}`, 'PATCH', fragment)
            .then((ns) => storeDispatch.nutritiveSolutions.patch(id, ns)),
    }), [id, solution])

    React.useEffect(() => {
        if (!solution) storeDispatch.nutritiveSolutions.get(id!, () => { }).then((s) => s && setSolution(s))
        const sol = nutritiveSolutions?.find(n => id === n.id) ?? undefined
        setSolution(sol)
    }, [id, solution])
    React.useEffect(() => {
        const sol = nutritiveSolutions?.find(n => id === n.id) ?? undefined
        setSolution(sol ?? solution)
    }, [nutritiveSolutions])
    return {
        nutritiveSolution: solution,
        dispatch
    }
}

export default useNutritiveSolution
