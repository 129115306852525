import { Box, Select, MenuItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material"
import ThematicType from "../Stores/ThematicType"
import { AppContext } from "../App"
import React from "react"
import InputField from "./InputField"


interface ThematicSelectorProps {
    selectedThematic: ThematicType | null,
    onSelect: (thematic: ThematicType) => void
    label?: string
}

const ThematicSelector = ({ selectedThematic, onSelect, label = 'Choisir une thématique' }: ThematicSelectorProps) => {
    const { thematics } = React.useContext(AppContext)
    const handleSelect = (id: ThematicType['id']) => onSelect(thematics!.find(t => t.id === id)!)
    if (!thematics) return <></>
    return (
        <Box>
            <InputField
                label={label}
                value={selectedThematic?.id ?? ''}
                onChange={({ target: { value } }: any) => handleSelect(value)}
                fullWidth
                select
                sx={{
                    '& .MuiSelect-select': { display: 'flex' }
                }}
            >
                {thematics?.map(thematic =>
                    <MenuItem key={thematic.id} value={thematic.id} sx={{ display: 'flex' }}>
                        <ListItemAvatar>
                            <Avatar src={`/download/${thematic.imageId}`} variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText primary={thematic.name} />
                    </MenuItem>)}
            </InputField>
        </Box>
    )
}



export default ThematicSelector