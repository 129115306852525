import React from "react"
import { BaseEntityType, DBInterface, FetchType, dbApiType } from "./types"
import { StoreNames } from "idb"

export interface GernericStoreDispatch<T extends BaseEntityType> {
    getAll: (idbCallback?: (El: T[] | undefined) => void) => Promise<T[] | undefined>
    get: (id: T['id'], idbCallback: (El: T | undefined) => void) => Promise<T | undefined>
    post: (el: T) => Promise<void>
    posts: (el: T[]) => Promise<void>
    patch: (id: T['id'], fragment: Partial<T>) => Promise<void>
    delete: (id: T['id']) => Promise<void>
}


export default function useGenericStore<T extends BaseEntityType>(baseApi: StoreNames<DBInterface>, fetch: FetchType<any>, db: dbApiType) {
    const [store, setStore] = React.useState<T[]>()
    const dispatch: GernericStoreDispatch<T> = {
        getAll: async (idb) => {
            if (idb) {
                idb(store ?? undefined)

            }
            return fetch(`/api/admin/${baseApi}`, 'GET')
                .then((resp: T[] | undefined) => {
                    if (!resp) return
                    setStore(resp)
                    db.clear(baseApi).then(() => {
                        Promise.all(
                            //@ts-ignore
                            resp.map((el: T) => db.post(baseApi, el))
                        )
                    })
                    return resp
                })
        },
        get: async (id, inMemoryCb) => {
            inMemoryCb(store?.find(e => e.id === id) ?? undefined)

            return fetch(`/api/admin/${baseApi}/${id}`, 'GET')
                .then((newEl: T | undefined) => {
                    newEl && setStore(prev => prev?.map(el => el.id).includes(id) ? prev.map(el => el.id === id ? newEl : el) : [...(prev ?? []), newEl])
                    //@ts-ignore
                    newEl && db.post(baseApi, newEl)
                    return newEl
                })
        },
        post: async (el: T) => {
            //@ts-ignore
            db.post(baseApi, el)
            setStore(store => [...(store?.filter(e => e.id !== el.id) ?? []), el])
        },
        posts: async (els: T[]) => {
            setStore(els)
            db.clear(baseApi).then(() => {
                //@ts-ignore
                els.map((el: T) => db.post(baseApi, el))
            })
        },
        patch: async (id: T['id'], fragment: Partial<T>) => {
            let newT: T | undefined = store!.find(k => k.id === id)
            if (newT) {
                setStore(prev => prev!.map(el => el.id === id ? { ...el, ...fragment } : el))
                //@ts-ignore
                db.post(baseApi, { ...newT, ...fragment })
            }
        },
        delete: async (id: T['id']) => fetch(`/api/admin/${baseApi}/${id}`, 'DELETE')
            .then(() => setStore(store => store?.filter(el => el.id !== id))),
    }

    React.useEffect(() => {
        //@ts-ignore
        db.getAll(baseApi).then((els: T[]) => setStore(els))
        // dispatch.getAll()
    }, [])

    return { store, dispatch }
}
// const useGenericStore: GenericStoreType2<T, Name> = (baseApi, fetch, db) => {
//     const [store, setStore] = React.useState<T[]>()
//     const dispatch: GenericStoreDispatchType<T> = {
//         getAll: async () => {
//             return fetch(`/api/admin/${baseApi}`, 'GET')
//                 .then(resp => {
//                     setStore(resp);
//                     db.clear(baseApi).then(() => {
//                         Promise.all(
//                             resp.map((el: T) => db.post(baseApi, el))
//                         )
//                     })
//                     return resp
//                 })
//         },
//         get: async (id, inMemoryCb) => {
//             inMemoryCb(store?.find(e => e.id === id) ?? null)

//             return fetch(`/api/admin/${baseApi}/${id}`, 'GET')
//                 .then(newEl => {
//                     newEl && setStore(prev => prev?.map(el => el.id).includes(id) ? prev.map(el => el.id === id ? newEl : el) : [...(prev ?? []), newEl])
//                     return newEl
//                 })
//         },
//         post: async (el) => setStore(store => [...(store ?? []), el]),
//         posts: async (els) => {
//             setStore(els)
//             db.clear(baseApi).then(() => {
//                 els.map((el: T) => db.post(baseApi, el))
//             })
//         },
//         patch: async (id, fragment) => {
//             let newT: T | undefined = store!.find(k => k.id === id)
//             if (newT) {
//                 setStore(prev => prev!.map(el => el.id === id ? { ...el, ...fragment } : el))
//                 db.post(baseApi, { ...newT, ...fragment })
//             }
//         },
//         delete: async (id) => fetch(`/api/admin/${baseApi}/${id}`, 'DELETE')
//             .then(() => setStore(store => store?.filter(el => el.id !== id))),
//     }

//     React.useEffect(() => {
//         db.getAll(baseApi).then((els) => setStore(els))
//         dispatch.getAll()
//     }, [])

//     return { store, dispatch }
// }

// export default useGenericStore