import { TextField, OutlinedInputProps, TextFieldProps, alpha, styled } from '@mui/material'

const InputField = styled((props: TextFieldProps) => (
    <TextField
        {...props}
        variant="filled"
        //@ts-ignore
        InputProps={{ ...props.InputProps, disableUnderline: true } as Partial<OutlinedInputProps>}
    />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default InputField
