import React from 'react'
import Page from '../../../../Components/PageInterface/Page'
import useDocument, { DocumentContext } from './useDocument'
import { useParams } from 'react-router-dom'
import TabGen from './TabGen/TabGen'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { AppContext } from '../../../../App'
import { Download } from '@mui/icons-material'
import { PageInterfaceProps } from '../../../../Components/PageInterface'
import TabSlides from './TabSlides/TabSlides'
import TabStream from './TabStream/TabStream'
import TabRadio from './TabRadio/TabRadio'
import TabFreeSolo from './TabFreeSolo/TabFreeSolo'
import TabQuestions from './TabQuestion/TabQuestions'
import QuizzDrawer from './QuizzDrawer/QuizzDrawer'

const EditCours = () => {
    const { id } = useParams<{ id: string }>()
    const { fetch } = React.useContext(AppContext)
    const context = useDocument(id!)

    const handleDownload = () => fetch(`/api/admin/cours/${id}/download`, 'GET').then(buffer => {
        const el = document.createElement('A')
        el.setAttribute('href', `data:application/octet-stream;base64,${buffer.buffer}`)
        el.setAttribute('download', `${context.cours?.name}.docx`)
        el.click()
    })
    return (
        <DocumentContext.Provider value={context}>
            <Page
                title={context.cours
                    ? <Box>
                        <Typography variant={'h5'}>{context.cours.name}</Typography>
                        {context.cours.subname && <Typography variant={'subtitle2'}>{context.cours.subname}</Typography>}
                    </Box>
                    : 'Edition du cours'}
                subtitle={context.cours ? 'Edition du cours' : undefined}
                tabs={[
                    { label: 'Généralités', component: <TabGen /> },
                    (context?.cours?.file && { label: 'Slides', component: <TabSlides /> }) ?? undefined,
                    { label: 'Stream', component: <TabStream /> },
                    // { label: 'Imagerie', component: <TabRadio /> },
                    { label: 'FreeSolo', component: <TabFreeSolo /> },
                    (context?.cours?.questions && context?.cours?.questions.length > 0 && { label: 'Questions', component: <TabQuestions /> }) ?? undefined

                ].filter(t => t !== undefined) as PageInterfaceProps['tabs']}
                loading={!context.cours}
                fileManagerConfig={{
                    uploadUrl: `/cours/${id}`,
                    files: context.cours?.files ?? [],
                    addFile: (file) => context.dispatch.addFile(file),
                    removeFile: (file) => context.dispatch.deleteFile(file),
                }}
                rightPlace={<Box display={'flex'} alignItems={'center'} height={'100%'}>
                    <Tooltip title="Télécharger le cours au format Word">
                        <IconButton onClick={handleDownload}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                    <QuizzDrawer />
                </Box>}
                fullPage
            />
        </DocumentContext.Provider>
    )
}

export default EditCours