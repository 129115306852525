import React from "react"
import { SortOrderEnum, useTableContextType, useTableDispatchType, useTableType } from "./types"

const useTable: useTableType<any> = (init) => {
    const [tab, setTab] = React.useState<string>('all')
    const [search, setSearch] = React.useState<string>('')
    const [sortField, setSortField] = React.useState<string>(init.columns.find(c => c.initialOrder)?.field ?? '')
    const [sortOrder, setSortOrder] = React.useState<keyof typeof SortOrderEnum>(init.columns.find(c => c.initialOrder)?.initialOrder ?? 'asc')
    const [secondaryFilterSelected, setSecondaryFilter] = React.useState<number>(-1)
    const [collapsed, setCollapsed] = React.useState<string[]>([])

    const dispatch: useTableDispatchType = React.useMemo(() => ({
        setTab, setSearch, setSecondaryFilter,
        sort: (field, order) => {
            setSortField(field)
            setSortOrder(field === sortField ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc')
        },
        toggleCollapse: (id) => setCollapsed(prev => prev.includes(id) ? prev.filter(p => p !== id) : [...prev, id])
    }), [init])

    const filteredDatas = React.useMemo(() =>
        tab && tab !== 'all'
            ? init.customFilters!.find(c => c.name === tab)!.filter(init.datas)
            : init.datas
        , [init, tab, init.datas])

    const secondFilteredDatas = React.useMemo(() =>
        secondaryFilterSelected !== -1
            ? init.secondaryFilters![secondaryFilterSelected]!.filter(filteredDatas)
            : filteredDatas
        , [filteredDatas, secondaryFilterSelected])

    const searchableDatas = React.useMemo(() =>
        search
            ? secondFilteredDatas.filter(d => init.columns.filter(c => c.search !== undefined).map(c => c.search!(d, search.toLocaleLowerCase('fr'))).filter(b => b).length > 0)
            : secondFilteredDatas,
        [secondFilteredDatas, search])

    const orderedDatas = React.useMemo(() => {
        const order = init.columns.find(c => c.field === sortField && c.order)
        if (order) {
            return order.order!(searchableDatas, SortOrderEnum[sortOrder])
        } else {
            return searchableDatas
        }
    }, [searchableDatas, sortField, sortOrder])

    return {
        ...init,
        dispatch,
        tab, secondaryFilterSelected,
        search,
        sort: {
            field: sortField,
            order: sortOrder
        },
        datas: orderedDatas,
        collapsed,
        isSortable: search === '' && sortField === '' && init.sortable !== undefined
    }
}

export const TableContext = React.createContext<useTableContextType<any>>({} as useTableContextType<any>)

export default useTable