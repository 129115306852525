import { Delete, Edit, ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionSummary, Box, Typography, AccordionDetails, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from "@mui/material"
import { competenceType } from "../../../EPDSIList"
import { ProcessusAccordionProps } from "./Processus"
import { EditEcContext } from "../useEditEc"
import React from "react"
import InputField from "../../../../../Components/InputField"
import ItemComment from "./ItemComment"
import RadioEval from "./RadioEval"
import CompetenceItemLine from "./CompetenceItemLine"

interface ComptenceAccordionProps {
    processus: number
    competence: competenceType
}

const ComptenceAccordion = ({ processus, competence, expanded, setExpanded }: ComptenceAccordionProps & ProcessusAccordionProps) => {

    const { ec, dispatch } = React.useContext(EditEcContext)

    return (
        <Accordion expanded={expanded === competence.id} onChange={() => setExpanded(competence.id)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Box>
                    <Box display="flex" flexDirection={'row'} gap={1} alignItems={'center'}>
                        <Typography variant="h5">
                            Processus {processus} : Soins intensifs -
                        </Typography>
                        <Typography variant="h6">
                            Compétence {processus}.{competence.id}
                        </Typography>
                    </Box>
                    <Typography>
                        {competence.title}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2}>Resource</TableCell>
                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>Niveau de maitrise</TableCell>
                            <TableCell rowSpan={2}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Tooltip title="Niveau initial"><Box sx={{ transform: 'rotate(-90deg)', textAlign: 'center', pl: 2 }}>N I</Box></Tooltip></TableCell>
                            <TableCell><Tooltip title="Maîtrise professionnelle partielle"><Box sx={{ transform: 'rotate(-90deg)', textAlign: 'center', pl: 2 }}>N MPP</Box></Tooltip></TableCell>
                            <TableCell><Tooltip title="Maîtrise professionnelle affirmée"><Box sx={{ transform: 'rotate(-90deg)', textAlign: 'center', pl: 2 }}>N MPA</Box></Tooltip></TableCell>
                            <TableCell><Tooltip title="Expertise"><Box sx={{ transform: 'rotate(-90deg)', textAlign: 'center', pl: 2 }}>N E</Box></Tooltip></TableCell>
                            <TableCell><Tooltip title="Non évalué"><Box sx={{ transform: 'rotate(-90deg)', textAlign: 'center', pl: 2 }}>NA</Box></Tooltip></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {competence.items.map(item =>
                            <CompetenceItemLine key={item.id} processus={processus} competence={competence.id} item={item} />)}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}

export default ComptenceAccordion