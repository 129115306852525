const MedicAdminVoieTranslate = (toTransform: string): string => {
    const voies = [
        { id: 'sol inj', name: 'Solution injectable' },
        { id: 'sol buv', name: 'Solution buvable' },
        { id: 'sol perf', name: 'Solution pour perfusion' },
    ]

    const voie = voies.find(v => v.id === toTransform)
    if (voie) return voie.name;
    const ref = voies.find(v => v.name === toTransform)
    if (ref) return ref.id;
    return toTransform;
}

export default MedicAdminVoieTranslate
