import { Delete } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material'
import React from 'react'

interface DeleteButtonProps {
    tooltip?: string | React.ReactNode
    square?: boolean
    label?: string
    size?: 'small' | 'medium' | 'large'
    confirm?: boolean | ConfirmDialogProps['config']
    onDelete: () => void
    variant?: 'text' | 'outlined' | 'contained'
}
const DeleteButton = ({ tooltip, square, label = 'Supprimer', size, confirm, onDelete, variant }: DeleteButtonProps) => {
    const [open, setOpen] = React.useState<boolean>(false)

    const handleConfirm = () => !confirm
        ? onDelete()
        : setOpen(true)

    const renderButton = () => square
        ? <Button color='error' size={size} onClick={handleConfirm} variant={variant}><Delete fontSize={size} />  {label}</Button>
        : <IconButton color='error' size={size} onClick={handleConfirm}><Delete fontSize={size} /></IconButton>
    return (
        <>
            {tooltip
                ? <Tooltip title={tooltip}>{renderButton()}</Tooltip>
                : renderButton()}
            <ConfirmDialog open={open} onDelete={onDelete} handleClose={() => setOpen(false)} config={typeof confirm === 'object' ? confirm : undefined} />
        </>
    )
}

interface ConfirmDialogProps {
    open: boolean
    handleClose: () => void
    onDelete: () => void
    config?: {
        title: string,
        body: string
    }
}
const ConfirmDialog = ({ open, handleClose, onDelete, config }: ConfirmDialogProps) => {
    const handleDelete = () => {
        onDelete()
        handleClose()
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {config?.title ?? "Confirmation requise"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {config?.body ?? 'Êtes-vous certains de vouloir supprimer cet élément ?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>Annuler</Button>
                <Button onClick={handleDelete} color='error'>Supprimer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteButton