import { Avatar, Box, Button, Divider, Drawer, FormControlLabel, IconButton, MenuItem, Slider, Switch, Typography } from '@mui/material'
import React from 'react'
import { ChapitreContext } from '../useEditChapitre'
import { Close, CopyAll, Link, LinkOff, Save } from '@mui/icons-material'
import InputField from '../../../../Components/InputField'
import { ChapitreAvatarImageContent, ChapitreAvatarTextContent, ChapitreFlexContent, ChapitreImageContent, ChapitreTextContent, ContentItem } from '../../../../Stores/ChapitreType'
import NoImage from '../../../../Components/NoImage'
import { PageContext } from '../../../../Components/PageInterface/Page'

const EditDrawer = () => {
    const { editedValue, dispatch } = React.useContext(ChapitreContext)
    return (
        <Drawer open={Boolean(editedValue)} anchor={'right'}>
            {editedValue && <Box sx={{ width: 400, height: '100%', mt: 7, p: 2, overflowY: 'auto' }} display={'flex'} flexDirection={'column'}>
                {{
                    1: <TextElement />,
                    2: <ListElement />,
                    3: <ListElement />,
                    4: <FlexElement />,
                    5: <ImageElement />,
                    6: <AvatarElement />,
                }[editedValue.type]}
            </Box>}
        </Drawer>
    )
}

const Title = ({ title }: { title: string }) => {
    const { copyItem, dispatch } = React.useContext(ChapitreContext)
    const handleClose = () => dispatch.setEditedItem(null)
    const handleCopy = () => dispatch.setCopyItem()
    return (
        <Box display={'flex'} flexDirection={'row'}>
            <Box flexGrow={1}>
                <Typography variant='h5'>{title}</Typography>
            </Box>
            <Box>
                <IconButton onClick={handleCopy}>
                    <CopyAll />
                </IconButton>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
        </Box>
    )
}

const SaveButton = ({ data, disabled }: { data: Partial<ContentItem>, disabled?: boolean }) => {
    const { editedValue, dispatch } = React.useContext(ChapitreContext)
    const handleSave = () => {
        dispatch.updateValueItem(editedValue!.id, data)
        dispatch.setEditedItem(null)
    }
    return (
        <Button sx={{ mt: 2 }} onClick={handleSave} disabled={disabled}>
            <Save />  Enregistrer
        </Button>
    )
}

const TextElement = () => {
    const { editedValue, dispatch } = React.useContext(ChapitreContext)
    const [text, setText] = React.useState((editedValue as ChapitreTextContent)!.text)
    const [variant, setVariant] = React.useState((editedValue as ChapitreTextContent)!.variant)

    React.useEffect(() => {
        setText((editedValue as ChapitreTextContent)!.text)
        setVariant((editedValue as ChapitreTextContent)!.variant)
    }, [editedValue])
    return (
        <>
            <Title title={'Element texte'} />
            <InputField label={'Texte'} value={text} onChange={({ target: { value } }) => setText(value)} multiline fullWidth sx={{ mb: 2 }} autoFocus />

            <InputField label="Style" value={variant ?? 'body1'} onChange={({ target: { value } }) => setVariant(value as ChapitreTextContent['variant'])} select fullWidth>
                <MenuItem value="h1">Titre 1</MenuItem>
                <MenuItem value="h2">Titre 2</MenuItem>
                <MenuItem value="h3">Titre 3</MenuItem>
                <MenuItem value="h4">Titre 4</MenuItem>
                <MenuItem value="h5">Titre 5</MenuItem>
                <MenuItem value="h6">Titre 6</MenuItem>
                <Divider />
                <MenuItem value="subtitle1">Sous-titre 1</MenuItem>
                <MenuItem value="subtitle2">Sous-titre 2</MenuItem>
                <Divider />
                <MenuItem value="body1">Paragraphe 1</MenuItem>
                <MenuItem value="body2">Paragraphe 2</MenuItem>
                <Divider />
                <MenuItem value="caption">Commentaire</MenuItem>
            </InputField>

            <SaveButton data={{ text, variant }} />
        </>
    )
}

const ListElement = () => {
    const { editedValue, dispatch } = React.useContext(ChapitreContext)
    const [type, setType] = React.useState<number>(editedValue!.type)

    React.useEffect(() => {
        setType(editedValue!.type)
    }, [editedValue])
    return (
        <>
            <Title title={'Liste'} />
            <InputField label={'Type de liste'} value={type} onChange={({ target: { value } }) => setType(Number(value))} fullWidth select>
                <MenuItem value={2}>Liste à puces</MenuItem>
                <MenuItem value={3}>Liste numérotée</MenuItem>
            </InputField>
            <SaveButton data={{ type: type! as ContentItem['type'] }} />
        </>
    )
}

const FlexElement = () => {
    const { editedValue, dispatch } = React.useContext(ChapitreContext)
    const [isFlex, setIsFlex] = React.useState<boolean>((editedValue as ChapitreFlexContent)!.isFlex)
    const [flexGrow, setFlexGrow] = React.useState<number>((editedValue as ChapitreFlexContent)!.flexGrow)
    const [gap, setGap] = React.useState<number>((editedValue as ChapitreFlexContent)!.gap)
    const [direction, setDirection] = React.useState<ChapitreFlexContent['direction']>((editedValue as ChapitreFlexContent)!.direction)
    const [alignItems, setAlignItems] = React.useState<ChapitreFlexContent['alignItems']>((editedValue as ChapitreFlexContent)!.alignItems)
    const [justifyContent, setJustifyContent] = React.useState<ChapitreFlexContent['justifyContent']>((editedValue as ChapitreFlexContent)!.justifyContent)

    React.useEffect(() => {
        setIsFlex((editedValue as ChapitreFlexContent)!.isFlex)
        setFlexGrow((editedValue as ChapitreFlexContent)!.flexGrow)
        setGap((editedValue as ChapitreFlexContent)!.gap)
        setDirection((editedValue as ChapitreFlexContent)!.direction)
        setAlignItems((editedValue as ChapitreFlexContent)!.alignItems)
        setJustifyContent((editedValue as ChapitreFlexContent)!.justifyContent)
    }, [editedValue])
    return (
        <>
            <Title title={'Flex'} />
            <Box>
                <FormControlLabel labelPlacement='start' label={'Flex ?'} control={<Switch checked={isFlex} onChange={(_, checked) => setIsFlex(checked)} />} />
            </Box>
            <FormControlLabel labelPlacement='start' label={'Grow'} control={<Slider sx={{ ml: 2, mr: 2 }} value={flexGrow} onChange={(_, value) => setFlexGrow(value as number)} min={0} max={10} />} />
            <FormControlLabel labelPlacement='start' label={'Gap'} control={<Slider sx={{ ml: 2, mr: 2 }} value={gap} onChange={(_, value) => setGap(value as number)} min={0} max={10} />} />

            <InputField label={'Direction'} value={direction} onChange={({ target: { value } }) => setDirection(value as ChapitreFlexContent['direction'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'row'}>Ligne</MenuItem>
                <MenuItem value={'row-reverse'}>Ligne inversée</MenuItem>
                <MenuItem value={'column'}>Colonne</MenuItem>
                <MenuItem value={'column-reverse'}>Colonne inversée</MenuItem>
            </InputField>
            <InputField label={'Alignement vertical'} value={alignItems} onChange={({ target: { value } }) => setAlignItems(value as ChapitreFlexContent['alignItems'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'flex-start'}>A gauche</MenuItem>
                <MenuItem value={'flex-end'}>A droite</MenuItem>
                <MenuItem value={'center'}>Centré</MenuItem>
            </InputField>
            <InputField label={'Alignement horizontal'} value={justifyContent} onChange={({ target: { value } }) => setJustifyContent(value as ChapitreFlexContent['justifyContent'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'flex-start'}>A gauche</MenuItem>
                <MenuItem value={'flex-end'}>A droite</MenuItem>
                <MenuItem value={'center'}>Centré</MenuItem>
            </InputField>
            <SaveButton data={{ isFlex, flexGrow, direction, gap, alignItems, justifyContent }} />
        </>
    )
}

const ImageElement = () => {
    const { openFileSelector } = React.useContext(PageContext)
    const { chapitre, editedValue, dispatch } = React.useContext(ChapitreContext)
    const [src, setSrc] = React.useState<string | null>((editedValue as ChapitreImageContent)!.src)
    const [width, setWidth] = React.useState<number | undefined>((editedValue as ChapitreImageContent)!.width)
    const [height, setHeight] = React.useState<number | undefined>((editedValue as ChapitreImageContent)!.height)
    const [objectFit, setObjectFit] = React.useState<ChapitreImageContent['objectFit']>((editedValue as ChapitreImageContent)!.objectFit)

    const [linkedWH, setLinkedWH] = React.useState<boolean>(true)
    const imageRef = React.createRef<HTMLImageElement>()

    const handleChangeWitdh = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (linkedWH) {
            const coef = width! / height!
            setWidth(Number(value))
            setHeight(Number(value) / coef)
        } else {
            setWidth(Number(value))
        }
    }

    const handleChangeHeight = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (linkedWH) {
            const coef = width! / height!
            setWidth(Number(value) * coef)
            setHeight(Number(value))
        } else {
            setHeight(Number(value))
        }
    }

    const selectImage = () => openFileSelector({
        title: 'Sélectionner une image',
        files: chapitre!.files,
        onSelect: ({ id }) => {
            setWidth(undefined)
            setHeight(undefined)
            setSrc(id)
        },
        close: () => openFileSelector(null)
    })

    React.useEffect(() => {
        setSrc((editedValue as ChapitreImageContent)!.src)
        setWidth((editedValue as ChapitreImageContent)!.width)
        setHeight((editedValue as ChapitreImageContent)!.height)
        setObjectFit((editedValue as ChapitreImageContent)!.objectFit)
        setLinkedWH(true)
    }, [editedValue])

    React.useEffect(() => {
        if (imageRef.current && width === undefined && height === undefined) {
            setWidth(imageRef.current.naturalWidth)
            setHeight(imageRef.current.naturalHeight)
            setLinkedWH(true)
        }
    }, [imageRef])
    return (
        <>
            <Title title={'Image'} />
            <Box sx={{ aspectRatio: '16/9', backgroundColor: theme => theme.palette.grey[400] }} display={'flex'} alignItems={'center'} justifyContent={'center'} mb={2} onClick={selectImage}>
                {src
                    ? <img
                        ref={imageRef}
                        src={`/download/${src}`}
                        style={{
                            width, height, objectFit
                        }} />
                    : <NoImage sx={{ height: 150 }} />}
            </Box>
            <InputField label={'Ajustement'} value={objectFit ?? ''} disabled={!src} onChange={({ target: { value } }) => setObjectFit(value as ChapitreImageContent['objectFit'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'cover'}>Couverture</MenuItem>
                <MenuItem value={'contain'}>Contenue</MenuItem>
            </InputField>
            <Box display={'flex'} gap={2} alignItems={'center'}>
                <Box>
                    <InputField type="number" label={'width'} disabled={!src} value={width ?? ''} onChange={handleChangeWitdh} />
                </Box>
                <Box>
                    <IconButton onClick={() => setLinkedWH(!linkedWH)}>
                        {linkedWH ? <Link /> : <LinkOff />}
                    </IconButton>
                </Box>
                <Box>
                    <InputField type="number" label={'height'} disabled={!src} value={height ?? ''} onChange={handleChangeHeight} />
                </Box>
            </Box>

            {/*             

            <InputField label={'Direction'} value={direction} onChange={({ target: { value } }) => setDirection(value as ChapitreImageContent['direction'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'row'}>Ligne</MenuItem>
                <MenuItem value={'row-reverse'}>Ligne inversée</MenuItem>
                <MenuItem value={'column'}>Colonne</MenuItem>
                <MenuItem value={'column-reverse'}>Colonne inversée</MenuItem>
            </InputField>
            <InputField label={'Alignement vertical'} value={alignItems} onChange={({ target: { value } }) => setAlignItems(value as ChapitreImageContent['alignItems'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'flex-start'}>A gauche</MenuItem>
                <MenuItem value={'flex-end'}>A droite</MenuItem>
                <MenuItem value={'center'}>Centré</MenuItem>
            </InputField>
            <InputField label={'Alignement horizontal'} value={justifyContent} onChange={({ target: { value } }) => setJustifyContent(value as ChapitreImageContent['justifyContent'])} fullWidth select sx={{ mb: 1 }}>
                <MenuItem value={'flex-start'}>A gauche</MenuItem>
                <MenuItem value={'flex-end'}>A droite</MenuItem>
                <MenuItem value={'center'}>Centré</MenuItem>
            </InputField> */}
            <SaveButton data={{ src, width, height, objectFit }} />
        </>
    )
}

const AvatarElement = () => {
    const { openFileSelector } = React.useContext(PageContext)
    const { chapitre, editedValue, dispatch } = React.useContext(ChapitreContext)
    const [type, setType] = React.useState<'text' | 'src'>()
    const [value, setValue] = React.useState<string>()

    const selectImage = () => openFileSelector({
        title: 'Sélectionner une image',
        files: chapitre!.files,
        onSelect: ({ id }) => {
            setValue(id)
        },
        close: () => openFileSelector(null)
    })

    React.useEffect(() => {
        if ('src' in editedValue!) setType('src')
        if ('text' in editedValue!) setType('text')
    }, [editedValue])
    React.useEffect(() => {
        if (type) {
            if (type in editedValue!) setValue((editedValue! as (ChapitreAvatarImageContent))[type as 'src'])
        } else {
            setValue(undefined)
        }
    }, [type])

    return (
        <>
            <Title title={'Avatar'} />
            <Box>
                <InputField label={'Type d\'avatar'} value={type ?? ''} onChange={({ target: { value } }) => setType(value as ('src' | 'text'))} select fullWidth placeholder={'Choisir un type d\'avatar'}>
                    <MenuItem value='src'>Image</MenuItem>
                    <MenuItem value='text'>Texte</MenuItem>
                </InputField>
            </Box>
            {type &&
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mb={2} onClick={selectImage}>
                    <Avatar
                        src={type === 'src' ? `/download/${value}` : undefined}>
                        {type === 'text' && (value ?? '')}
                    </Avatar>
                </Box>}
            {type === 'src' && <Typography variant="caption" color="GrayText">Cliquer pour séléctionner une image</Typography>}
            {type === 'text' && <InputField label={'Texte'} value={value ?? ''} onChange={({ target: { value } }) => setValue(value)} />}

            <SaveButton data={{ [type as ('src' | 'text')]: value, [type === 'src' ? 'text' : 'src']: undefined }} disabled={!type || !value} />
        </>
    )
}
export default EditDrawer