import { Box } from '@mui/material'
import React from 'react'
import { EditEcContext } from '../useEditEc'
import IPAS from '../../../../../Components/IPAS/IPAS'

const ECAnamnese = () => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 2, gap: 2, display: 'flex', flexDirection: 'column' }}>
            {ec?.description && <IPAS ipasValue={ec.description} changeValue={dispatch.changeDescription} />}
        </Box>
    )
}

export default ECAnamnese