import React from "react";
import ExploitationPratiqueType, { PrimeAnalyseType, PlannifType } from "../../../../Stores/ExploitationPratiqueType";
import { AppContext } from "../../../../App";
import IPASValueType from "../../../../Stores/IPASTypes";
import FileEntity from "../../../../Stores/FileType";
import EPDSIProblemsGroupType from "../../../../Stores/EPDSIProblemsGroupType";
import { useNavigate } from "react-router-dom";
import EpdsiEcgType from "../../../../Stores/EpdsiEcgType";
import { MedicamentPresentationType } from "../../../../Stores/MedicamentType";
import { arrayMove } from "@dnd-kit/sortable";
import { Task } from "gantt-task-react";

type useEpEditType = (id: ExploitationPratiqueType['id']) => useEpEditContextType
type useEpEditContextType = {
    ep: ExploitationPratiqueType | undefined
    problems: EPDSIProblemsGroupType[] | undefined
    activeEcg: EpdsiEcgType | null
    anaEdit: null | 'add' | PrimeAnalyseType
    editedTask: Task | undefined
    dispatch: useEpEditDispatchType
}
type useEpEditDispatchType = {
    setAnaEdit: (ana: null | 'add' | PrimeAnalyseType) => void
    deleteEp: () => void
    changeDate: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeDateRecueil: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeIpp: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeName: (name: string) => void
    changeIpas: (value: IPASValueType) => void
    setRespiFiles: (files: FileEntity[]) => void
    setScopeFiles: (files: FileEntity[]) => void
    setRadioFiles: (files: FileEntity[]) => void
    setGdsFiles: (files: FileEntity[]) => void
    addPrimeAna: (analyse: PrimeAnalyseType) => void,
    changePrimeAna: (id: PrimeAnalyseType['id'], partial: Partial<PrimeAnalyseType>) => void
    sortPrimeAna: (oldIndex: number, newIndex: number) => void
    setPrimeAnaPriority: (id: PrimeAnalyseType['id']) => void
    setPrimeAnaPriorityBulk: (primeAnas: PrimeAnalyseType[]) => void
    removePrimeAna: (id: PrimeAnalyseType['id']) => void
    deleteFile: (fileId: string) => void
    addPlannif: (field: PlannifFieldsType) => void,
    changePlannif: (field: PlannifFieldsType, priorityId: number, partial: Partial<PlannifType>) => void
    removePlannif: (field: PlannifFieldsType, priorityId: number) => void
    sortPlannif: (field: PlannifFieldsType, oldIndex: number, newIndex: number) => void
    addEcg: (file: File) => Promise<void>
    setActiveEcg: (ecgId: string) => void
    deleteEcg: () => Promise<void>
    updateEcg: (analyse: EpdsiEcgType['analyse']) => Promise<void>
    toggleConfig: (config: string) => void
    addPresentation: (presentationId: MedicamentPresentationType['id']) => Promise<void>
    addPlannifTask: () => void
    selectEditTask: (task: Task | undefined) => void
    editTask: (task: Task) => void
}
export type PlannifFieldsType = ('priority' | 'soins' | 'visite' | 'social') & keyof ExploitationPratiqueType

export const UseEpEditContext = React.createContext<useEpEditContextType>({} as useEpEditContextType)

const useEpEdit: useEpEditType = (id) => {
    const { fetch, dispatch: dispatchApp } = React.useContext(AppContext)
    const navigate = useNavigate()
    const [ep, setEp] = React.useState<ExploitationPratiqueType>()
    const [problems, setProblems] = React.useState<EPDSIProblemsGroupType[]>()
    const [activeEcg, setActiveEcg] = React.useState<string>('')
    const [anaEdit, setAnaEdit] = React.useState<null | 'add' | PrimeAnalyseType>(null)
    const [editedTask, setEditedTask] = React.useState<Task | undefined>(undefined)


    const dispatch: useEpEditDispatchType = React.useMemo(() => ({
        setAnaEdit,
        deleteEp: () => dispatchApp.exploitationsPratiques.delete(ep!.id).then(() => {
            navigate('/epdsi/eps')
        }),
        changeDate: (event) => fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { date: event.target.value + ' 12:00:00' })
            .then((ep) => {
                setEp(ep)
                dispatchApp.exploitationsPratiques.post(ep)
            }),
        changeDateRecueil: (event) => fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { dateRecueil: event.target.value + ' 12:00:00' })
            .then((ep) => {
                setEp(ep)
                dispatchApp.exploitationsPratiques.post(ep)
            }),
        changeIpp: (event) => fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { ipp: event.target.value })
            .then((ep) => {
                setEp(prev => ({ ...prev!, ipp: ep.ipp }))
                dispatchApp.exploitationsPratiques.post(ep)
            }),
        changeName: (name) => fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { name })
            .then((ep) => {
                setEp(ep)
                dispatchApp.exploitationsPratiques.post(ep)
            }),
        changeIpas: (ipas) => fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { ipas })
            .then((ep) => {
                setEp(ep)
                dispatchApp.exploitationsPratiques.post(ep)
            }),
        setRespiFiles: respiFiles => {
            setEp({ ...ep!, respiFiles: [...ep!.respiFiles, ...respiFiles] })
        },
        setScopeFiles: scopeFiles => {
            setEp({ ...ep!, scopeFiles: [...ep!.scopeFiles, ...scopeFiles] })
        },
        setRadioFiles: radioFiles => {
            setEp({ ...ep!, radioFiles: [...ep!.radioFiles, ...radioFiles] })
        },
        setGdsFiles: gdsFiles => {
            setEp({ ...ep!, gdsFiles: [...ep!.gdsFiles, ...gdsFiles] })
        },
        // addPrimeAna: () => {
        //     const newId = ep?.primeAna.map(p => p.id).sort((a, b) => a < b ? 1 : -1)[0] ?? -1
        //     const newAna: ExploitationPratiqueType['primeAna'] = [...ep?.primeAna ?? [], { id: newId + 1, position: Math.max(...ep?.primeAna.map(p => p.position ?? 0) ?? [-1]) + 1, system: null, data: '', explication: '', problem: '' }]
        //     setEp({ ...ep!, primeAna: newAna })
        //     fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna: newAna })
        //         .then((ep) => {
        //             setEp(ep)
        //             dispatchApp.exploitationsPratiques.post(ep)
        //         })
        // },
        addPrimeAna: (analyse) => {
            setEp({ ...ep!, primeAna: [...ep?.primeAna ?? [], analyse] })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna: [...ep?.primeAna ?? [], analyse] })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        changePrimeAna: (idAna, partial) => {
            const newAna: ExploitationPratiqueType['primeAna'] = ep!.primeAna.map(p => p.id === idAna ? ({ ...p, ...partial }) : p)
            setEp({ ...ep!, primeAna: newAna })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna: newAna })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        sortPrimeAna: (oldIndex, newIndex) => {
            const primeAna = arrayMove([...ep!.primeAna], oldIndex, newIndex).map((p, i) => ({ ...p, position: i }))
            setEp({ ...ep!, primeAna })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        setPrimeAnaPriority: (id) => {
            const newPriority = ep?.primeAna.map(p => p.prioritaire).filter(p => typeof p === 'number').sort((a, b) => a! < b! ? 1 : -1)[0] ?? -1
            dispatch.changePrimeAna(id, { prioritaire: newPriority + 1 })
        },
        setPrimeAnaPriorityBulk: (primeAnas) => {
            const newAna: PrimeAnalyseType[] = ep!.primeAna.map(p => ({
                ...p,
                prioritaire: primeAnas.find(np => np.id === p.id)?.prioritaire ?? p.prioritaire ?? undefined
            }))
            setEp({ ...ep!, primeAna: newAna })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna: newAna })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        removePrimeAna: idAna => {
            const newAna: ExploitationPratiqueType['primeAna'] = ep!.primeAna.filter(p => p.id !== idAna)
            setEp({ ...ep!, primeAna: newAna })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { primeAna: newAna })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        deleteFile: (fileId) => {
            fetch(`/api/admin/exploitations-pratiques/${id}/files/${fileId}`, 'DELETE')
            setEp({
                ...ep!,
                files: ep!.files.filter(f => f.id !== fileId),
                gdsFiles: ep!.gdsFiles.filter(f => f.id !== fileId),
                radioFiles: ep!.radioFiles.filter(f => f.id !== fileId),
                respiFiles: ep!.respiFiles.filter(f => f.id !== fileId),
                scopeFiles: ep!.scopeFiles.filter(f => f.id !== fileId),
            })
        },

        addPlannif: (field) => {
            if (!ep) return
            const newId = ep[field].map(p => p.id).sort((a, b) => a < b ? 1 : -1)[0] ?? -1
            const newEls: PlannifType[] = [...ep[field] ?? [], { id: newId + 1, position: Math.max(-1, ...(ep[field].map(f => f.position ?? 0))) + 1, priority: '', arg: '' }]
            setEp({ ...ep!, [field]: newEls })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { [field]: newEls })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        changePlannif: (field, elId, partial) => {
            if (!ep) return
            const newEls: PlannifType[] = ep[field].map(p => p.id === elId ? ({ ...p, ...partial }) : p)
            setEp({ ...ep!, [field]: newEls })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { [field]: newEls })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        sortPlannif: (field, oldIndex, newIndex) => {
            if (!ep || !ep[field]) return
            const plannif = arrayMove([...ep[field]], oldIndex, newIndex).map((p, i) => ({ ...p, position: i }))
            setEp({ ...ep!, [field]: plannif })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { [field]: plannif })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        removePlannif: (field, elId) => {
            if (!ep) return
            const newEls: PlannifType[] = ep[field].filter(p => p.id !== elId)
            setEp({ ...ep!, priority: newEls })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { [field]: newEls })
                .then((ep) => {
                    setEp(ep)
                    dispatchApp.exploitationsPratiques.post(ep)
                })
        },
        addEcg: (file) => {
            const form = new FormData()
            form.append('file', file)
            return fetch(`/api/admin/exploitations-pratiques/${id}/ecgs`, 'POST', form).then(ecg => setEp(p => ({ ...p!, ecgs: [...p!.ecgs, ecg] })))
        },
        setActiveEcg: (ecgId: string) => setActiveEcg(ecgId),
        deleteEcg: () => fetch(`/api/admin/exploitations-pratiques/${id}/ecgs/${activeEcg}`, 'DELETE').then(({ success }) => success && setEp(p => ({ ...p!, ecgs: p!.ecgs.filter(ecg => ecg.id !== activeEcg) }))),
        updateEcg: (analyse) => {
            setEp(p => ({
                ...p!,
                ecgs: p!.ecgs.map(ecg => ecg.id === activeEcg ? ({ ...ecg, analyse }) : ecg)
            }))
            return fetch(`/api/admin/exploitations-pratiques/${id}/ecgs/${activeEcg}`, 'PATCH', { analyse }).then(ecgNew => setEp(p => ({
                ...p!,
                ecgs: p!.ecgs.map(ecg => ecg.id === activeEcg ? ecgNew : ecg)
            })))
        },
        toggleConfig: (config) => {
            const newConfig = ep?.config.includes(config) ? ep?.config.filter(c => c !== config) : [...(ep?.config ?? []), config]
            setEp({ ...ep!, config: newConfig })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { config: newConfig }).then(el => setEp(p => ({ ...p!, config: el.config })))
        },
        addPresentation: (presentationId) => fetch(`/api/admin/exploitations-pratiques/${id}/presentations`, 'POST', { presentationId }).then(medoc => setEp(prev => ({
            ...prev!,
            medicaments: [...prev!.medicaments, medoc]
        }))),
        addPlannifTask: () => {
            const start = ep?.plannif && ep?.plannif.length > 0
                ? new Date(ep?.plannif[ep?.plannif.length - 1].end)
                : new Date(2020, 1, 1, 7, 0, 0)
            const end = new Date(2020, 1, 1, start.getHours(), start.getMinutes() + 30, 0)
            const newPlannif: Task[] = [
                ...ep?.plannif ?? [],
                {
                    start,
                    end,
                    name: 'Nouvelle tache',
                    id: `Task ${ep?.plannif?.length ?? 0}`,
                    type: 'task',
                    progress: 0,
                    styles: { progressSelectedColor: '#607d8b' },
                }
            ]
            setEp({ ...ep!, plannif: newPlannif })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { plannif: newPlannif }).then(el => setEp(p => ({ ...p!, config: el.config })))

        },
        selectEditTask: (task) => setEditedTask(task),
        editTask: (task) => {
            const newPlannif: Task[] = ep?.plannif.map(t => t.id === task.id ? task : t) ?? []
            setEp({ ...ep!, plannif: newPlannif })
            fetch(`/api/admin/exploitations-pratiques/${id}`, 'PATCH', { plannif: newPlannif }).then(el => setEp(p => ({ ...p!, config: el.config })))
            setEditedTask(undefined)
        }

    }), [ep, activeEcg, id, fetch, dispatchApp])

    React.useEffect(() => {
        dispatchApp.exploitationsPratiques.get(id!, setEp).then(setEp)
        dispatchApp.exploitationsPratiques.get(id!, el => el && setEp(el)).then(setEp)
        dispatchApp.medicaments.getAll()
        fetch(`/api/admin/exploitations-pratiques/problems`, 'GET').then(setProblems)
    }, [id])
    return {
        ep, problems, anaEdit, editedTask,
        activeEcg: ep?.ecgs.find(ecg => ecg.id === activeEcg) ?? null,
        dispatch
    }
}

export default useEpEdit