import { Box, IconButton, Input } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { DocumentContext } from '../useDocument'
import { Delete, DragHandle } from '@mui/icons-material'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const TabFreeSolo = () => {
    const { cours, dispatch } = React.useContext(DocumentContext)
    const [value, setValue] = React.useState<string[]>((cours?.freeSolo ?? '').split('\n'))

    const onChange = (index: number, v: string) => {
        const newValue = [...value]
        newValue[index] = v
        setValue(newValue)
    }

    const onBlur = () => {
        dispatch.updateField('freeSolo', value.join('\n'))
    }

    const onDragEnd = (event: DragEndEvent) => {
        const activeId = event.active.id
        const overId = event.over?.id
        const newValue = arrayMove(value, activeId as number, overId as number)
        setValue(newValue)
        dispatch.updateField('freeSolo', newValue.join('\n'))
    }

    const onDelete = (index: number) => {
        const newValue = [...value]
        newValue.splice(index, 1)
        setValue(newValue)
        dispatch.updateField('freeSolo', newValue.join('\n'))
    }

    React.useEffect(() => {
        setValue((cours?.freeSolo ?? '').split('\n'))
    }, [cours?.freeSolo])

    return (
        <Box
            height={'100%'}
            sx={{
                overflowY: 'auto',
            }}
            p={1}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
        >
            <DndContext
                onDragEnd={onDragEnd}>
                <SortableContext items={value.map((_, i) => i.toString())}>
                    {value.map((obj, i) => <SortableEl
                        key={i}
                        obj={obj}
                        index={i}
                        onChange={onChange}
                        onBlur={onBlur}
                        onDelete={onDelete}
                        canDelete={
                            value.length === 1 && i === 0 && obj === ''
                        }
                    />)}
                </SortableContext>
            </DndContext>
        </Box>
    )
}
interface SortableElProps {
    obj: string
    index: number
    onChange: (index: number, value: string) => void
    onBlur: () => void
    onDelete: (index: number) => void
    canDelete: boolean
}
const SortableEl = ({
    obj: v,
    index: i,
    onChange,
    onBlur,
    onDelete,
    canDelete
}: SortableElProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: i.toString() });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };
    return (
        <Box
            key={i}
            display={'flex'}
            alignItems={'center'}
            gap={1}
            sx={{
                '& .hoverable': {
                    visibility: 'hidden',
                },
                '&:hover .hoverable': {
                    visibility: 'visible',
                }
            }}
            style={style}
            ref={setNodeRef}
            {...attributes}
        >
            <Box className={'hoverable'} ml={1}>
                <DragHandle {...listeners} />
            </Box>
            <InputField
                label='Ligne'
                value={v}
                onChange={({ target: { value } }) => onChange(i, value)}
                onBlur={onBlur}
                fullWidth
                multiline
            />

            <Box className={'hoverable'} mr={1}>
                <IconButton size='small' color='error' onClick={() => onDelete(i)} disabled={canDelete}>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TabFreeSolo