import { Box, Select, MenuItem, ListItemAvatar, Avatar, ListItemText, ListItemButton, ListItemSecondaryAction, Typography, IconButton, Menu, List, ListItem, Tooltip, Divider } from "@mui/material"
import ThematicType from "../Stores/ThematicType"
import { AppContext } from "../App"
import React, { PropsWithChildren } from "react"
import InputField from "./InputField"
import IntervenantType from "../Stores/IntervenantType"
import { Add, Delete, Favorite, Image, Person } from "@mui/icons-material"
import { DocumentContext } from "../Pages/EPDSI/Cours/Edit/useDocument"


interface EPDSIIntervenantSelectorProps {
    value: IntervenantType[],
    onSelect: (el: IntervenantType) => void
    onDelete: (el: IntervenantType) => void
}

const EPDSIIntervenantSelector = ({ value, onSelect, onDelete }: EPDSIIntervenantSelectorProps) => {
    const { intervenants } = React.useContext(AppContext)
    const { dispatch } = React.useContext(DocumentContext)
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)

    if (!intervenants) return <></>

    const favorites = intervenants.filter(i => i.isFavorite)
    console.log(favorites)

    const list = intervenants.filter(i => !(value ?? []).map(v => v.id).includes(i.id))

    const handleCreate = async (_: any, firstname?: string | null, lastname?: string | null, email?: string | null) => {
        firstname = await prompt('Prénom du nouvel intervenant :', firstname ?? '')
        lastname = await prompt('Nom de famille du nouvel intervenant :', lastname ?? '')
        email = await prompt('Adresse email du nouvel intervenant :', email ?? '')
        if (firstname && lastname && email) {
            dispatch.createIntervenant(firstname, lastname, email)
        }
    }

    return (
        <Box
            sx={{
                border: theme => `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme => theme.spacing(1.25),
                p: 1,
                background: theme => theme.palette.grey[50],
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-root': {
                    color: theme => theme.palette.grey[700] + ' !important'
                },
                '&:hover': {
                    background: 'unset'
                },
            }}
        >
            <Typography role="label" variant={value.length > 0 ? 'caption' : undefined}>{value.length === 0 ? 'Choisir un intervenant' : 'Intervenant(s)'}</Typography>
            {value.length === 0 && <Typography variant="caption">Aucun intervenant</Typography>}
            {value.length > 0 && <List sx={{
                '& li button': { visibility: 'hidden !important' },
                '& li:hover button': { visibility: 'visible !important' },
            }}>
                {value?.map(el => <ListElement key={el.id} el={el} onDelete={onDelete} />)}
            </List>}
            <Box display={'flex'} justifyContent={'space-between'}>
                {list.length > 0 &&
                    <Tooltip title="Choisir un intervenant">
                        <IconButton onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                            <Person />
                        </IconButton>
                    </Tooltip>}
                <Tooltip title={'Créer un nouvel intervenant'}>
                    <IconButton onClick={handleCreate}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
            >
                {favorites.length > 0 && favorites
                    .sort((a, b) => `${a.lastname} ${a.firstname}`.toLocaleLowerCase() > `${b.lastname} ${b.firstname}`.toLocaleLowerCase() ? 1 : -1)
                    .map(el => <MenuElement key={el.id} el={el} onSelect={onSelect} />)}
                {favorites.length > 0 && <Divider />}
                {list
                    .sort((a, b) => `${a.lastname} ${a.firstname}`.toLocaleLowerCase() > `${b.lastname} ${b.firstname}`.toLocaleLowerCase() ? 1 : -1)
                    .map(el => <MenuElement key={el.id} el={el} onSelect={onSelect} />)}
            </Menu>
        </Box>
    )
}
interface ElementProps {
    el: IntervenantType
    onSelect?: (el: IntervenantType) => void
    onDelete?: (el: IntervenantType) => void
}
const MenuElement = ({ el, onSelect }: ElementProps) =>
    <MenuItem onClick={onSelect ? () => onSelect(el) : undefined} value={el.id}>
        <ListItemAvatar>
            <Avatar src={el.imageId ? `/download/${el.imageId}` : undefined} />
        </ListItemAvatar>
        <ListItemText primary={`${el.firstname} ${el.lastname}`} secondary={el.email} />
    </MenuItem>
const ListElement = ({ el, onDelete }: ElementProps) => {
    const { fetch } = React.useContext(AppContext)
    const { dispatch } = React.useContext(DocumentContext)
    const ref = React.useRef<HTMLInputElement>(null)

    const handleUpload = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {

        if (!files) return
        const form = new FormData()
        form.append('file', files[0])
        return fetch(`/api/admin/files/images/EPDSIIntervenant/${el.id}`, 'POST', form)
            .then(f => dispatch.setIntervenantImage(el.id, f))

        ref.current!.value = ''
    }
    return (
        <ListItem value={el.id}>
            <ListItemAvatar>
                <Avatar src={el.imageId ? `/download/${el.imageId}` : undefined} />
            </ListItemAvatar>
            <ListItemText primary={`${el.firstname} ${el.lastname}`} secondary={el.email} />
            <ListItemSecondaryAction >
                <IconButton color={el.isFavorite ? 'error' : undefined} onClick={() => dispatch.setFavorite(el.id, !el.isFavorite)}>
                    <Favorite />
                </IconButton>
                <IconButton onClick={() => ref.current?.click()}>
                    <Image />
                </IconButton>
                <IconButton color={'error'} onClick={() => onDelete && onDelete(el)}>
                    <Delete color={'error'} />
                </IconButton>
                <input ref={ref} hidden type="file" accept="image/*" onChange={handleUpload} />
            </ListItemSecondaryAction>
        </ListItem>
    )
}


export default EPDSIIntervenantSelector