import React from 'react'
import { UseEpEditContext } from '../useEpsEdit'
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, FormLabel, Radio, RadioGroup, Switch } from '@mui/material'
import EpdsiEcgType from '../../../../../Stores/EpdsiEcgType'
import InputField from '../../../../../Components/InputField'

const Analyse = () => {
    const { activeEcg, dispatch } = React.useContext(UseEpEditContext)
    const [activeAccordion, setActiveAccordion] = React.useState<number>(0)

    const handleChangeAccordion = (index: number) => () => setActiveAccordion(index)

    const handleChangeSwitch = ({ target: { role, name, checked } }: React.ChangeEvent<HTMLInputElement>) => dispatch.updateEcg({
        ...activeEcg!.analyse,
        [role! as keyof EpdsiEcgType['analyse']]: {
            ...activeEcg!.analyse[role! as keyof EpdsiEcgType['analyse'] & 'trace'],
            [name]: checked
        }
    })
    // const handleChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => console.log(e)

    return (
        <Box minWidth={500}>
            <Accordion onChange={handleChangeAccordion(1)} expanded={activeAccordion === 1}>
                <AccordionSummary>Qualité du tracé</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        label="25mm/sec"
                        control={<Switch onChange={handleChangeSwitch} inputProps={{ role: "trace" }} name="time" checked={activeEcg?.analyse.trace?.time ?? false} />}
                    />
                    <FormControlLabel
                        label="10mm/1mV"
                        control={<Switch onChange={handleChangeSwitch} inputProps={{ role: "trace" }} name="volt" checked={activeEcg?.analyse.trace?.volt ?? false} />}
                    />
                    <FormControlLabel
                        label="Présence de parasites"
                        control={<Switch onChange={handleChangeSwitch} inputProps={{ role: "trace" }} name="parasit" checked={activeEcg?.analyse.trace?.parasit ?? false} />}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(2)} expanded={activeAccordion === 2}>
                <AccordionSummary>Fréquence, régularité et rythme</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputField
                        label="Fréquence"
                        type="number"
                        value={activeEcg?.analyse.freq?.f}
                        onChange={({ target: { value } }) => dispatch.updateEcg({ ...activeEcg!.analyse, freq: { ...activeEcg!.analyse.freq, f: +value } })} />
                    <ElRadio
                        title={'Régularité'}
                        value={activeEcg?.analyse.freq?.regularity}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, freq: { ...activeEcg!.analyse.freq, regularity: value } })}
                        options={['Régulier', 'Irrégulier']}
                    />
                    <ElRadio
                        title={'Rythme'}
                        value={activeEcg?.analyse.freq?.rythm}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, freq: { ...activeEcg!.analyse.freq, rythm: value } })}
                        options={["Supraventriculaire", "Ventriculaire", "Supraventriculaire et ventriculaire", "Sinusal"]}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(3)} expanded={activeAccordion === 3}>
                <AccordionSummary>Ondes P</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ElRadio
                        title={'Fréquence auriculaire'}
                        value={activeEcg?.analyse.p?.f}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, p: { ...activeEcg!.analyse.p, f: value } })}
                        options={['P égal QRS', 'P > QRS', 'P < QRS', 'absence d\'onde P']}
                    />
                    <FormControlLabel
                        label="Négative en aVR"
                        control={<Switch onChange={handleChangeSwitch} inputProps={{ role: "p" }} name="avr" checked={activeEcg?.analyse.p?.avr ?? false} />}
                    />
                    <FormControlLabel
                        label="Positive en DI"
                        control={<Switch onChange={handleChangeSwitch} inputProps={{ role: "p" }} name="d1" checked={activeEcg?.analyse.p?.d1 ?? false} />}
                    />

                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(4)} expanded={activeAccordion === 4}>
                <AccordionSummary>Intervalle PQ</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ElRadio
                        title={'Durée'}
                        value={activeEcg?.analyse.pq}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, pq: value })}
                        options={['< 200ms', '> 200ms', 'impossible à déterminer']}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(5)} expanded={activeAccordion === 5}>
                <AccordionSummary>Complexe QRS</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputField
                        label="Fréquence ventriculaire"
                        type="number"
                        value={activeEcg?.analyse.qrs?.f}
                        onChange={({ target: { value } }) => dispatch.updateEcg({ ...activeEcg!.analyse, qrs: { ...activeEcg!.analyse.qrs, f: +value } })} />
                    <ElRadio
                        title={'Durée'}
                        value={activeEcg?.analyse.qrs?.delay}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, qrs: { ...activeEcg!.analyse.qrs, delay: value } })}
                        options={['<120ms', '>120ms']}
                    />
                    <ElRadio
                        title={'Morphologie'}
                        value={activeEcg?.analyse.qrs?.m}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, qrs: { ...activeEcg!.analyse.qrs, m: value } })}
                        options={["Supraventriculaire", "Ventriculaire", "Supraventriculaire et ventriculaire"]}
                    />
                    <ElRadio
                        title={'Onde Q>40ms ou >25% amplitude de l’onde R'}
                        value={activeEcg?.analyse.qrs?.q}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, qrs: { ...activeEcg!.analyse.qrs, q: value } })}
                        options={['Oui', 'Non']}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(6)} expanded={activeAccordion === 6}>
                <AccordionSummary>Segment ST</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ElRadio
                        value={activeEcg?.analyse.st}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, st: value })}
                        options={['Isoélectrique', 'Susdécalage > 1mm', 'Sousdécalage > 0.5 mm']}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(7)} expanded={activeAccordion === 7}>
                <AccordionSummary>Onde T</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ElRadio
                        value={activeEcg?.analyse.t2?.symetrie}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, t2: { ...activeEcg!.analyse?.t2, symetrie: value } })}
                        options={['Asymétrique', 'Symétrique']}
                    />
                    <ElRadio
                        value={activeEcg?.analyse.t2?.amplitude}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, t2: { ...activeEcg!.analyse?.t2, amplitude: value } })}
                        options={['Positive', 'Négative']}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(8)} expanded={activeAccordion === 8}>
                <AccordionSummary>Intervalle Q-T</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ElRadio

                        value={activeEcg?.analyse.qt?.s}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, qt: { s: value } })}
                        options={['Homme', 'Femme']}
                    />
                    {activeEcg?.analyse.qt?.s && <ElRadio
                        value={activeEcg?.analyse.qt?.i}
                        onChange={value => dispatch.updateEcg({ ...activeEcg!.analyse, qt: { ...activeEcg!.analyse.qt, i: value } })}
                        options={activeEcg?.analyse.qt?.s === 1 ? ['< 440ms', '> 440ms', 'impossible à déterminer'] : ['< 460ms', '> 460ms', 'impossible à déterminer']}
                    />}
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(9)} expanded={activeAccordion === 9}>
                <AccordionSummary>Interprétation</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputField
                        label="Interprétation"
                        defaultValue={activeEcg!.analyse.interpretation}
                        onBlur={({ target: { value } }) => dispatch.updateEcg({ ...activeEcg!.analyse, interpretation: value })}
                        //@ts-ignore
                        onKeyUp={({ key, target: { value } }) => key === 'Enter' && dispatch.updateEcg({ ...activeEcg!.analyse, interpretation: value })}
                        multiline
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion onChange={handleChangeAccordion(10)} expanded={activeAccordion === 10}>
                <AccordionSummary>Intervention infirmière</AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputField
                        label="Intervention infirmière"
                        defaultValue={activeEcg!.analyse.intervention}
                        onBlur={({ target: { value } }) => dispatch.updateEcg({ ...activeEcg!.analyse, intervention: value })}
                        //@ts-ignore
                        onKeyUp={({ key, target: { value } }) => key === 'Enter' && dispatch.updateEcg({ ...activeEcg!.analyse, intervention: value })}
                        multiline
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

interface ElRadioProps {
    title?: string
    value: number | null | undefined
    onChange: (value: number) => any
    options: string[]
}
const ElRadio = ({ title, value, onChange, options }: ElRadioProps) => <>
    {title && <FormLabel sx={{ mt: 2 }}>{title}</FormLabel>}
    <RadioGroup
        value={value ?? ''}
        name="freq-regularity"
        sx={{ display: "flex", flexDirection: "row" }}
        onChange={(_, value) => onChange(+value)}
    >
        {options.map((o, i) =>
            <FormControlLabel key={i} value={i + 1} control={<Radio />} label={o} />)}
    </RadioGroup>
</>

export default Analyse