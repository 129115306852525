import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, TextField } from '@mui/material'
import React from 'react'
import IPASValueType, { IPASValueSystType } from '../../Stores/IPASTypes'
import InputField from '../InputField'

interface SystemsProps {
    ipas: IPASValueType,
    onChange: (value: IPASValueSystType | undefined) => void
}
const Systems = ({ ipas, onChange }: SystemsProps) => {

    const show = ipas.systemes !== undefined

    const [open, toggleOpen] = React.useReducer(o => !o, false)

    const [hdm, setHdm] = React.useState<string>('')
    const [resp, setResp] = React.useState<string>('')
    const [neuro, setNeuro] = React.useState<string>('')
    const [dig, setDig] = React.useState<string>('')
    const [renal, setRenal] = React.useState<string>('')
    const [cut, setCut] = React.useState<string>('')
    const [social, setSocial] = React.useState<string>('')

    const changeSystem = () => onChange({ hdm, resp, neuro, dig, renal, cut, social })

    React.useEffect(() => {
        setHdm(ipas.systemes?.hdm ?? '')
        setResp(ipas.systemes?.resp ?? '')
        setNeuro(ipas.systemes?.neuro ?? '')
        setDig(ipas.systemes?.dig ?? '')
        setRenal(ipas.systemes?.renal ?? '')
        setCut(ipas.systemes?.cut ?? '')
        setSocial(ipas.systemes?.social ?? '')
    }, [show])


    return (
        <Grid item xs={12}>
            {/* <Dialog
                open={open}
                onClose={toggleOpen}>
                <DialogTitle>Effacer par systèmes ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Cette action sera définitive.</DialogContentText>
                    <DialogContentText>Toutes les données seront effacées.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleOpen}>Annuler</Button>
                    <Button color='error' onClick={() => { onChange(undefined); toggleOpen() }}>Effacer</Button>
                </DialogActions>
            </Dialog>
            <FormControlLabel
                control={<Checkbox checked={show} onClick={show ? toggleOpen : changeSystem} />}
                label={'Par systèmes'} /> */}

            {/* {show && <Box sx={{ pl: 4 }}> */}
            <Box sx={{ pl: 4 }}>
                <InputField
                    label="HDM"
                    value={hdm}
                    onChange={({ target: { value } }) => setHdm(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Respiratoire"
                    value={resp}
                    onChange={({ target: { value } }) => setResp(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Neurologique"
                    value={neuro}
                    onChange={({ target: { value } }) => setNeuro(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Digestif"
                    value={dig}
                    onChange={({ target: { value } }) => setDig(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Rénal"
                    value={renal}
                    onChange={({ target: { value } }) => setRenal(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Cutané"
                    value={cut}
                    onChange={({ target: { value } }) => setCut(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <InputField
                    label="Social"
                    value={social}
                    onChange={({ target: { value } }) => setSocial(value)}
                    onBlur={changeSystem}
                    onKeyUp={({ key }) => key === 'Enter' && changeSystem()}
                    multiline
                    fullWidth
                    sx={{ mb: 2 }}
                />
            </Box>
            {/* </Box>} */}

        </Grid>
    )
}

export default Systems