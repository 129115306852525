import React from 'react'
import Page from '../../../Components/PageInterface/Page'
import { Add, Edit, Receipt } from '@mui/icons-material'
import CardTable from '../../../Components/CardTable/CardTable'
import { Button, Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../App'
import InvoiceType from '../../../Stores/InvoiceType'

const InvoicesList = () => {
    const { invoices, dispatch: AppDispatch, fetch } = React.useContext(AppContext)

    const navigate = useNavigate()

    const createInvoice = () => fetch('/api/admin/invoices', 'POST').then((invoice: InvoiceType) => navigate(`/aleph/invoices/${invoice.id}`))

    React.useEffect(() => {
        AppDispatch.invoices.getAll()
    }, [])
    return (
        <Page
            title={'Liste des factures'}
            breadcrumb={[
                { url: '/aleph/invoices', name: 'Factures', icon: Receipt },
            ]}
            children={<CardTable
                customFilters={[
                    { name: 'En attentes', filter: (datas) => datas },
                    { name: 'Envoyées', filter: (datas) => datas },
                    { name: 'Payées', filter: (datas) => datas }
                ]}
                columns={[
                    { field: 'client', header: { label: 'Client' }, body: (invoice) => invoice.client?.name ?? '' },
                    { field: 'title', header: { label: 'Libellé' }, body: (invoice) => invoice.title ?? '' },
                    {
                        field: 'status', header: { label: 'Status' }, body: (invoice) =>
                            (invoice.status === 'cancel' && <Chip label="Annulée" color='info' />) ||
                            (invoice.status === 'created' && <Chip label="En attente" color='warning' />) ||
                            (invoice.status === 'delayed' && <Chip label="Retardée" color='warning' />) ||
                            (invoice.status === 'sended' && <Chip label="Envoyée" color='warning' />) ||
                            (invoice.status === 'paid' && <Chip label="Payée" color='success' />)
                    }

                ]}
                buttons={[
                    { rounded: true, onClick: (data) => navigate(`/aleph/invoices/${data.id}`), icon: Edit }
                ]}
                datas={invoices ?? []}
                noCard={true}
            />}
            loading={false}
            rightPlace={<Button onClick={createInvoice}>
                <Add /> Nouvelle facture
            </Button>}
            fullPage
        />
    )
}

export default InvoicesList