import { Upload } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import FileType from '../Stores/FileType'
import { AppContext } from '../App'
import File from '../Stores/FileType'

interface FileUploaderProps {
    accept?: string
    multiple?: boolean
    entityName: string
    entityId: string
    onUploadEnd: (files: File[]) => void
}
const FileUploader = ({ accept, multiple, entityName, entityId, onUploadEnd }: FileUploaderProps) => {
    const { fetch } = React.useContext(AppContext)
    const ref = React.createRef<HTMLInputElement>()


    const handleClick = () => {
        ref.current?.click()
    }

    const handleUpload = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
        console.log(files)

        if (!files) return
        Promise.all([...files].map(file => {
            const form = new FormData()
            form.append('file', file)
            return fetch(`/api/admin/files/${entityName}/${entityId}`, 'POST', form)
        })).then(onUploadEnd)

        ref.current!.value = ''
    }


    return (
        <React.Fragment>
            <input ref={ref} type='file' hidden {...{ accept, multiple }} onChange={handleUpload} />
            <Button onClick={handleClick}>
                <Upload />  Charger un fichier
            </Button>
        </React.Fragment>
    )
}

export default FileUploader