import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// serviceWorkerRegistration.register({
//   onSuccess: (reg) => console.log('success'),
//   onUpdate: (reg) => console.log('updated'),
//   onFail: (reason) => console.log(reason)
// });
serviceWorkerRegistration.unregister()