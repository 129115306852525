import React from 'react'
import { AppContext } from '../../App'
import { Avatar, Badge, Box, IconButton, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Switch } from '@mui/material'
import { Dns } from '@mui/icons-material'
import { AppType } from '../../Stores/usePm2'

const AppsMenu = () => {
    const { apps, dispatch } = React.useContext(AppContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const inError = React.useMemo(() => apps?.filter(a => a.status !== 'online').length, [apps])

    const handleClick = (app: AppType) => app.status === 'online' ? dispatch.apps.stop(app.name) : dispatch.apps.start(app.name)
    return (
        <Box display={apps && apps.length >= 0 ? 'block' : 'none'}>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)} color={inError === 0 ? 'primary' : 'secondary'}>
                <Badge
                    badgeContent={apps?.filter(a => a.status !== 'online').length}
                    color={'error'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Avatar ><Dns color={inError === 0 ? 'primary' : 'secondary'} /></Avatar>
                </Badge>
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {apps?.map(app => <MenuItem key={app.pid} onClick={() => handleClick(app)} >
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <ListItemText primary={app.name} />
                        <Box sx={{ ml: 4 }}><Switch checked={app.status === 'online'} disabled /></Box>
                    </Box>
                </MenuItem>)}
            </Menu>
        </Box >
    )
}

export default AppsMenu