import { Close, Edit, QuestionAnswer } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, FormControl, FormControlLabel, IconButton, MenuItem, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { DocumentContext } from '../useDocument'
import TabQuestions from '../TabQuestion/TabQuestions'
import { PageContext } from '../../../../../Components/PageInterface/Page'
import { QuestionType } from '../../../../../Stores/CoursType'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { v4 } from 'uuid'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import DeleteButton from '../../../../../Components/DeleteButton'
import InputField from '../../../../../Components/InputField'
import QuestionItem from '../TabQuestion/QuestionItem'
import CreateQuestion from '../TabSlides/CreateQuestion'
import PreviewQuestion from './PreviewQuestion'

const QuizzDrawer = () => {
    const { cours, activeSlide, openQuizzDrawer, dispatch } = React.useContext(DocumentContext)
    const { tab } = React.useContext(PageContext)
    const canFilter = tab === 'Slides'
    const [filter, setFilter] = React.useState(false)

    const [edit, setEdit] = React.useState<QuestionType | undefined>()


    const handleAddQuestion = () => setEdit(prev => ({
        ...prev!,
        reponses: [...prev!.reponses, { id: v4(), value: '', correct: false }]
    }))

    const onDragEnd = (event: DragEndEvent) => {

        const activeId = event.active.data.current!.sortable.index
        const overId = event.over!.data.current!.sortable.index
        setEdit(prev => ({
            ...prev!,
            reponses: arrayMove(prev!.reponses, activeId as number, overId as number)
        }))
    }

    const handleSave = () => {
        dispatch.saveQuestion(edit!)
        setEdit(undefined)
    }

    const questions = (canFilter && filter
        ? cours?.questions.filter(q => q.slideNumber === activeSlide)
        : cours?.questions)
        ?? []

    return (
        <React.Fragment>
            <Tooltip title="Quizz">
                <IconButton onClick={dispatch.toggleQuizzDrawer}>
                    <QuestionAnswer />
                </IconButton>
            </Tooltip>

            <Drawer
                anchor={'right'}
                open={openQuizzDrawer}
                onClose={dispatch.toggleQuizzDrawer}
            >
                <Box sx={{ mt: 8, p: 1, minWidth: { xs: '100vw', md: '50vw' } }}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant={'h5'}>Quizz questions</Typography>
                        <Box >
                            <IconButton onClick={dispatch.toggleQuizzDrawer}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        {canFilter && <FormControlLabel control={<Switch checked={filter} onChange={(_, checked) => setFilter(checked)} />} label={'Questions de la slide active uniquement'} />}
                        <CreateQuestion slideNumber={activeSlide ?? 0} />
                    </Box>
                    <Box ml={1} mr={1} p={1} display={'flex'} flexDirection={'column'} gap={1}>
                        <Dialog open={Boolean(edit)} maxWidth={'lg'} fullWidth >
                            <DialogTitle>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box>Edition de la question : {edit?.question ?? ''} </Box>
                                    <Box>
                                        <IconButton onClick={() => setEdit(undefined)}>
                                            <Close />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <Box display={'flex'} flexDirection={'column'} gap={1}>
                                    <InputField
                                        label="Enoncé de la question"
                                        value={edit?.question ?? ''}
                                        onChange={({ target: { value } }) => setEdit(prev => ({ ...prev!, question: value }))}
                                        multiline
                                        fullWidth
                                    />
                                    <InputField
                                        label="Type de question"
                                        value={edit?.type ?? 0}
                                        onChange={({ target: { value } }) => setEdit(prev => ({ ...prev!, type: +value, reponses: +value === 2 ? [{ id: v4(), value: 'Vrai', correct: false }, { id: v4(), value: 'Faux', correct: false }] : [] }))}
                                        fullWidth
                                        error={edit?.type === 0 && edit?.reponses.filter(el => el.correct).length > 1}
                                        select
                                    >
                                        {['Choix unique', 'Choix multiple', 'Vrai/Faux'].map((el, i) => <MenuItem key={i} value={i}>{el}</MenuItem>)}
                                    </InputField>
                                    <Divider />
                                    <Box>
                                        <Button onClick={handleAddQuestion}>
                                            Ajouter une réponse
                                        </Button>
                                    </Box>
                                    <Box display={'flex'} flexDirection={'column'} gap={1}>
                                        <DndContext
                                            onDragEnd={onDragEnd}
                                        >
                                            <SortableContext
                                                items={edit?.reponses.map((el, i) => el.id) ?? []}
                                            >
                                                {edit?.reponses.map((el, i) => <QuestionItem reponse={el} setQuestion={setEdit} key={i} />)}
                                            </SortableContext>
                                        </DndContext>
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setEdit(undefined)}>
                                    Annuler
                                </Button>
                                <Button disabled={
                                    !edit?.question ||
                                    edit?.reponses.length === 0 ||
                                    ((edit?.type === 0 || edit?.type === 2) && edit?.reponses.filter(r => r.correct).length !== 1) ||
                                    edit?.type === 2 && edit?.reponses.length !== 2
                                } onClick={handleSave}>
                                    Enregistrer
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {questions.map((q, i) =>
                            // <Box key={q.id} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}
                            //     sx={{
                            //         p: 1,
                            //         '& .hoverable': {
                            //             visibility: 'hidden'
                            //         },
                            //         '&:hover .hoverable': {
                            //             visibility: 'visible',
                            //         },
                            //         '&:hover': {
                            //             backgroundColor: 'rgba(0,0,0,0.1)',
                            //         }

                            //     }}>
                            //     <Box flexGrow={1}>{q.question}</Box>
                            //     <Box>
                            //         {q.type === 0 ? 'Choix unique' :
                            //             q.type === 1 ? 'Choix multiple' :
                            //                 'Vrai/Faux'}
                            //     </Box>
                            //     <Box className={'hoverable'}>
                            //         <IconButton onClick={() => setEdit(q)}>
                            //             <Edit />
                            //         </IconButton>
                            //         <DeleteButton
                            //             onDelete={() => dispatch.deleteQuestion(q.id)}
                            //         />

                            //     </Box>
                            // </Box>
                            <PreviewQuestion question={q} setEdit={setEdit} />)}
                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default QuizzDrawer