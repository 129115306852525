import React from "react"
import { useEditChapitreContextType, useEditChapitreDispatchType, useEditChapitreType } from "./types"
import { Chapitre, ContentItem } from "../../../Stores/ChapitreType"
import { AppContext } from "../../../App"
import { arrayMove } from "@dnd-kit/sortable"
import { v4 } from 'uuid'
import File from "../../../Stores/FileType"

export const ChapitreContext = React.createContext<useEditChapitreContextType>({} as useEditChapitreContextType)
const useEditChapitre: useEditChapitreType = (id) => {
    const { chapitres, dispatch: storeDispatch, fetch } = React.useContext(AppContext)
    const [chapitre, setChapitre] = React.useState<Chapitre | undefined>()
    const [parent, setParent] = React.useState<undefined | Chapitre | null>()
    const [editedItemId, setEditedItemId] = React.useState<string | null>(null)
    const [copyItem, setCopyItem] = React.useState<ContentItem | null>(null)
    const editedValue = React.useMemo(() => {
        const deep = (tree: ContentItem[]): ContentItem | null => {
            for (let i = 0, mx = tree.length; i < mx; i++) {
                if (tree[i].id === editedItemId) return tree[i]
                if (tree[i].childs) {
                    let find = deep(tree[i].childs as ContentItem[])
                    if (find) return find
                }
            }
            return null
        }
        return deep(chapitre?.value ?? []) ?? null
    }, [editedItemId, chapitre])

    const dispatch: useEditChapitreDispatchType = React.useMemo(() => ({
        update: async (partial: Partial<Chapitre>) => fetch(`/api/admin/chapitres/${id}`, 'PATCH', partial).then((el: Chapitre) => {
            setChapitre(el)
            storeDispatch.chapitres.patch(id, el)
        }),
        changePosition: async (newPosition, parentId) => fetch(`/api/admin/chapitres/sort`, 'PATCH', { oldPosition: chapitre!.position, newPosition, parentId }),

        addValueItem: (parentId, el) => {
            let newValue = [...chapitre!.value]
            if (parentId === null) {
                newValue.push(el)
            } else {
                const deep = (items: Chapitre['value']): Chapitre['value'] => items.map(v => v.id === parentId ? ({ ...v, childs: [...(v.childs ?? []), el] }) as ContentItem : v.childs ? ({ ...v, childs: deep(v.childs) }) : v)
                newValue = deep(newValue)
            }
            setChapitre(prev => ({ ...prev!, value: newValue }))
            setTimeout(function () { dispatch.setEditedItem(el.id) }, 100)
            return dispatch.update({ value: newValue })
        },
        updateValueItem: (valueId, partial) => {
            const deep = (items: Chapitre['value']): Chapitre['value'] => items.map(v => v.id === valueId ? ({ ...v, ...partial }) as ContentItem : v.childs ? ({ ...v, childs: deep(v.childs) }) : v)
            const newValue = deep([...chapitre!.value])
            setChapitre(prev => ({ ...prev!, value: newValue }))
            return dispatch.update({ value: newValue })
        },
        deleteValueItem: (id) => {
            const deep = (items: Chapitre['value']): Chapitre['value'] => items.filter(v => v.id !== id).map(i => i.childs ? ({ ...i, childs: deep(i.childs) }) : i)
            const newValue = deep([...chapitre!.value])
            setChapitre(prev => ({ ...prev!, value: newValue }))
            return dispatch.update({ value: newValue })
        },
        sortValueItem: async (parentId: string | null, oldIndex: number, newIndex: number) => {
            let value = [...chapitre!.value]
            if (!parentId) {
                value = arrayMove(value, oldIndex, newIndex)
            } else {
                const deep = (items: Chapitre['value']): Chapitre['value'] => items.map(i => i.id === parentId ? ({ ...i, childs: arrayMove(i.childs!, oldIndex, newIndex) }) : i.childs ? ({ ...i, childs: deep(i.childs) }) : i)
                value = deep(value)
            }
            setChapitre(prev => ({ ...prev!, value }))
            return dispatch.update({ value })

        },


        setEditedItem: (id) => setEditedItemId(id),
        setCopyItem: () => setCopyItem(editedValue),
        pasteCopyItem: async (parentId: string | null) => {
            if (!copyItem) return
            const deepId = (el: ContentItem): ContentItem => el.childs ? ({ ...el, childs: el.childs.map(c => deepId(c)), id: v4() }) : ({ ...el, id: v4() })
            let newEl = deepId(copyItem)
            let value = [...chapitre!.value]
            if (!parentId) {
                value.push(newEl)
            } else {
                const deep = (els: ContentItem[]): ContentItem[] => els.map(el => el.id === parentId ? ({ ...el, childs: [...(el.childs ?? []), newEl] }) : ({ ...el, ...(el.childs && { childs: deep(el.childs) }) }))
                value = deep(value)
            }
            setChapitre(prev => ({ ...prev!, value }))
            return dispatch.update({ value })
        },

        addChapter: async () => fetch(`/api/admin/chapitres/${id}/chapitres`, 'POST')
            .then((el: Chapitre) => {
                if (el) {
                    let newChapitre = ({ ...chapitre!, chapitres: [...chapitre!.chapitres, el] as Chapitre['chapitres'] })
                    setChapitre(newChapitre)
                    storeDispatch.chapitres.patch(id, newChapitre)
                }
            }),

        addFile: (file: File) => {
            setChapitre(prev => ({ ...prev!, files: [...(prev!.files ?? []), file] }))
        },
        removeFile: (file: File) => {
            setChapitre(prev => ({ ...prev!, files: prev!.files.filter(f => f.id !== file.id) }))
        }

    }), [chapitre, editedValue, copyItem])

    React.useEffect(() => {
        storeDispatch.chapitres.get(id, (el => el && setChapitre(el))).then(el => el && setChapitre(el))
    }, [id])

    React.useEffect(() => {
        if (chapitre && chapitre.parentId) {
            if (parent === undefined) {
                storeDispatch.chapitres.get(chapitre.parentId, (el) => setParent(el)).then(el => setParent(el ?? null))
            }
        }
    }, [chapitre])
    return {
        chapitre, dispatch, editedValue, copyItem, parent
    }
}

export default useEditChapitre