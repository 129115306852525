import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'
import { PlayArrow, Pause } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'

const PlayPauseButtons = () => {
    const { status, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <React.Fragment>
            {status === 'paused' && <Box>
                <IconButton color='inherit' onClick={dispatch.play}>
                    <PlayArrow color='inherit' />
                </IconButton>
            </Box>}
            {status === 'playing' && <Box>
                <IconButton color='inherit' onClick={dispatch.pause}>
                    <Pause color='inherit' />
                </IconButton>
            </Box>}
        </React.Fragment>
    )
}

export default PlayPauseButtons