import { Box, SxProps, Typography } from "@mui/material"
import React, { MouseEvent } from "react"

interface FormAreaProps {
    label?: string
    children: JSX.Element | JSX.Element[] | React.ReactNode
    sx?: SxProps
    innerSx?: SxProps
    className?: string
    onClick?: (event: MouseEvent<HTMLDivElement>) => void
    inCard?: boolean
}

const FormArea = ({ label, children, sx = {}, innerSx, className, onClick, inCard = true }: FormAreaProps) => {
    return (
        <Box sx={{
            position: 'relative',
            border: theme => `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
            borderRadius: theme => theme.spacing(1.25),
            p: 1,
            mt: 1.15,
            mb: 1,
            '&:hover': {
                borderColor: theme => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
                '& [role="FormAreaLabel"]': {
                    color: theme => `${theme.palette.primary.main} !important`
                }
            },
            '&:active': {
                borderColor: theme => theme.palette.primary.main,
                '& [role="FormAreaLabel"]': {
                    color: theme => `${theme.palette.primary.main} !important`
                }
            },
            ...sx
        }} className={className} onClick={onClick}>
            {label &&
                <Typography
                    variant="caption"
                    role="FormAreaLabel"
                    sx={{
                        top: 0,
                        position: 'absolute',
                        pl: 0.5,
                        pr: 1,
                        color: theme => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
                        backgroundColor: theme => theme.palette.mode === 'light'
                            ? inCard ? theme.palette.background.paper : '#fafafa'
                            : '#424242',
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                        transform: 'translate(0px, -50%)',
                        userSelect: 'none'
                    }}>
                    {label}
                </Typography>}
            <Box role={'innerBox'} sx={{ p: .5, ...innerSx }}>
                {children}
            </Box>
        </Box>

    )
}

export default FormArea