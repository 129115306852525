const systemNames = [
    { id: 1, name: 'Cardio-vasculaire' },
    { id: 2, name: 'Respiratoire' },
    { id: 3, name: 'Neurologique' },
    { id: 4, name: 'Digestif' },
    { id: 5, name: 'Rénal' },
    { id: 6, name: 'Cutané' },
    { id: 7, name: 'Social' },
    { id: 8, name: 'Infectieux' },
    { id: 9, name: 'Hydrique-métabolique-électrolytique' },
    { id: 10, name: 'Psycho-social' },
]

export default systemNames