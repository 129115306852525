import React from 'react'
import { Box, CssBaseline } from '@mui/material'
import { AppBar, Drawer, MainContent, UiContext } from '.'
import { UiContextType } from './UiContext'

const Ui = ({ children }: React.PropsWithChildren) => {
    const [open, toggleOpen] = React.useReducer(o => !o, false)
    const contextValue: UiContextType = React.useMemo(() => ({ open, toggleOpen }), [open])
    return (
        <UiContext.Provider value={contextValue}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', height: theme => `calc(100vh)`
            }}>
                <CssBaseline />
                <Box>
                    <AppBar />
                    <Drawer />
                </Box>
                <MainContent>
                    {children}
                </MainContent>
            </Box >
        </UiContext.Provider>
    )
}

export default Ui