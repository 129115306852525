import { TableRow, TableCell, Typography, Box, Tooltip, IconButton, Button, Collapse } from '@mui/material'
import React from 'react'
import TableCollapserButton from './TableCollapserButton'
import { TableContext } from '../useTable'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { DragHandle } from '@mui/icons-material'

interface RowProps {
    data: any
    index: number
}
const Row = ({
    data,
    index
}: RowProps) => {
    const { datas, columns, buttons, collapse, collapsed, isSortable } = React.useContext(TableContext)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: data.id });


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };
    return (
        <React.Fragment key={data.id}>
            <TableRow key={index} sx={{
                '& .hoverable *': { visibility: 'hidden' },
                '&:hover .hoverable *': { visibility: 'visible' },
                '& .buttons-container button': { visibility: 'hidden' },
                '& .buttons-container button[role="visible"]': { visibility: 'visible' },
                '&:hover .buttons-container button': { visibility: 'visible' },
                ...(collapse && { '& > *': { borderBottom: 'unset !important' } })
            }}
                style={style}
                ref={setNodeRef}
                {...attributes}
            >
                {isSortable && <TableCell className="hoverable"><DragHandle {...listeners} /></TableCell>}
                {collapse && <TableCollapserButton id={data.id} />}
                {columns.map(column => <TableCell key={column.field.toString()}>{
                    !column.body
                        ? <Typography>{data[column.field] as string}</Typography>
                        : typeof column.body === 'function'
                            ? column.body(data)
                            : column.body
                }</TableCell>)}
                {buttons && buttons.length > 0 && <TableCell sx={{ width: '1px' }}>
                    <Box display={'flex'} flexWrap={'nowrap'} gap={1} className="buttons-container">
                        {buttons.filter(b => typeof b === 'function' || !b.isHeader).map((button, index) =>
                            typeof button === 'function'
                                ? button(data)
                                : <Tooltip key={index + data.id} title={button.label ?? ''} disableHoverListener={!button.label}>
                                    {button.rounded
                                        ? <IconButton
                                            onClick={() => button.onClick(data)}
                                            size='small'
                                            color={button.color
                                                ? typeof button.color === 'function'
                                                    ? button.color(data)
                                                    : button.color
                                                : undefined}
                                            role={button.role ? button.role(data) : undefined}
                                            disabled={button.disabled ? button.disabled(data) : false}
                                        >
                                            {button.icon && <button.icon fontSize={'small'} />}
                                        </IconButton>
                                        : <Button
                                            onClick={() => button.onClick(data)}
                                            size='small'
                                            color={button.color
                                                ? typeof button.color === 'function'
                                                    ? button.color(data)
                                                    : button.color
                                                : undefined}
                                            role={button.role ? button.role(data) : undefined}
                                            disabled={button.disabled ? button.disabled(data) : false}
                                        >
                                            {button.icon && <button.icon fontSize={'small'} />}
                                            {button.label}
                                        </Button>}

                                </Tooltip>)}
                    </Box>
                </TableCell>}
            </TableRow>
            {collapse && <TableRow>
                <TableCell colSpan={1 + columns.length + (buttons ? 1 : 0)} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={collapsed.includes(data.id)} timeout="auto" unmountOnExit children={collapse(data)} />
                </TableCell>
            </TableRow>}
        </React.Fragment>
    )
}

export default Row