import { Check, ChevronRight, Close, Edit } from '@mui/icons-material'
import { Box, Collapse, IconButton } from '@mui/material'
import React from 'react'
import DeleteButton from '../../../../../Components/DeleteButton'
import { QuestionType } from '../../../../../Stores/CoursType'
import { DocumentContext } from '../useDocument'

interface PreviewQuestionProps {
    question: QuestionType,
    setEdit: React.Dispatch<React.SetStateAction<QuestionType | undefined>>
}
const PreviewQuestion = ({
    question: q,
    setEdit
}: PreviewQuestionProps) => {
    const { dispatch } = React.useContext(DocumentContext)
    const [open, toggleOpen] = React.useReducer(o => !o, false)
    return (
        <React.Fragment>
            <Box key={q.id} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}
                sx={{
                    pl: 1,
                    pr: 1,
                    '& .hoverable': {
                        visibility: 'hidden'
                    },
                    '&:hover .hoverable': {
                        visibility: 'visible',
                    },
                    '&:hover': {
                        // backgroundColor: 'rgba(0,0,0,0.1)',
                    }

                }}>
                <Box>
                    <IconButton onClick={toggleOpen}>
                        <ChevronRight sx={{ transform: open ? 'rotate(90deg)' : undefined }} />
                    </IconButton>
                </Box>
                <Box flexGrow={1}>{q.question}</Box>
                <Box>
                    {q.type === 0 ? 'Choix unique' :
                        q.type === 1 ? 'Choix multiple' :
                            'Vrai/Faux'}
                </Box>
                <Box className={'hoverable'}>
                    <IconButton onClick={() => setEdit(q)}>
                        <Edit />
                    </IconButton>
                    <DeleteButton
                        onDelete={() => dispatch.deleteQuestion(q.id)}
                    />
                </Box>
            </Box>
            <Collapse in={open}>
                <Box pr={1} pl={3} pb={1}>
                    {q.reponses.map((r, i) => <Box key={i} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                        <Box>{r.correct ? <Check color='success' /> : <Close color='error' />}</Box>
                        <Box flexGrow={1}>{r.value}</Box>
                    </Box>)}
                </Box>
            </Collapse>
        </React.Fragment>
    )
}

export default PreviewQuestion