import { Delete, Edit } from '@mui/icons-material'
import { TableRow, TableCell, Box, Typography, IconButton } from '@mui/material'
import React from 'react'
import ItemComment from './ItemComment'
import RadioEval from './RadioEval'
import { competenceResourceType } from '../../../EPDSIList'
import { EditEcContext } from '../useEditEc'


interface CompetenceItemLineProps {
    processus: number
    competence: number
    item: competenceResourceType
}
const CompetenceItemLine = ({ processus, competence, item }: CompetenceItemLineProps) => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    const addComment = () => dispatch.addComment(processus, competence, item.id)

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="caption">{item.label}</Typography>
                </TableCell>
                <RadioEval processus={processus} competence={competence} item={item.id} value={1} />
                <RadioEval processus={processus} competence={competence} item={item.id} value={2} />
                <RadioEval processus={processus} competence={competence} item={item.id} value={3} />
                <RadioEval processus={processus} competence={competence} item={item.id} value={4} />
                <RadioEval processus={processus} competence={competence} item={item.id} value={-1} />
                <TableCell>
                    {ec?.comments.find(comment => comment.processus === processus && comment.competence === competence && comment.item === item.id)
                        ? <></>
                        : <IconButton size="small" onClick={addComment}>
                            <Edit fontSize="small" />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
            <ItemComment processus={processus} competence={competence} item={item.id} />
        </>
    )
}

export default CompetenceItemLine