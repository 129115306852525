import React from 'react'
import { AppContext } from '../../App'
import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const UserMenu = () => {
    const { user, dispatch } = React.useContext(AppContext)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    return (
        <Box>
            <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <Avatar>{user!.firstname.at(0)!.toLocaleUpperCase()}{user!.lastname.at(0)!.toLocaleUpperCase()}</Avatar>
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box m={2} display={'flex'} alignItems={'center'} gap={2}>
                    <Box>
                        <Avatar sx={{ width: 75, height: 75 }}>{user!.firstname.at(0)!.toLocaleUpperCase()}{user!.lastname.at(0)!.toLocaleUpperCase()}</Avatar>
                    </Box>
                    <Box>
                        <Typography variant='subtitle1'>{user!.firstname} {user!.lastname}</Typography>
                        <Typography variant='caption'>{user!.email}</Typography>
                    </Box>
                </Box>
                <Divider />
                <MenuItem component={Link} to='/profil'>
                    Profil
                </MenuItem>
                <MenuItem onClick={dispatch.auth.logOut}>
                    Se déconnecter
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu