import React from 'react'
import InputField from '../../../Components/InputField'
import { ChapitreContext } from './useEditChapitre'
import { Chapitre } from '../../../Stores/ChapitreType'
import { Box, MenuItem } from '@mui/material'

const GeneralitesTab = () => {
    const { chapitre, dispatch } = React.useContext(ChapitreContext)

    const [name, setName] = React.useState<string>(chapitre!.name)
    const [position, setPosition] = React.useState<number>(chapitre!.position + 1)
    const handleChangeName = () => chapitre!.name !== name && dispatch.update({ name })
    const handleChangeStatus = ({ target: { value: status } }: React.ChangeEvent<HTMLInputElement>) => Number(status) !== chapitre!.status && dispatch.update({ status: Number(status) })
    const handleChangePosition = () => dispatch.changePosition(position - 1, chapitre!.parentId)

    React.useEffect(() => {
        if (chapitre) setName(chapitre.name)
        if (chapitre) setPosition(chapitre.position + 1)
    }, [chapitre])

    return (
        <Box p={1} gap={2} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} gap={2}>
                <Box flexGrow={3}>
                    <InputField
                        label="Nom du chapitre"
                        value={name}
                        onChange={({ target: { value } }) => setName(value)}
                        onBlur={handleChangeName}
                        onKeyUp={({ key }) => key === 'Enter' && handleChangeName()}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box display={'flex'} gap={2}>
                <Box flexGrow={1}>
                    <InputField
                        label="Chapitre"
                        type='number'
                        onChange={({ target: { value } }) => setPosition(Number(value))}
                        onBlur={handleChangePosition}
                        onKeyUp={({ key }) => key === 'Enter' && handleChangePosition()}
                        value={position}
                        inputProps={{ min: 1, step: 1 }}
                        fullWidth />
                </Box>
                <Box flexGrow={1}>
                    <InputField
                        label="Status"
                        onChange={handleChangeStatus}
                        value={chapitre!.status}
                        select
                        fullWidth>
                        <MenuItem value={0}>A faire</MenuItem>
                        <MenuItem value={1}>A mettre à jour</MenuItem>
                        <MenuItem value={2}>Finalisé</MenuItem>
                    </InputField>
                </Box>

            </Box>
        </Box>
    )
}

export default GeneralitesTab