import { Card, CardHeader, Avatar, IconButton, CardActions, Button, CardContent, Typography, SxProps, Theme, Chip, Badge } from "@mui/material";
import { Comment as CommentIcon, Edit, FactCheck, Rule } from "@mui/icons-material";
import { KanbanContext } from "../useKanban";
import React from "react";
import { KanbanCard } from "../../../Stores/KanbanType";

interface ItemProps {
    id: string
    card: KanbanCard
    dragOverlay?: boolean
}
const Item = ({ id, card, dragOverlay }: ItemProps) => {
    const { dispatch } = React.useContext(KanbanContext)
    const handleEdit = () => dispatch.setEditedCard(card)

    return (
        <Card sx={{
            width: `100%`,
            cursor: dragOverlay ? "grabbing" : "grab",
            borderRadius: 0,
            ...(card.status ? {
                width: `calc(100% - 10px)`,
                borderLeftWidth: 10,
                borderLeftStyle: 'solid',
                borderLeftColor: (theme: Theme) => ['', theme.palette.primary.main, theme.palette.warning.main, theme.palette.success.main].at(card.status!)
            } : {}) as SxProps,
            mb: 1,
            '& .MuiCardHeader-root button': { visibility: 'hidden' },
            '&:hover .MuiCardHeader-root button': { visibility: 'visible' },
            '& .MuiCardActions-root': { visibility: 'visible' },
        }} className="item">
            <CardHeader
                title={card.name}
                action={<IconButton onClick={handleEdit} onTouchEnd={handleEdit} size="small"><Edit fontSize="small" /></IconButton>}
            />
            {card.description && <CardContent>
                <Typography variant="caption" component={'p'} color={'GrayText'} textAlign={"justify"}>{card.description}</Typography>
            </CardContent>}
            <CardActions>
                {card.tags?.map(tag => <Chip label={tag} key={tag} size="small" />)}
            </CardActions>
            {(card.cl.length > 0 || card.comments.length > 0) &&
                <CardActions>
                    {card.cl.length > 0 && <Badge badgeContent={Math.round((card.cl.filter(cl => cl.els.length > 0).map(checkList => Math.round(100 * checkList.els.filter(el => el.status).length / checkList.els.length)).reduce((a, b) => a + b)) / (card.cl.filter(cl => cl.els.length > 0).length)) + '%'} color="primary" >
                        <Rule />
                    </Badge>}
                    {card.comments.length > 0 && <Badge badgeContent={card.comments.length} color="primary" >
                        <CommentIcon />
                    </Badge>}
                </CardActions>}
        </Card>
    );
};

export default Item;
