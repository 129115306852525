import React from "react"
import ExamenCliniqueType from "../../../../Stores/ExamenClinqueType"
import { AppContext } from "../../../../App"

type useEditEcType = (id: ExamenCliniqueType['id']) => useEditEcContext
type useEditEcContext = {
    dispatch: useEditEcDispatch
    ec: ExamenCliniqueType | undefined
}
type useEditEcDispatch = {
    changeDate: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeExaminateur: (event: React.ChangeEvent<HTMLInputElement>) => void
    changePeriod: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeComplexity: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeName: (value: string) => void
    changeDescription: (value: ExamenCliniqueType['description']) => void
    addComment: (processus: number, competence: number, item: number) => void
    deleteComment: (processus: number, competence: number, item: number) => void
    updateComment: (processus: number, competence: number, item: number, value: string) => void
    setEval: (processus: number, competence: number, item: number, value: number) => void
    changeEval2: (eval2: ExamenCliniqueType['eval2']) => void
}

export const EditEcContext = React.createContext<useEditEcContext>({} as useEditEcContext)

const useEditEc: useEditEcType = (id) => {
    const { examensCliniques, dispatch: dispatchApp, fetch } = React.useContext(AppContext)

    const [ec, setEc] = React.useState<ExamenCliniqueType | undefined>()
    const dispatch: useEditEcDispatch = React.useMemo(() => ({
        changeDate: (event) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { date: event.target.value + ' 12:00:00' })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        changeExaminateur: (event) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { examinateur: event.target.value })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        changePeriod: (event) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { period: +event.target.value })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        changeComplexity: (event) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { complexity: +event.target.value })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        changeName: (name) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { name })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        changeDescription: (description) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { description })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        addComment: (processus, competence, item) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { comments: [...ec!.comments, { processus, competence, item, value: '' }] })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        deleteComment: (processus, competence, item) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { comments: ec!.comments.filter(comment => comment.item === item ? comment.processus === processus && comment.competence === competence ? false : true : true) })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        updateComment: (processus, competence, item, value) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { comments: ec!.comments.map(comment => comment.processus === processus && comment.competence === competence && comment.item === item ? ({ ...comment, value }) : comment) })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            }),
        setEval: (processus, competence, item, value) => {
            if (Boolean((ec!.evals ?? []).find(evaluation => evaluation.processus === processus && evaluation.competence === competence && evaluation.item === item))) {
                fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { evals: (ec!.evals ?? []).map(evaluation => evaluation.processus === processus && evaluation.competence === competence && evaluation.item === item ? ({ ...evaluation, value }) : evaluation) })
                    .then((ec) => {
                        setEc(ec)
                        dispatchApp.examensCliniques.post(ec)
                    })
            } else {
                fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { evals: [...(ec!.evals ?? []), { processus, competence, item, value }] })
                    .then((ec) => {
                        setEc(ec)
                        dispatchApp.examensCliniques.post(ec)
                    })
            }
        },
        changeEval2: (eval2) => fetch(`/api/admin/examens-cliniques/${id}`, 'PATCH', { eval2 })
            .then((ec) => {
                setEc(ec)
                dispatchApp.examensCliniques.post(ec)
            })
    }), [ec])

    React.useEffect(() => {
        if (examensCliniques?.find(ecs => ecs.id === id)) {
            setEc(examensCliniques?.find(ecs => ecs.id === id))
        } else {
            setEc(undefined)
        }

    }, [examensCliniques, id])

    React.useEffect(() => {
        fetch(`/api/admin/examens-cliniques/${id}`, 'GET')
            .then((exam) => dispatchApp.examensCliniques.post(exam))
    }, [id])

    return {
        ec, dispatch
    }
}

export default useEditEc