import { Autocomplete, AutocompleteRenderInputParams, Box, Button, Grid, IconButton } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { MedicamentPresentationType } from '../../../../../Stores/MedicamentType'
import { AppContext } from '../../../../../App'
import MedicAdminVoieTranslate from '../../../../../utils/MedicAdminVoie'
import { Link } from '@mui/icons-material'

interface MedicamentEditeurProps {
    data: MedicamentPresentationType
}
const MedicamentEditeur = ({ data }: MedicamentEditeurProps) => {
    const { medicaments, fetch, dispatch } = React.useContext(AppContext)
    const { voies, dilutionUnits } = React.useMemo(() => ({
        voies: medicaments?.map(medicament => medicament.presentations.map(p => p.voie))
            .flat()
            .map(r => MedicAdminVoieTranslate(r))
            .filter((v, i, a) => a.indexOf(v) === i) ?? [],
        dilutionUnits: medicaments?.map(medicament => medicament.presentations.map(p => p.dilutionUnit))
            .flat()
            .filter((v, i, a) => a.indexOf(v) === i) ?? []
    }), [medicaments])

    const [med, setMed] = React.useState<MedicamentPresentationType>(data)

    const handleChangeName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setMed({ ...med, name: value })
    const handleChangeVoie = (_: any, value: string | null) => setMed({ ...med, voie: value ?? '' })
    const handleChangeDilution = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setMed({ ...med, dilution: +value })
    const handleChangeDilutionUnit = (_: any, value: string | null) => setMed({ ...med, dilutionUnit: value ?? '' })
    const handleChangeVolume = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setMed({ ...med, volume: +value })
    const handleChangeRefMed = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setMed({ ...med, refMed: value })

    const navigateToRefMed = () => window.open(med.refMed, '_blank')

    const handleCancel = () => setMed(data)
    const handleSave = () => {
        fetch(`/api/admin/medicament-presentations/${med.id}`, 'PATCH', {
            name: med.name,
            voie: med.voie,
            dilution: med.dilution,
            dilutionUnit: med.dilutionUnit,
            volume: med.volume,
            refMed: med.refMed
        }).then(setMed).then(() => dispatch.medicaments.getAll())
    }

    return (
        <Box sx={{ ml: 10, mr: 10, mb: 2 }}>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <InputField
                        label={'Nom du médicament'}
                        value={med.name}
                        onChange={handleChangeName}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Autocomplete
                        value={MedicAdminVoieTranslate(med.voie ?? '')}
                        onChange={handleChangeVoie}
                        freeSolo
                        renderInput={(params) =>
                            <InputField
                                {...params}
                                label={'Voie d\'administration'}
                                fullWidth
                            />
                        }
                        options={voies} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputField
                        label={'Dilution'}
                        value={med.dilution}
                        onChange={handleChangeDilution}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        value={med.dilutionUnit}
                        onChange={handleChangeDilutionUnit}
                        freeSolo
                        renderInput={(params) =>
                            <InputField
                                {...params}
                                label={'Unité'}
                                fullWidth
                            />
                        }
                        options={dilutionUnits} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputField
                        label={'Volume'}
                        value={med.volume}
                        onChange={handleChangeVolume}
                        InputProps={{
                            endAdornment: 'mL'
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        label={'Lien refMed'}
                        value={med.refMed}
                        onChange={handleChangeRefMed}
                        InputProps={{
                            endAdornment: <IconButton disabled={!med.refMed} onClick={navigateToRefMed}>
                                <Link />
                            </IconButton>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button variant='outlined' color='secondary' onClick={handleCancel}>
                        Annuler
                    </Button>
                    <Button variant='outlined' color='primary' sx={{ ml: 2 }} onClick={handleSave} disabled={!med.name}>
                        Enregistrer
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MedicamentEditeur