import React from 'react'
import CardTable from '../CardTable/CardTable'
import File from '../../Stores/FileType'
import sizeConverter from '../../utils/sizeConverter'
import { Avatar, Box, IconButton } from '@mui/material'
import { OndemandVideo, Delete, Image } from '@mui/icons-material'
import VideoPreview from './VideoPreview'
import PdfPreview from './PdfPreview'

interface FileTableProps {
    files: File[],
    handleDelete: (file: File) => void
}
const FileTable = ({ files, handleDelete }: FileTableProps) => {
    return (
        <CardTable
            columns={[
                {
                    field: 'mime', header: { label: '', style: { width: '1px' } }, body: (data) => <>
                        {data.mime.startsWith('image') && <Avatar variant='square' src={`/download/${data.id}`}><Image /></Avatar>}
                        {data.mime.startsWith('video') && <VideoPreview video={data} />}
                        {data.mime.endsWith('pdf') && <PdfPreview pdf={data} />}
                    </>
                },
                { field: 'name', header: { label: 'Nom du fichier' }, search: (data, search) => data.name.toLocaleLowerCase().includes(search) },
                { field: 'createdAt', header: { label: '', style: { textAlign: 'right', width: '1px' } }, body: (data) => <Box whiteSpace={'nowrap'}>{new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(data.createdAt))}</Box> },
                { field: 'size', header: { label: 'Taille', style: { textAlign: 'right', width: '1px' } }, body: (data) => <Box textAlign={'right'} whiteSpace={'nowrap'}>{sizeConverter(data.size)}</Box> }
            ]}
            customFilters={[
                { name: 'Images', filter: (files) => files.filter(f => f.mime.startsWith('image')) },
                { name: 'Vidéos', filter: (files) => files.filter(f => f.mime.startsWith('video')) },
                { name: 'PDF', filter: (files) => files.filter(f => f.mime.includes('pdf')) },
            ]}
            buttons={[
                { onClick: handleDelete, icon: Delete, rounded: true, color: 'error', label: 'Supprimer le fichier' }
            ]}
            datas={files}
            noCard={true} />
    )
}

export default FileTable