import React from "react"
import { DBInterface, Stores, dbApiType, useStoreDispatchType, useStoreType } from "./types"
import useAuth from "./useAuth"
import defaultTheme from '../Themes/default'
import { Kanban } from "./KanbanType"
import { openDB } from "idb/with-async-ittr"
import { Chapitre } from "./ChapitreType"
import useGenericStore from "./useGenericStore"
import usePm2 from "./usePm2"
import ExploitationPratiqueType from "./ExploitationPratiqueType"
import ExamenCliniqueType from "./ExamenClinqueType"
import NutritiveSolution from "./NutritiveSolutionType"
import InvoiceClient from "./InvoiceClientType"
import CoursType from "./CoursType"
import ThematicType from "./ThematicType"
import IntervenantType from "./IntervenantType"
import MedicamentType from "./MedicamentType"

const db = openDB<DBInterface>('AlephIt', 9, {
    upgrade(db, oldVersion) {
        if (oldVersion < 1) {
            db.createObjectStore('auth', {
                keyPath: 'id',
            });
            db.createObjectStore('chapitres', {
                keyPath: 'id',
            });
            db.createObjectStore('kanbans', {
                keyPath: 'id',
            });
        }
        if (oldVersion < 2) {
            db.createObjectStore('nutritive-solutions', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 3) {
            db.createObjectStore('invoices', {
                keyPath: 'id'
            })
            db.createObjectStore('invoice-clients', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 4) {
            db.createObjectStore('examens-cliniques', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 5) {
            db.createObjectStore('exploitations-pratiques', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 6) {
            db.createObjectStore('cours', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 7) {
            db.createObjectStore('thematics', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 8) {
            db.createObjectStore('intervenants', {
                keyPath: 'id'
            })
        }
        if (oldVersion < 9) {
            db.createObjectStore('medicaments', {
                keyPath: 'id'
            })
        }
    },
});

const dbApi: dbApiType = {
    getAll: async (store) => (await db).getAll(store),
    get: async (store, id) => (await db).get(store, id),
    post: async (store, el) => (await db).put(store, el),
    clear: async (store) => (await db).clear(store)
}

const useStore: useStoreType = () => {
    const authValue = useAuth(dbApi)
    const { apps, dispatch: appsDispatch } = usePm2(authValue.tokens, authValue.fetch)
    const kanbanValue = useGenericStore<Kanban>('kanbans', authValue.fetch, dbApi)
    const chapitreValue = useGenericStore<Chapitre>('chapitres', authValue.fetch, dbApi)
    const invoicesValue = useGenericStore<any>('invoices', authValue.fetch, dbApi)
    const invoiceClientsValue = useGenericStore<InvoiceClient>('invoice-clients', authValue.fetch, dbApi)
    const solutionNutritivesValue = useGenericStore<NutritiveSolution>('nutritive-solutions', authValue.fetch, dbApi)
    const examensCliniquesValue = useGenericStore<ExamenCliniqueType>('examens-cliniques', authValue.fetch, dbApi)
    const exploitationsPratiques = useGenericStore<ExploitationPratiqueType>('exploitations-pratiques', authValue.fetch, dbApi)
    const coursValue = useGenericStore<CoursType>('cours', authValue.fetch, dbApi)
    const thematicsValue = useGenericStore<ThematicType>('thematics', authValue.fetch, dbApi)
    const intervenantsValue = useGenericStore<IntervenantType>('intervenants', authValue.fetch, dbApi)
    const medicamentsValue = useGenericStore<MedicamentType>('medicaments', authValue.fetch, dbApi)


    const [stores, setStores] = React.useState<Stores>({
        chapitres: undefined,
        cours: undefined,
        examensCliniques: undefined,
        exploitationsPratiques: undefined,
        invoices: undefined,
        invoiceClients: undefined,
        kanbans: undefined,
        medicaments: undefined,
        nutritiveSolutions: undefined,
        thematics: undefined,
        intervenants: undefined,
        user: null,
    })


    const dispatch: useStoreDispatchType = React.useMemo(() => ({
        auth: authValue.dispatch,
        apps: appsDispatch,
        chapitres: chapitreValue.dispatch,
        cours: coursValue.dispatch,
        examensCliniques: examensCliniquesValue.dispatch,
        exploitationsPratiques: exploitationsPratiques.dispatch,
        invoices: invoicesValue.dispatch,
        invoiceClients: invoiceClientsValue.dispatch,
        intervenants: intervenantsValue.dispatch,
        kanbans: kanbanValue.dispatch,
        medicaments: medicamentsValue.dispatch,
        nutritiveSolutions: solutionNutritivesValue.dispatch,
        thematics: thematicsValue.dispatch,

    }), [
        authValue,
        chapitreValue.dispatch,
        kanbanValue.dispatch,
        appsDispatch
    ])

    React.useEffect(() => {
        setStores(prevStore => ({
            ...prevStore,
            chapitres: chapitreValue.store,
            cours: coursValue.store,
            examensCliniques: examensCliniquesValue.store,
            exploitationsPratiques: exploitationsPratiques.store,
            invoices: invoicesValue.store,
            invoiceClients: invoiceClientsValue.store,
            intervenants: intervenantsValue.store,
            kanbans: kanbanValue.store,
            medicaments: medicamentsValue.store,
            nutritiveSolutions: solutionNutritivesValue.store,
            thematics: thematicsValue.store,
            user: authValue.user,
        }))
    }, [
        authValue.user,
        chapitreValue.store,
        coursValue.store,
        examensCliniquesValue.store,
        exploitationsPratiques.store,
        invoicesValue.store,
        invoiceClientsValue.store,
        intervenantsValue.store,
        kanbanValue.store,
        medicamentsValue.store,
        solutionNutritivesValue.store,
        thematicsValue.store
    ])

    return {
        ...stores, dispatch, fetch: authValue.fetch, theme: defaultTheme, apps
    }
}

export default useStore