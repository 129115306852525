import { Box, Typography } from '@mui/material'
import React from 'react'

const EmptyFile = () => {
    return (
        <Box>
            <Typography variant="caption" color="initial">Aucune image...</Typography>
        </Box>
    )
}

export default EmptyFile