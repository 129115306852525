import { Add, Delete, Edit, Receipt, Visibility } from '@mui/icons-material'
import Page from '../../../Components/PageInterface'
import useInvoiceEdit, { InvoiceEditContext } from './useInvoiceEdit'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import ClientSelector from './ClientSelector'
import InvoiceTitle from './InvoiceTitle'
import InputField from '../../../Components/InputField'
import CardTable from '../../../Components/CardTable/CardTable'
import { useParams } from 'react-router-dom'
import InvoiceModalItemEdit from './InvoiceModalItemEdit'
import InvoicePdfRender from './InvoicePdfRender'

const InvoiceCreate = () => {
    const { id } = useParams<{ id: string }>()
    const contextValue = useInvoiceEdit(id!)
    return (
        <InvoiceEditContext.Provider value={contextValue}>
            <Page
                title={'Création d\'une facture'}
                breadcrumb={[
                    { url: '/aleph/invoices', name: 'Factures', icon: Receipt },
                    { url: '', name: 'Nouvelle facture', icon: Add },
                ]}
                rightPlace={<Button onClick={() => contextValue.dispatch.showInvoicePdf()}>
                    <Visibility /> Voir la facture
                </Button>}
                children={<Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowY: 'auto',
                    p: 2,
                    gap: 2
                }}>{contextValue.invoice && <>
                    <InvoiceModalItemEdit />
                    <InvoicePdfRender />
                    <ClientSelector />
                    {contextValue.invoice.client &&
                        <>
                            <InvoiceTitle />
                            <InputField fullWidth type={'number'} label={'Numéros de facture'} value={contextValue.invoice.invoiceNumber ?? ''} onChange={({ target: { value } }) => contextValue.dispatch.update({ invoiceNumber: +value })} />

                            {contextValue.invoice.title && <Box display={'flex'} gap={2}>
                                <InputField fullWidth type={'date'} label={'Date d\'émission'} value={contextValue.invoice.emitedAt?.split('T')[0] ?? ''} onChange={({ target: { value } }) => contextValue.dispatch.update({ emitedAt: value + 'T00:00:00.000Z' })} />
                                <InputField fullWidth type={'date'} label={'Date de paiement'} value={contextValue.invoice.paidAt?.split('T')[0] ?? ''} onChange={({ target: { value } }) => contextValue.dispatch.update({ paidAt: value + 'T00:00:00.000Z' })} />
                            </Box>}
                        </>}
                    <CardTable
                        columns={[
                            { field: 'title', header: { label: 'Désignation' } },
                            { field: 'quota', header: { label: 'Quantité', style: { width: '1px' } } },
                            { field: 'unit', header: { label: 'Unité', style: { width: '1px' } } },
                            { field: 'unitPrice', header: { label: 'Prix unitaire', style: { width: '1px' } }, body: ({ unitPrice }) => new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(unitPrice) },
                            { field: 'tva', header: { label: 'TVA', style: { width: '1px' } } },
                            { field: 'id', header: { label: 'Montant HT', style: { width: '1px' } }, body: ({ quota, unitPrice, tva }) => new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format((quota && unitPrice && tva && quota * unitPrice * (tva / 100)) || (quota && unitPrice && quota * unitPrice) || 0) }
                        ]}
                        buttons={[
                            { isHeader: true, onClick: contextValue.dispatch.addItem, icon: Add, rounded: true },
                            { onClick: (item) => contextValue.dispatch.editItem(item), icon: Edit, rounded: true },
                            { onClick: (item) => contextValue.dispatch.deleteItem(item.id), icon: Delete, rounded: true },
                        ]}
                        datas={contextValue.invoice.items ?? []}
                        noCard={false}
                    />
                    <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Box></Box>
                        <Box>
                            <Card >
                                <CardContent  >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography>Total HT</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>{
                                                contextValue.invoice.items.length > 0 && new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(contextValue.invoice.items.map(({ quota, unitPrice, tva }) => (quota && unitPrice && quota * unitPrice) || 0).reduce((prev, curr) => prev + curr))
                                            }</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography>TVA 20%</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>{new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(0)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: -2 }}>

                                        <Box sx={{ pr: 4 }}>
                                            <Typography>Total TTC</Typography>
                                        </Box>
                                        <Box >
                                            <Typography>{
                                                contextValue.invoice.items.length > 0 && new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(contextValue.invoice.items.map(({ quota, unitPrice, tva }) => (quota && unitPrice && quota * unitPrice) || 0).reduce((prev, curr) => prev + curr))}</Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>

                </>}
                </Box>}
                loading={!contextValue.invoice}
                fullPage
            />
        </InvoiceEditContext.Provider>
    )
}

export default InvoiceCreate