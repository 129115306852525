import { ChevronRight } from '@mui/icons-material'
import { IconButton, TableCell } from '@mui/material'
import React from 'react'
import { TableContext } from '../useTable'

interface TableCollapserButtonProps {
    id: string
}
const TableCollapserButton = ({ id }: TableCollapserButtonProps) => {
    const { dispatch, collapsed } = React.useContext(TableContext)
    const isCollapsed = collapsed.includes(id)
    return (
        <TableCell sx={{ width: '1px' }}>
            <IconButton onClick={() => dispatch.toggleCollapse(id)}>
                <ChevronRight sx={{ transform: isCollapsed ? 'rotate(90DEG)' : undefined }} />
            </IconButton>
        </TableCell>
    )
}

export default TableCollapserButton