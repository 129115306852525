import { VolumeOff, VolumeMute, VolumeDown, VolumeUp } from '@mui/icons-material'
import { Box, IconButton, Slider } from '@mui/material'
import { VideoPlayerContext } from '../useVideoPlayer'
import React from 'react'

const Sound = () => {
    const { volume, dispatch } = React.useContext(VideoPlayerContext)
    const [visible, setVisible] = React.useState(false)
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null
        const handleMouse = () => {
            if (timer) clearTimeout(timer)
            setVisible(true)
            timer = setTimeout(() => {
                setVisible(false)
            }, 2000)
        }
        ref.current?.addEventListener('mousemove', handleMouse)
        return () => {
            ref.current?.removeEventListener('mousemove', handleMouse)
            if (timer) clearTimeout(timer)
        }
    }, [ref.current])

    if (volume === undefined) return < VolumeOff />

    return (
        <Box
            ref={ref}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
                '& .MuiSlider-root': {
                    mr: 2,
                    opacity: 0,
                    width: 0,
                    '&[data-show="true"]': {
                        opacity: 1,
                        width: 75,
                        animation: 'show 0.4s ease-out',
                    },
                },

                '@keyframes show': {
                    '0%': {
                        display: 'none',
                        opacity: 0
                    },
                    '1%': {
                        display: 'block',
                        opacity: 0
                    },
                    '100%': {
                        display: 'block',
                        opacity: 1
                    }
                },
            }}
        >
            <IconButton color='inherit' onClick={() => dispatch.setVolume(volume > 0 ? 0 : 1)}>
                {
                    volume === 0 ? <VolumeOff />
                        : volume < 0.33 ? <VolumeMute />
                            : volume < 0.66 ? <VolumeDown />
                                : <VolumeUp />
                }
            </IconButton>
            <Slider data-show={visible} min={0} max={100} onChange={(_, value) => dispatch.setVolume((value as number) / 100)} />
        </Box>
    )
}

export default Sound