import { Box, Button } from '@mui/material'
import React from 'react'

interface SliderProps {
    images: string[]
    controls?: boolean
    active?: number
}
const Slider = ({ images, controls = true, active: controledActive = 0 }: SliderProps) => {
    const [active, setActive] = React.useState<number>(controledActive)
    const handlePrev = () => setActive(prev => prev - 1)
    const handleNext = () => setActive(prev => prev + 1)

    React.useEffect(() => {
        setActive(controledActive)
    }, [controledActive])
    return (
        <Box sx={{
            width: "100%",
            // maxHeight: '40vh',
            height: '100%',
            position: 'relative'
        }}
            onScroll={console.log}
        >
            {controls &&
                <Box sx={{
                    position: 'absolute'
                }}>
                    <Button onClick={handlePrev} disabled={active === 0}>next</Button>
                    <Button onClick={handleNext} disabled={active === images.length - 1}>prev</Button>
                </Box>}
            <Box sx={{
                // height: '50vh',
                height: '100%',
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                '& div': {
                    minWidth: 0,
                    visibility: 'hidden',
                    '&[role="active"]': {
                        visibility: 'visible',
                        minWidth: '100%',
                        textAlign: 'center'
                    },
                    '& img': {
                        width: '100%',
                        aspectRatio: '16/9'
                    }
                }
            }}>
                {images.map((src, index) => <Box key={src} role={index === active ? 'active' : undefined}><img src={src} /></Box>)}
            </Box>
            <Box></Box>
        </Box>
    )
}

export default Slider