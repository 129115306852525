import React from 'react'
import { useParams } from 'react-router-dom'
import useEditEc, { EditEcContext } from './useEditEc'
import Page from '../../../../Components/PageInterface/Page'
import EPDSIList from '../../EPDSIList'
import { MenuBook, Person, Edit as EditIcon } from '@mui/icons-material'
import ECSetting from './Settings/ECSetting'
import ECAnamnese from './Anamnese/ECAnamnese'
import Processus from './Processus/Processus'
import { Button, Link } from '@mui/material'
import { AppContext } from '../../../../App'
import Eval from './Eval/Eval'
import { BreadCrumbType } from '../../../../Components/PageInterface'
import Logo from '../../../../assets/epdsi.png'

const Edit = () => {

    const { fetch } = React.useContext(AppContext)

    const { id } = useParams<{ id: string }>()
    const contextValue = useEditEc(id!)

    const handleClick = () => fetch(`/api/admin/examens-cliniques/${id}/download`, 'GET').then(buffer => {
        const el = document.createElement('A')
        el.setAttribute('href', `data:application/octet-stream;base64,${buffer.buffer}`)
        el.setAttribute('download', `Enseignement clinique du ${new Intl.DateTimeFormat('fr-CH').format(new Date(contextValue.ec?.date ?? ''))}.docx`)
        el.click()
    })

    return (
        <EditEcContext.Provider value={contextValue}>
            <Page
                title={contextValue.ec ? `Enseignement clinique du ${new Intl.DateTimeFormat('fr-FR').format(new Date(contextValue.ec.date))}  —  ${contextValue.ec.name}` : ``}
                subtitle={contextValue.ec ? `Examinateur : ${EPDSIList.examinateurs.find(e => e.id === contextValue.ec?.examinateur)?.firstname} ${EPDSIList.examinateurs.find(e => e.id === contextValue.ec?.examinateur)?.lastname}` : undefined}
                loading={!contextValue.ec}
                rightPlace={<Button onClick={handleClick}>Télécharger</Button>}
                breadcrumb={[
                    { url: '/epdsi', name: 'EPD SI', src: Logo },
                    { url: '/epdsi/ecs', name: 'Enseignements cliniques', letters: 'EC' },
                    { url: `/epdsi/ecs/${id}`, name: contextValue.ec ? `Rapport d'enseignement clinique du ${new Intl.DateTimeFormat('fr-FR').format(new Date(contextValue.ec.date))}  —  ${contextValue.ec.name}` : ``, icon: EditIcon },
                ]}
                fullPage
                tabs={[
                    { icon: Person, label: 'Généralités', component: <ECSetting /> },
                    { icon: Person, label: 'Anamnèse', component: <ECAnamnese /> },
                    { icon: Person, label: 'Processus 1', component: <Processus processus={1} /> },
                    { icon: Person, label: 'Processus 2', component: <Processus processus={2} /> },
                    { icon: Person, label: 'Processus 3', component: <Processus processus={3} /> },
                    { icon: Person, label: 'Processus 4', component: <Processus processus={4} /> },
                    { icon: Person, label: 'Evaluation', component: <Eval /> },
                ]}
            />
        </EditEcContext.Provider>
    )
}

export default Edit