import { Box, Typography } from '@mui/material';
import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer';

const Time = () => {
    const { currentTime, totalTime } = React.useContext(VideoPlayerContext)

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedTime = `${hours ? `${hours}:` : ''}${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        return formattedTime;
    }

    return (
        currentTime !== undefined && totalTime !== undefined
            ? <Box display={'flex'} gap={0.5}>
                <Typography>
                    {formatTime(currentTime)}
                </Typography>
                <Typography>/</Typography>
                <Typography>
                    {formatTime(totalTime)}
                </Typography>
            </Box>
            : <></>
    )
}

export default Time