import Page from '../../../../Components/PageInterface/Page'

import ChuvIcon from '../../../../assets/favicon.ico'
import { useParams } from 'react-router-dom'
import { Edit, Restaurant } from '@mui/icons-material'
import useNutritiveSolution, { NutritiveSolutionContext } from './useNutritiveSolution'
import SettingsTab from './Settings/SettingsTab'
import CompositionTab from './Composition/CompositionTab'


const EditNutritiveSolution = () => {
    const { id } = useParams<{ id: string }>()
    const contextValue = useNutritiveSolution(id!)


    return (
        <NutritiveSolutionContext.Provider value={contextValue}>

            <Page
                title={contextValue.nutritiveSolution?.name ?? 'Solution nutritive'}
                subtitle={contextValue.nutritiveSolution?.type ?? 'Solution nutritive'}
                breadcrumb={[
                    { url: '/chuv', name: 'CHUV', src: ChuvIcon },
                    { url: '/chuv/nutritive-solutions', name: 'Solutions nutritives', icon: Restaurant },
                    { url: `/chuv/nutritive-solutions/${id}`, name: contextValue.nutritiveSolution?.name ?? 'Edition', icon: Edit }
                ]}
                loading={!contextValue.nutritiveSolution}
                tabs={[
                    { label: 'Généralités', component: <SettingsTab /> },
                    { label: 'Composition', component: <CompositionTab /> }
                ]}
                fullPage
            />
        </NutritiveSolutionContext.Provider>
    )
}

export default EditNutritiveSolution