import { Box, Grid, ListItemText, MenuItem } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { EditEcContext } from '../useEditEc'
import EPDSIList from '../../../EPDSIList'

const ECSetting = () => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    const [name, setName] = React.useState(ec?.name ?? '')

    React.useEffect(() => {
        setName(ec?.name ?? '')
    }, [ec])
    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'Date de \'enseignement clinique'}
                        type='date'
                        value={ec?.date.split('T')[0]}
                        onChange={dispatch.changeDate}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'Examinateur'}
                        value={ec?.examinateur ?? ''}
                        onChange={dispatch.changeExaminateur}
                        select
                        fullWidth
                    >
                        {EPDSIList.examinateurs.sort((a, b) => (`${a.firstname} ${a.lastname}`).toLocaleLowerCase() < (`${a.firstname} ${a.lastname}`).toLocaleLowerCase() ? -1 : 1).map((exam) => <MenuItem key={exam.id} value={exam.id}>{exam.firstname} {exam.lastname}</MenuItem>)}
                    </InputField>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'Période'}
                        value={ec?.period}
                        onChange={dispatch.changePeriod}
                        select
                        fullWidth
                    >
                        <MenuItem value={1}>Période 1</MenuItem>
                        <MenuItem value={2}>Période 2</MenuItem>
                        <MenuItem value={3}>Période 3</MenuItem>
                    </InputField>
                </Grid>
                <Grid item xs={12} md={8}>
                    <InputField
                        label={'Nom du patient'}
                        value={name}
                        onChange={({ target: { value } }) => setName(value)}
                        onBlur={({ target: { value } }) => dispatch.changeName(value)}
                        /* @ts-ignore */
                        onKeyUp={({ key, target: { value } }) => key === 'Enter' && dispatch.changeName(value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputField
                        label={'Niveaux de complexité de la situation de soins'}
                        value={ec?.complexity}
                        onChange={dispatch.changeComplexity}
                        select
                        fullWidth
                    >
                        <MenuItem value={1}>Patient stable</MenuItem>
                        <MenuItem value={2}>Patient instable</MenuItem>
                    </InputField>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ECSetting