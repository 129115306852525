import { useSortable } from "@dnd-kit/sortable";
import { QuestionType, ResponseType } from "../../../../../Stores/CoursType";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle, Check, Close, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import InputField from "../../../../../Components/InputField";

interface QuestionItemProps {
    reponse: ResponseType
    setQuestion: React.Dispatch<React.SetStateAction<QuestionType | undefined>>
}
const QuestionItem = ({
    reponse,
    setQuestion
}: QuestionItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: reponse.id });


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 0,
    };

    const handleChangeReponse = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, value }) : el)
        }))
    }

    const handleCorrect = () => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: true }) : el)
        }))
    }
    const handleInCorrect = () => {
        setQuestion(prev => ({
            ...prev!,
            reponses: prev!.reponses.map(el => el.id === reponse.id ? ({ ...el, correct: false }) : el)
        }))
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
            style={style}
            ref={setNodeRef}
            {...attributes}
            sx={{
                '& .hoverable': { visibility: 'hidden' },
                '&:hover .hoverable': { visibility: 'visible' },
                '& .hoverable[data-byPass="true"]': { visibility: 'visible' },
            }}>
            <Box className={'hoverable'}>
                <DragHandle {...listeners} />
            </Box>
            <Box flexGrow={1}>
                <InputField
                    label="Enoncé de la reponse"
                    value={reponse.value}
                    onChange={handleChangeReponse}
                    multiline
                    fullWidth
                />
            </Box>
            <Box >
                <IconButton className={'hoverable'} data-byPass={reponse.correct} color={reponse.correct ? 'primary' : undefined} onClick={handleCorrect}>
                    <Check />
                </IconButton>
                <IconButton className={'hoverable'} data-byPass={!reponse.correct} color={!reponse.correct ? 'secondary' : undefined} onClick={handleInCorrect}>
                    <Close />
                </IconButton>
            </Box>
            <Box>
                <IconButton color={'error'} className={'hoverable'} onClick={() => setQuestion(prev => ({ ...prev!, reponses: prev!.reponses.filter(el => el.id !== reponse.id) }))}>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    )
}

export default QuestionItem