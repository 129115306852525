import React from "react";
import { ChevronLeft, Dashboard, LocalLibrary, MenuBook, QuizRounded, Receipt, Restaurant, ViewKanban } from "@mui/icons-material";
import { Avatar, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Theme, Toolbar, Tooltip, Typography, styled, useTheme } from "@mui/material";
import UiContext from "./UiContext";
import { Link, NavLink, useLocation } from "react-router-dom";

import ChuvLogo from '../../assets/favicon.ico'
import EPDSILogo from '../../assets/epdsi.png'
import InputField from "../InputField";
import LogoAleph from "../LogoAleph";

interface DrawerProps extends MuiDrawerProps {
    theme: Theme
}

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: DrawerProps) => ({
        '& .MuiDrawer-paper': {
            position: 'absolute',
            height: '100vh',
            whiteSpace: 'nowrap',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 300
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7)
                },
            }),
        },
    }),
);

const DrawerAvatar = styled(Avatar)(
    (() => ({
        width: 24,
        height: 24
    }))
)

interface ItemProps {
    url: string,
    icon?: React.ElementType
    iconColor?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    src?: string
    letters?: string
    name: string
}
const Item = ({ url, icon: Icon, src, name, iconColor, letters }: ItemProps) => {
    const location = useLocation()
    const isActive = location.pathname.startsWith(url)
    return (
        <ListItemButton component={Link} to={url} className={isActive ? 'Mui-selected' : undefined}>
            {Icon && <ListItemIcon>
                <Icon color={iconColor} />
            </ListItemIcon>}
            {src && <ListItemAvatar>
                <DrawerAvatar src={src} />
            </ListItemAvatar>}
            {letters && <ListItemAvatar>
                <DrawerAvatar children={<Typography variant={'caption'}>{letters}</Typography>} />
            </ListItemAvatar>}
            <ListItemText primary={name} />
        </ListItemButton>
    )
}

interface GroupItemProps extends ItemProps {
    items: ItemProps[]
}
const GroupItem = ({ url, icon, src, name, items }: GroupItemProps) => {
    const location = useLocation()
    const isActive = location.pathname.startsWith(url) ?? false
    return (
        <>
            <Item url={url} icon={icon} src={src} name={name} />
            <Collapse in={isActive}>
                <Divider />
                {items.map(item => <Item {...item} key={item.url} />)}
            </Collapse>
        </>
    )
}


const Drawer = ({ children }: React.PropsWithChildren) => {
    const { open, toggleOpen } = React.useContext(UiContext)
    const theme = useTheme()
    return (
        <StyledDrawer open={open} theme={theme} variant="permanent">
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleOpen}>
                    <ChevronLeft />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <Item url={"/"} name={"Dashboard"} icon={Dashboard} />
                <Divider />
                <GroupItem items={[
                    { url: '/aleph/kanban', name: 'Kanban', icon: ViewKanban },
                    { url: '/aleph/invoices', name: 'Facturation', icon: Receipt },
                ]} url={"/aleph"} name={"AlephIT"} icon={LogoAleph} iconColor="secondary" />
                <Divider />
                <GroupItem items={[
                    { url: '/chuv/guide-de-poche', name: 'Guide de poche', icon: MenuBook },
                    { url: '/chuv/nutritive-solutions', name: 'Solution de nutrition', icon: Restaurant },
                ]} url={"/chuv"} name={"CHUV"} src={ChuvLogo} />
                <Divider />
                <GroupItem items={[
                    { url: '/epdsi/cours', name: 'Cours', icon: LocalLibrary },
                    { url: '/epdsi/quizz', name: 'Quizz', icon: QuizRounded },
                    { url: '/epdsi/ecs', name: 'Examens cliniques', letters: 'EC' },
                    { url: '/epdsi/eps', name: 'Exploitations de la pratique', letters: 'EP' },
                ]} url={"/epdsi"} name={"EPD SI"} src={EPDSILogo} />
            </List>
        </StyledDrawer>
    )
}

export default Drawer