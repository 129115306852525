import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

interface LogoAlephProps {
    fill?: string
    size?: number
    fontSize?: "small" | "inherit" | "large" | "medium"
    color?: SvgIconProps['color']
}
const LogoAleph = ({ fill = "#000000", size, color, fontSize }: LogoAlephProps) => {

    return (
        <SvgIcon sx={{ stroke: 'none', width: size, height: size }} viewBox="0 0 85 85" color={color} fontSize={fontSize}>
            <path
                d="M 41.03125,8.5625 L 41.03125,24.5 L 5.8125,41.40625 L 41.03125,59.125 L 41.03125,76.4375 L 48.96875,76.4375 L 48.96875,63.125 L 75.09375,76.25 L 79.1875,69.28125 L 48.96875,54.21875 L 48.96875,29.875 L 77.9375,16.0625 L 74.1875,8.5625 L 48.96875,20.6875 L 48.96875,8.5625 L 41.03125,8.5625 z M 41.03125,33.65625 L 41.03125,50.25 L 24.03125,41.78125 L 41.03125,33.65625 z"
                id="rect2453"
                style={{ stroke: 'none', width: size, height: size }}
            />
        </SvgIcon>
    )
}

export default LogoAleph