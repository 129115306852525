import { Box } from '@mui/material'
import React from 'react'
import { PageContext } from './Page'

const TabHeaderButton = () => {
    const { tabButton } = React.useContext(PageContext)
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                ml: 'auto'
            }}          >
            {tabButton}
        </Box>
    )
}

export default TabHeaderButton