import Page from '../../../Components/PageInterface/Page'
import ChuvIcon from '../../../assets/favicon.ico'
import { MenuBook, Edit as EditIcon, InsertDriveFile } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import useEditChapitre, { ChapitreContext } from './useEditChapitre'
import GeneralitesTab from './GeneralitesTab'
import ContentTab from './Content/ContentTab'
import SubChapters from './SubChapters'
import { BreadCrumbType } from '../../../Components/PageInterface'
import React from 'react'

const Edit = () => {
    const { id } = useParams<{ id: string }>()
    const contextValue = useEditChapitre(id!)

    return (
        <ChapitreContext.Provider value={contextValue}>
            <Page
                loading={!contextValue.chapitre}
                title={contextValue.chapitre ? contextValue.chapitre.name : 'Guide de poche'}
                subtitle={contextValue.chapitre ? 'Guide de poche' : 'Edition du chapitre'}
                breadcrumb={[
                    { url: '/chuv', name: 'CHUV', src: ChuvIcon },
                    { url: '/chuv/guide-de-poche', name: 'Guide de poche', icon: MenuBook },
                    (contextValue.chapitre?.parentId ? { url: `/chuv/guide-de-poche/${contextValue.chapitre.parentId}`, name: contextValue.parent ? contextValue.parent.name : contextValue.chapitre.parentId, icon: InsertDriveFile } : undefined),
                    { url: `/chuv/guide-de-poche/${id}`, name: contextValue.chapitre ? contextValue.chapitre.name : 'Edition du chapitre', icon: EditIcon },
                ].filter(b => b !== undefined) as BreadCrumbType[]}
                // children={<>linked</>}
                resetTab={id}
                tabs={[
                    { label: 'Généralités', component: <GeneralitesTab /> },
                    { label: 'Sous-chapitres', component: <SubChapters /> },
                    { label: 'Contenu', component: <ContentTab /> },
                ]}
                fileManagerConfig={{
                    uploadUrl: `/chapitre/${id}`,
                    files: contextValue.chapitre?.files ?? [],
                    addFile: contextValue.dispatch.addFile,
                    removeFile: contextValue.dispatch.removeFile
                }}
                fullPage />
        </ChapitreContext.Provider>
    )
}

export default Edit