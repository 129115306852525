import React from 'react'
import IPAS from '../../../../../Components/IPAS/IPAS'
import IPASValueType from '../../../../../Stores/IPASTypes'
import { UseEpEditContext } from '../useEpsEdit'
import { Box } from '@mui/material'

const EPIpas = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <IPAS ipasValue={ep!.ipas!} changeValue={dispatch.changeIpas} />
        </Box>
    )
}

export default EPIpas