import { Box, CardActionArea, CircularProgress } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from './useVideoPlayer'
import { PlayArrow, RestartAlt } from '@mui/icons-material'

const VideoOverflow = () => {
    const { status, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <CardActionArea
            sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
            }}
            onClick={
                status === 'paused' ? dispatch.play
                    : status === 'playing' ? dispatch.pause
                        : status === 'ended' ? dispatch.restart : undefined
            }
            disableRipple={status === 'loading'}
        >
            <Box sx={{
                background: 'rgba(0,0,0,0.5)',
                borderRadius: '100%'
            }}>
                {status === 'loading' && <CircularProgress color='secondary' size={100} />}
                {status === 'paused' && <PlayArrow color={'inherit'} sx={{ fontSize: 200 }} />}
                {status === 'ended' && <RestartAlt color={'inherit'} sx={{ fontSize: 200 }} />}
            </Box>

        </CardActionArea>
    )
}

export default VideoOverflow