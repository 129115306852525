import { Box } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'
import Progress from './Progress'
import PlayPauseButtons from './PlayPauseButtons'
import FullScreenButton from './FullScreenButton'
import Time from './Time'
import Sound from './Sound'
import Speed from './Speed'

const Toolbar = () => {
    const { showControls } = React.useContext(VideoPlayerContext)

    return (
        <Box
            className='toolbar'
            data-show={showControls}
            sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                zIndex: 2,
                pl: 2,
                pr: 2,
                alignItems: 'center',
            }}
        >
            <Progress />
            <PlayPauseButtons />
            <Sound />
            <Time />
            <Box ml={'auto'} />
            <Speed />
            <FullScreenButton />

        </Box>
    )
}

export default Toolbar