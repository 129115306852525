import { Close } from '@mui/icons-material'
import { Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Menu, Typography } from '@mui/material'
import React from 'react'

const groups = [
    {
        id: 1,
        name: 'Groupe 1 adulte',
        color: '#254069',
        colorText: 'white',
        users: [
            {
                id: '8822a9f9-e8a0-431e-a696-2fae1fd7d4a7',
                lastname: 'Bovey',
                firstname: 'Nora',
            },
            {
                id: '79fb7139-f2a5-4c18-a232-42154cc942fc',
                lastname: 'Dicandia',
                firstname: 'Marco',
            },
            {
                id: '37c7f61c-9f7d-4da7-a87d-8f7d405372a1',
                lastname: 'Gonnet',
                firstname: 'Adrienne',
            },
            {
                id: 'c1b5d2d2-d951-4add-8ec8-b527a4810ba5',
                lastname: 'Guedes Teixeira',
                firstname: 'Catia',
            },
            {
                id: '184e7426-3943-4619-be10-74bc5e49805d',
                lastname: 'Jari',
                firstname: 'Kalid',
            },
            {
                id: '92d7f5f6-f456-463f-a2ee-2b174540cd17',
                lastname: 'Ramos Ribeiro',
                firstname: 'Pedro',
            },
            {
                id: 'b6f2078a-9c9c-4980-b9b9-c13476ce6e0e',
                lastname: 'Rossopoulos',
                firstname: 'Marina',
            },
            {
                id: '0961fd75-e1e8-40ba-8cc6-5545fc78594c',
                lastname: 'Ruaux',
                firstname: 'Joséphine',
            },
            {
                id: 'c2fab099-5b89-4757-8965-e528be2b26c9',
                lastname: 'Stienne',
                firstname: 'Noémie',
            },
            {
                id: '856dc61a-9f82-400b-9f74-f6c1b697cac6',
                lastname: 'Barras',
                firstname: 'Debora',
            },
            {
                id: '5273386b-0417-44de-a8ef-a7af83cdcb90',
                lastname: 'Hassaenia',
                firstname: 'Sara',
            },
            {
                id: '9eb13cb5-be8d-4d86-989c-fc1378983420',
                lastname: 'Mignot',
                firstname: 'Carmen',
            },
            {
                id: '9c56b0a0-3825-4ab2-b685-7e3d92f8a00c',
                lastname: 'Morand',
                firstname: 'Elodie',
            },
            {
                id: 'd2f44dba-531a-4be0-aa00-4dd06dedc5af',
                lastname: 'Nault',
                firstname: 'Mathilde',
            },
            {
                id: 'c14b1a1f-2373-4152-b311-1ca32f555aa3',
                lastname: 'Rekik',
                firstname: 'Mohamed',
            },
            {
                id: '80fd5588-b476-4d14-876e-bdacc8d534b4',
                lastname: 'Roux',
                firstname: 'Emilien',
            },
        ]
    },
    {
        id: 2,
        name: 'Groupe 2 adulte',
        color: '#55abe5',
        colorText: 'white',
        users: [

            {
                id: '79013e08-a586-4707-88cc-6998683c8278',
                lastname: 'Da Silva Ferraz',
                firstname: 'Marlène',
            },
            {
                id: 'c339d9fc-179e-406a-b20c-f486416ec7da',
                lastname: 'Garcia',
                firstname: 'Salomé',
            },
            {
                id: 'f7b223c8-ef19-43a5-9a34-f75353e5172d',
                lastname: 'Gruaz',
                firstname: 'Manon',
            },
            {
                id: '33f30d3c-47c0-4003-a3fe-1df545899c34',
                lastname: 'Jaquet',
                firstname: 'Anthony',
            },
            {
                id: '457bb9cd-ac16-43b8-89e5-1b167fa672de',
                lastname: 'Lucas',
                firstname: 'Pierre',
            },
            {
                id: '0f7cde4a-fe42-452f-9494-6fdac5ab8c58',
                lastname: 'Rossat',
                firstname: 'Audrey',
            },
            {
                id: 'aa65c5cb-cfeb-4d7b-9676-d49e3db9130c',
                lastname: 'Rousset',
                firstname: 'Mathilde',
            },
            {
                id: '31c326f8-9b5f-4a2e-927e-bd35d76819d6',
                lastname: 'Sizaire',
                firstname: 'Geoffrey',
            },
            {
                id: '35d4a805-8e1e-47ba-bc58-77a369d260ea',
                lastname: 'Wadoux',
                firstname: 'Marie',
            },
            {
                id: '73a6d226-d9ff-4284-a979-2cba1220284e',
                lastname: 'Burcham',
                firstname: 'Matthew',
            },
            {
                id: '2756cb88-e80e-4779-9447-80c97626c085',
                lastname: 'Meunier',
                firstname: 'Marie',
            },
            {
                id: '680b0559-263e-4384-97f1-32f23d436b4f',
                lastname: 'Petrovska',
                firstname: 'Radost',
            },
            {
                id: '3289e444-ac07-4590-aef7-5ca3f914b285',
                lastname: 'Straforelli',
                firstname: 'Deborah',
            },
            {
                id: 'b4fc4da4-5da4-4eb7-b345-2407e7184971',
                lastname: 'Tomas Domingues',
                firstname: 'Raquel',
            },
            {
                id: '9804ad91-e81b-4254-9ae7-b870446c3cc2',
                lastname: 'Van de Laer',
                firstname: 'Tom',
            },
            {
                id: '8258abb9-dc96-454b-aa52-cb8cbea02acf',
                lastname: 'Van de Velde',
                firstname: 'Frederic',
            }
        ]
    },
    {
        id: 1,
        name: 'Groupe 3 pédiatrie',
        color: '#fef143',
        users: [

            {
                id: '84b2dd21-d1d3-4bcc-9c4c-17f21135a316',
                lastname: 'Chaumarel',
                firstname: 'Louise',
            },
            {
                id: 'a2884977-f19d-411d-ac13-36e2550f669f',
                lastname: 'Cuche',
                firstname: 'Tino',
            },
            {
                id: 'f3f6a2de-bfa5-4002-b9ca-f184cae8c875',
                lastname: "D'Amico",
                firstname: 'Lara',
            },
            {
                id: '508f512b-c1ea-4360-b468-8fcbcda73c10',
                lastname: 'Habran',
                firstname: 'Anais',
            },
            {
                id: '5937f359-556c-4c95-8c21-bb5c882af5de',
                lastname: 'Monnet',
                firstname: 'Jean-Marie',
            },
            {
                id: '4822c939-5821-4808-96cd-07314d15be5c',
                lastname: 'Paratore',
                firstname: 'Lorenzo',
            },
            {
                id: '72c1ca61-19aa-45a9-aa33-82c58a19d702',
                lastname: 'Penon',
                firstname: 'Lee-Roy',
            },
            {
                id: 'a30f863b-c8a9-4bc4-ad47-daa4abd3170b',
                lastname: 'Soares Domingues',
                firstname: 'Beatriz',
            },
            {
                id: '1a3d726f-a69f-4734-9ca7-465a72621eb7',
                lastname: 'Tschopp',
                firstname: 'Maeva',
            }
        ]
    }
]

const Groupes = () => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)



    const handleClose = () => setAnchor(null)
    return (
        <React.Fragment>
            <Button onClick={({ currentTarget }) => setAnchor(currentTarget)}>Groupes</Button>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={handleClose}>
                <Box >

                    <Box display={'flex'} flexDirection={'row'} gap={2}>
                        {groups.map(group =>
                            <Box key={group.id} sx={{ backgroundColor: group.color, color: group.colorText ?? undefined }}>
                                <List dense>
                                    <ListSubheader sx={{ backgroundColor: group.color, color: group.colorText ?? undefined }}>
                                        {group.name}
                                    </ListSubheader>
                                    <Divider />
                                    {group.users.map(u =>
                                        <ListItem key={u.lastname}>
                                            <ListItemAvatar>
                                                <Avatar />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${u.lastname} ${u.firstname}`} />
                                        </ListItem>)}
                                </List>
                            </Box>)}
                        {/* <Box>
                        <List>
                            {[
                                {firstname: 'Nora', lastname: 'Bovey'}
                            ].map(u => <ListItem key={u.lastname}>
                                <ListItemText primary={`${u.lastname} ${u.firstname}`} />
                            </ListItem>)}
                           
                        </List>
                    </Box> */}
                    </Box>
                </Box>
            </Menu>
        </React.Fragment>
    )
}

export default Groupes