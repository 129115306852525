import { TabPanel } from '@mui/lab'
import React from 'react'
import InputField from '../../../InputField'
import { KanbanContext } from '../../useKanban'
import { Box, Button, Card, CardContent, Checkbox, Divider, IconButton, LinearProgress, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { CheckList, CheckListElement } from '../../../../Stores/KanbanType'
import LabelInput from '../../../LabelInput'

const CheckListTab = () => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    return (
        <TabPanel value='checklist'>
            <Box display={'flex'} flexDirection={'column'} gap={4}>
                {editedCard?.cl?.map(cl => <CheckListBox key={cl.id} checkList={cl} />)}
                <Button variant='outlined' onClick={dispatch.addCheckList}><Add />  Ajouter</Button>
            </Box>
        </TabPanel>
    )
}

interface CheckListProps {
    checkList: CheckList
}
const CheckListBox = ({ checkList }: CheckListProps) => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    const handleChange = (partial: Partial<CheckList>) => dispatch.updateCheckList(checkList.id, partial)
    const handleDelete = () => dispatch.removeCheckList(checkList.id)

    const percent = Math.round(100 * checkList.els.filter(el => el.status).length / checkList.els.length)
    return (
        <Card variant='outlined'>
            <CardContent>
                <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} gap={2}>
                    <Box flexGrow={1}>
                        <LabelInput value={checkList.name} onChange={(name) => handleChange({ name })} />
                    </Box>
                    <IconButton size='small' onClick={handleDelete} color='error'>
                        <Delete fontSize='small' />
                    </IconButton>
                </Box>
                {!isNaN(percent) &&
                    <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} mt={2} gap={1}>
                        <LinearProgress variant='determinate' value={percent} sx={{ flexGrow: 1 }} />
                        <Typography>{percent}%</Typography>
                    </Box>}
            </CardContent>
            <Divider />
            {checkList.els?.length > 0 &&
                <CardContent>
                    <Box display={'flex'} flexDirection={'column'}>
                        {checkList.els.map(task => <TaskItem key={task.id} task={task} />)}
                    </Box>
                </CardContent>}
            {checkList.els?.length > 0 && <Divider />}
            <CardContent>
                <CheckListElementAdder id={checkList.id} />
            </CardContent>
        </Card>
    )
}

interface CheckListElementAdderProps {
    id: CheckList['id']
}
const CheckListElementAdder = ({ id }: CheckListElementAdderProps) => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    const [createMode, setCreateMode] = React.useState<boolean>(false)
    const [value, setValue] = React.useState<string>('')
    const handleCreateMode = () => setCreateMode(true)
    const handleExitCreateMode = () => setCreateMode(false)
    const handleCreate = () => {
        handleExitCreateMode()
        dispatch.addCheckListElement(id, value)
    }

    React.useEffect(() => {
        setValue('')
    }, [createMode])
    return (
        <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} gap={1}>
            {!createMode && <Button size='small' onClick={handleCreateMode}>
                <Add fontSize='small' />  Item
            </Button>}
            {createMode && <>
                <InputField
                    value={value}
                    onChange={({ target: { value } }) => setValue(value)}
                    label="Nom de la tâche"
                    fullWidth />
                <Button disabled={!value} onClick={handleCreate}>
                    Créer
                </Button>
                <Button onClick={handleExitCreateMode}>
                    Annuler
                </Button>
            </>}
        </Box>
    )
}

interface TaskItemProps {
    task: CheckListElement
}
const TaskItem = ({ task }: TaskItemProps) => {
    const { dispatch } = React.useContext(KanbanContext)
    const handleChange = (partial: Partial<CheckListElement>) => dispatch.updateCheckListElement(task.id, partial)
    const handleDelete = () => dispatch.removeCheckListElement(task.id)
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
            <Checkbox checked={task.status ?? false} onChange={(_, status) => handleChange({ status })} />
            <Box flexGrow={1}>
                <LabelInput value={task.name} onChange={(name) => handleChange({ name })} disabled={task.status} />
            </Box>
            <Box>
                <IconButton onClick={handleDelete} size='small' color='error'>
                    <Delete fontSize='small' />
                </IconButton>
            </Box>
        </Box>
    )
}
export default CheckListTab