import ReactPDF from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import React from 'react'
import { InvoiceEditContext } from './useInvoiceEdit';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LogoAleph from '../../../Components/LogoAleph';
import { Image } from '@react-pdf/renderer';
import AlephSrc from '../../../assets/aleph.png'

const BLUECOLOR = '#1976d2'
const GREYBG = '';
const GREYHEADER = '#1d1d1b'
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        fontSize: 10
    },
    sectionAlephAdress: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        alignItems: 'center'
    },
    sectionFacturation: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: 50
    },
    sectionFacturationLine: {
        display: 'flex',
        flexDirection: 'row'
    },
    sectionFacturationLineHeader: {
        width: 100,
        fontWeight: 800
    },
    sectionTableHeader: {
        padding: 10,
        marginTop: 15,
        backgroundColor: BLUECOLOR,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 10,
        fontWeight: 800
    },
    sectionTableLine: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 2.5,
        paddingTop: 2.5,
        display: 'flex',
        flexDirection: 'row',
        fontSize: 10,
        fontWeight: 800
    },
    sectionTitleWidth: { width: 250 },
    sectionOtherWidth: {
        width: 75,
        textAlign: 'right'
    },
    sectionTotalLine: { display: 'flex', flexDirection: 'row', width: '25%', paddingBottom: 5 },
    sectionTotalHeader: { color: BLUECOLOR, fontSize: 10, fontWeight: 800, flexGrow: 1 },
    sectionTotalValue: { fontSize: 10, fontWeight: 800, textAlign: 'right' },
    sectionPayTo: { borderBottom: '0.5px solid black', paddingBottom: 10 },
    sectionPayToLine: { display: 'flex', flexDirection: 'row', marginLeft: 10, marginBottom: 5, color: GREYHEADER },
    sectionPayToHeader: { width: 75 },
});

const FormatCurrency = (currency: number) => new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(currency).toString().replaceAll(' ', ' ')

const InvoicePdfRender = () => {
    const { invoicePdf, dispatch } = React.useContext(InvoiceEditContext)
    const handleClose = () => dispatch.showInvoicePdf()

    const DocPdf = () => <Document>
        <Page size="A4" style={styles.page}>
            <View style={{ flexGrow: 1, padding: 20 }}>
                <View >
                    <View style={styles.sectionAlephAdress}>
                        <Image src={AlephSrc} style={{ width: 25 }} />
                        <Text style={{ fontSize: 15 }}>Aleph IT</Text>
                    </View>
                    <View style={{ paddingLeft: 28 }}>
                        <Text>Pierre Lucas</Text>
                        <Text>18 rue Henri DESGRANGES</Text>
                        <Text>17000 La Rochelle</Text>
                        <Text>Tel. : +33 6 26 27 09 45</Text>
                        <Text>@ : lucas.pierre@me.com</Text>
                    </View>
                </View>
                <View style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <View style={{ marginTop: 50, width: '50%' }}>
                        <Text style={{ color: BLUECOLOR, fontSize: 15 }}>{invoicePdf?.client?.name}</Text>
                        <Text style={{ fontSize: 12 }}>{invoicePdf?.client?.subname}</Text>
                        <Text style={{ fontSize: 12 }}>{invoicePdf?.client?.adress}</Text>
                        <Text style={{ fontSize: 12 }}>{invoicePdf?.client?.postalCode}, {invoicePdf?.client?.city}</Text>

                    </View>
                </View>
                <View>
                    <View style={styles.sectionFacturation}>
                        <View style={styles.sectionFacturationLine}>
                            <Text style={styles.sectionFacturationLineHeader}>Numéro de facture</Text>
                            <Text>{invoicePdf?.invoiceNumber}</Text>
                        </View>
                        <View style={styles.sectionFacturationLine}>
                            <Text style={styles.sectionFacturationLineHeader}>Date d'émission</Text>
                            <Text>{new Intl.DateTimeFormat('fr').format(new Date(invoicePdf?.emitedAt ?? new Date()))}</Text>
                        </View>
                        <View style={styles.sectionFacturationLine}>
                            <Text style={styles.sectionFacturationLineHeader}>Date d'échéance</Text>
                            <Text>{new Intl.DateTimeFormat('fr').format(new Date(invoicePdf?.paidAt ?? new Date()))}</Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 50 }}>
                        {/* <Text style={{ color: BLUECOLOR, fontSize: 15 }}>{invoicePdf?.client?.name}</Text> */}
                        <Text style={{ fontSize: 12 }}>{invoicePdf?.title}</Text>
                    </View>
                    <View style={styles.sectionTableHeader}>
                        <View style={{ ...styles.sectionTitleWidth }}>
                            <Text>Désignation</Text>
                        </View>
                        <View style={styles.sectionOtherWidth}>
                            <Text>Quantité</Text>
                        </View>
                        <View style={styles.sectionOtherWidth}>
                            <Text>Unité</Text>
                        </View>
                        <View style={styles.sectionOtherWidth}>
                            <Text>Prix unitaire</Text>
                        </View>
                        <View style={styles.sectionOtherWidth}>
                            <Text>TVA</Text>
                        </View>
                        <View style={styles.sectionOtherWidth}>
                            <Text>Montant HT</Text>
                        </View>
                    </View>
                    {invoicePdf?.items.map(item =>
                        <View style={styles.sectionTableLine} key={item.id}>
                            <View style={{ ...styles.sectionTitleWidth }}>
                                <Text>{item.title}</Text>
                            </View>
                            <View style={styles.sectionOtherWidth}>
                                <Text>{item.quota}</Text>
                            </View>
                            <View style={styles.sectionOtherWidth}>
                                <Text>{item.unit}</Text>
                            </View>
                            <View style={styles.sectionOtherWidth}>
                                <Text>{FormatCurrency(item.unitPrice)}</Text>
                            </View>
                            <View style={styles.sectionOtherWidth}>
                                <Text>{FormatCurrency(item.tva ?? 0)}</Text>
                            </View>
                            <View style={styles.sectionOtherWidth}>
                                <Text>{FormatCurrency((item.quota && item.unitPrice && item.tva && item.quota * item.unitPrice * (item.tva / 100)) || (item.quota && item.unitPrice && item.quota * item.unitPrice))}</Text>
                            </View>
                        </View>)}
                    <View style={{ marginTop: 25, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end', padding: 10 }}>
                        <View style={styles.sectionTotalLine}>
                            <View style={styles.sectionTotalHeader}>
                                <Text>Total HT</Text>
                            </View>
                            <View style={styles.sectionTotalValue}>
                                <Text>{invoicePdf && invoicePdf.items.length > 0 && FormatCurrency(invoicePdf?.items.map(({ quota, unitPrice, tva }) => (quota && unitPrice && quota * unitPrice) || 0).reduce((prev, curr) => prev + curr) ?? 0)}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionTotalLine}>
                            <View style={styles.sectionTotalHeader}>
                                <Text>TVA 20%</Text>
                            </View>
                            <View style={styles.sectionTotalValue}>
                                <Text>{FormatCurrency(0)}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionTotalLine}>
                            <View style={styles.sectionTotalHeader}>
                                <Text>Total TTC</Text>
                            </View>
                            <View style={styles.sectionTotalValue}>
                                <Text>{invoicePdf && invoicePdf.items.length > 0 && FormatCurrency(invoicePdf?.items.map(({ quota, unitPrice, tva }) => (quota && unitPrice && quota * unitPrice) || 0).reduce((prev, curr) => prev + curr) ?? 0)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f3f3f0', padding: 20 }}>
                <View style={styles.sectionPayTo}>
                    <Text style={{ color: GREYHEADER, textTransform: 'uppercase', paddingBottom: 5 }}>Détails du paiement</Text>
                    <View style={styles.sectionPayToLine}>
                        <Text style={styles.sectionPayToHeader}>Bénéficiaire</Text>
                        <Text>Pierre Lucas</Text>
                    </View>
                    <View style={styles.sectionPayToLine}>
                        <Text style={styles.sectionPayToHeader}>RIB</Text>
                        <Text>16958 00001 22785748176 32</Text>
                    </View>
                    <View style={styles.sectionPayToLine}>
                        <Text style={styles.sectionPayToHeader}>IBAN</Text>
                        <Text>FR76 1695 8000 0122 7857 4817 632</Text>
                    </View>
                    <View style={styles.sectionPayToLine}>
                        <Text style={styles.sectionPayToHeader}>BIC/SWIFT</Text>
                        <Text>QNTOFRP1XXX</Text>
                    </View>
                    <View style={styles.sectionPayToLine}>
                        <Text style={styles.sectionPayToHeader}>Référence</Text>
                        <Text>{invoicePdf?.id}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 8, marginBottom: 0, paddingTop: 20 }}>
                    <Text>Aleph IT</Text>
                    <Text>SIRET : 90393376000022</Text>
                    <Text>Page 1-1</Text>
                </View>
            </View>
        </Page>
    </Document>
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={Boolean(invoicePdf)}
            onClose={handleClose}
        >
            <DialogTitle>Facture</DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <DocPdf />
                </PDFViewer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoicePdfRender