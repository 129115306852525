import { TabPanel } from '@mui/lab'
import React from 'react'
import InputField from '../../../InputField'
import { KanbanContext } from '../../useKanban'
import { Avatar, Box, Button, Card, CardContent, Checkbox, Divider, IconButton, LinearProgress, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { CheckList, CheckListElement, KanbanCard, KanbanCardComment } from '../../../../Stores/KanbanType'
import LabelInput from '../../../LabelInput'

const CommentsTab = () => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    return (
        <TabPanel value='comments'>
            <Box display={'flex'} flexDirection={'column'} gap={4}>
                {editedCard?.comments.map(comment => <CommentItem key={comment.id} comment={comment} />)}
                {editedCard && editedCard.comments.length > 0 && <Divider sx={{ ml: -4, mr: -4 }} />}
                <CommentAdder />
            </Box>
        </TabPanel>
    )
}

const CommentAdder = () => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    const [message, setMessage] = React.useState<string>('')
    const handleSend = () => dispatch.addComment(editedCard!.id, message)

    React.useEffect(() => {
        setMessage('')
    }, [editedCard?.comments])
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} gap={1}>
            <Box>
                <Avatar>PL</Avatar>
            </Box>
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                <InputField
                    value={message}
                    onChange={({ target: { value } }) => setMessage(value)}
                    label="Commentaire"
                    multiline
                    minRows={3} />
                <Box textAlign={'right'} onClick={handleSend}>
                    <Button>Commenter</Button>
                </Box>
            </Box>

        </Box>
    )
}

interface CommentItemProps {
    comment: KanbanCardComment
}
const CommentItem = ({ comment }: CommentItemProps) => {
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} gap={1}>
            <Box>
                <Avatar>PL</Avatar>
            </Box>
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                <Typography variant='h6'>Pierre Lucas</Typography>
                <Card variant='outlined' sx={{ p: 1, backgroundColor: theme => theme.palette.grey[100], flexGrow: 1 }}>
                    {comment.comment}
                </Card>
                <Typography variant='caption' color={'GrayText'}>
                    {new Intl.DateTimeFormat('fr', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(comment.createdAt))}
                </Typography>
            </Box>
        </Box>
    )
}

export default CommentsTab