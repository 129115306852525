import { Avatar, Box, Divider, Tab, Typography } from '@mui/material'
import React from 'react'
import { PageContext } from './Page'
import BreadCrumb from './BreadCrumb'
import { TabList } from '@mui/lab'
import TabManager from './TabHeader'

const Header = () => {
    const { title, subtitle, breadcrumb, image, rightPlace, tabs } = React.useContext(PageContext)
    return (
        <Box display={'flex'} flexDirection={'column'} gap={1} p={1}>
            <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                {image &&
                    <Box>
                        <Avatar
                            sx={breadcrumb && subtitle
                                ? { width: 85, height: 85 }
                                : (breadcrumb || subtitle)
                                    ? { width: 60, height: 60 }
                                    : {}}
                            src={image.id ? `/download/${image.id}` : image.asset ?? ''}
                        />
                    </Box>}
                <Box flexGrow={1}>
                    {typeof title === 'string'
                        ? <Typography variant={'h5'} component={'h2'}>{title}</Typography>
                        : title}
                    <Typography variant={'subtitle2'} component={'h3'}>{subtitle}</Typography>
                    <BreadCrumb />
                </Box>
                <Box>
                    {rightPlace}
                </Box>
            </Box>
            {tabs && <TabManager />}
        </Box>
    )
}

export default Header