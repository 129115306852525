import { TableRow, TableCell, IconButton } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { EditEcContext } from '../useEditEc'
import { Delete } from '@mui/icons-material'


interface ItemCommentProps {
    processus: number,
    item: number
    competence: number
}
const ItemComment = ({ processus, competence, item }: ItemCommentProps) => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    const [value, setValue] = React.useState<undefined | string>(undefined)

    const handleChange = () => dispatch.updateComment(processus, competence, item, value ?? '')
    const deleteComment = () => dispatch.deleteComment(processus, competence, item)

    React.useEffect(() => {
        if (ec?.comments.find(comment => comment.processus === processus && comment.competence === competence && comment.item === item)) {
            setValue(ec!.comments.find(comment => comment.processus === processus && comment.competence === competence && comment.item === item)!.value)
        } else {
            setValue(undefined)
        }
    }, [ec])

    if (value === undefined) return (<></>)
    return (
        <TableRow>
            <TableCell colSpan={6}>
                <InputField
                    label={'Commentaire'}
                    value={value}
                    onChange={({ target: { value } }) => setValue(value)}
                    onBlur={handleChange}
                    onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                    fullWidth
                    multiline
                />
            </TableCell>
            <TableCell>
                <IconButton size="small" color="error" onClick={deleteComment}>
                    <Delete fontSize="small" color="error" />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default ItemComment