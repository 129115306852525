import React from "react";
import { Menu } from "@mui/icons-material";
import { Box, IconButton, AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Toolbar, Typography, styled } from "@mui/material";
import UiContext from "./UiContext";
import UserMenu from "./UserMenu";
import LogoAleph from "../LogoAleph";
import AppsMenu from "./AppsMenu";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: '100%',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 300,
            width: `calc(100% - 300px)`
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Bar = ({ children }: React.PropsWithChildren) => {
    const { open, toggleOpen } = React.useContext(UiContext)
    return (
        <AppBar open={open} position="absolute">
            <Toolbar sx={{ pr: '24px' }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleOpen}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <Menu />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, display: 'flex', gap: 1, alignItems: 'center' }}
                >
                    <LogoAleph fontSize={'inherit'} color={'secondary'} /> Aleph IT
                </Typography>
                <Box flexGrow={1} />
                <AppsMenu />
                <UserMenu />
            </Toolbar>
        </AppBar>
    )
}

export default Bar