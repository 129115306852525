import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { EditEcContext } from '../useEditEc'

const Eval = () => {
    const { ec, dispatch } = React.useContext(EditEcContext)

    const [good, setGood] = React.useState(ec!.eval2.good)
    const [work, setWork] = React.useState(ec!.eval2.work)
    const [learn, setLearn] = React.useState(ec!.eval2.learn)
    const [update, setUpdate] = React.useState(ec!.eval2.update)

    const handleChange = () => dispatch.changeEval2({ good, work, learn, update })

    React.useEffect(() => {
        setGood(ec!.eval2.good)
        setWork(ec!.eval2.work)
        setLearn(ec!.eval2.learn)
        setUpdate(ec!.eval2.update)
    }, [ec])

    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField
                        value={good}
                        onChange={({ target: { value } }) => setGood(value)}
                        onBlur={handleChange}
                        onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                        label="Ce que j’ai bien réalisé aujourd’hui (recueil de données, analyse, choix des priorités, planification, délégation, soins et surveillances…)"
                        multiline
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        value={work}
                        onChange={({ target: { value } }) => setWork(value)}
                        onBlur={handleChange}
                        onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                        label="Ce que je dois améliorer (recueil de données, analyse, choix des priorités, planification, délégation, soins et surveillances…)"
                        multiline
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        value={learn}
                        onChange={({ target: { value } }) => setLearn(value)}
                        onBlur={handleChange}
                        onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                        label="Objectifs travaillés dans l’EC de ce jour"
                        multiline
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        value={update}
                        onChange={({ target: { value } }) => setUpdate(value)}
                        onBlur={handleChange}
                        onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                        label="Objectifs poursuivis pour le prochain EC"
                        multiline
                        fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Eval