import React from 'react'
import InputField from '../../../Components/InputField'
import { InvoiceEditContext } from './useInvoiceEdit'

const InvoiceTitle = () => {
    const { invoice, dispatch } = React.useContext(InvoiceEditContext)
    const [title, setTitle] = React.useState(invoice!.title)

    const handleSave = (event: React.FocusEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) =>
        ((event.type === 'blur') || (event.type === 'keyup' && (event as React.KeyboardEvent).key === 'Enter')) && dispatch.update({ title })

    React.useEffect(() => {
        setTitle(invoice!.title)
    }, [invoice])
    return (
        <InputField
            label={'Intitulé de la facture'}
            value={title}
            onChange={({ target: { value } }) => setTitle(value)}
            onBlur={handleSave}
            onKeyUp={handleSave}
            multiline
        />
    )
}

export default InvoiceTitle