import React from 'react'
import Kanban from '../Components/KanBan/Kanban'
import Page from '../Components/PageInterface'
import { ViewKanban } from '@mui/icons-material'

const KanbanPage = () => {
  return (
    <Page
      loading={false}
      title={'Kanban'}
      breadcrumb={[
        { name: 'Kanban', icon: ViewKanban, url: '/aleph/kanban' }
      ]}
      fullPage
    >
      <Kanban />
    </Page>
  )
}

export default KanbanPage