import { Box, Button, Card, CardMedia, Grid, IconButton, Input, MenuItem } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { DocumentContext } from '../useDocument'
import { PageContext } from '../../../../../Components/PageInterface/Page'
import { Close } from '@mui/icons-material'
import ThematicSelector from '../../../../../Components/ThematicSelector'
import EPDSIIntervenantSelector from '../../../../../Components/EPDSIIntervenantSelector'
import CoursObjectifs from '../../../../../Components/CoursObjectifs'
import DeleteButton from '../../../../../Components/DeleteButton'

const TabGen = () => {
    const { openFileSelector } = React.useContext(PageContext)
    const { cours, dispatch } = React.useContext(DocumentContext)

    const handleSelectFile = () => {
        openFileSelector({
            title: 'Sélectionner un PDF',
            files: cours?.files.filter((f) => f.mime.includes('pdf')) ?? [],
            onSelect: dispatch.selectFile,
            close: () => openFileSelector(null)
        })
    }

    return (
        <Box ml={1} mr={1} p={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                    <InputField
                        key={cours?.updatedAt + 'name'}
                        label="Titre du document"
                        defaultValue={cours?.name ?? ''}
                        onBlur={({ target: { value } }) => dispatch.updateField('name', value)}
                        sx={{ mb: 2 }}
                        fullWidth
                    />
                    <InputField
                        key={cours?.updatedAt + 'subname'}
                        label="Sous titre du document (optionnel)"
                        defaultValue={cours?.subname ?? ''}
                        onBlur={({ target: { value } }) => dispatch.updateField('subname', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <InputField
                        key={cours?.updatedAt + 'type'}
                        label={'Type de cours'}
                        value={cours?.type !== 0 ? cours?.type ?? '' : ''}
                        onChange={({ target: { value } }) => dispatch.updateField('type', +value)}
                        select
                        fullWidth
                    >
                        <MenuItem value={2}>Audio commenté</MenuItem>
                        <MenuItem value={1}>Webinaire</MenuItem>
                        <MenuItem value={3}>Vidéo</MenuItem>
                    </InputField>
                    <DeleteButton
                        label='Supprimer le cours'
                        square
                        confirm
                        onDelete={dispatch.deleteCours}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <EPDSIIntervenantSelector value={cours?.intervenants ?? []} onSelect={dispatch.addIntervenant} onDelete={dispatch.removeIntervenant} />
                        <InputField
                            label={'Plannification du cours'}
                            defaultValue={cours!.date.split('.')[0]}
                            onBlur={({ target: { value } }) => dispatch.updateField('date', value + ':00.000Z')}
                            //@ts-ignore
                            onKeyUp={({ key, currentTarget: { value } }) => key === 'Enter' && dispatch.updateField('date', value + ':00.000Z')}
                            type="datetime-local" />
                        <InputField
                            key={cours?.updatedAt}
                            label="Période"
                            defaultValue={cours?.period ?? ''}
                            onBlur={({ target: { value } }) => dispatch.updateField('period', value)}
                            fullWidth
                            select
                        >
                            <MenuItem value={1}>Période 1</MenuItem>
                            <MenuItem value={2}>Période 2</MenuItem>
                            <MenuItem value={3}>Période 3</MenuItem>
                        </InputField>
                        <ThematicSelector selectedThematic={cours?.thematic ?? null} onSelect={dispatch.setThematic} />
                        <Box display={{ xs: 'block', lg: 'none' }}>
                            {cours?.file
                                ? <Card sx={{ position: 'relative', aspectRatio: '16/9' }}>
                                    <CardMedia image={`/download/${cours.file.id}/1`} sx={{ minHeight: '100%' }} />
                                    <Box sx={{ position: 'absolute', top: 0, right: 0, m: 2 }} >
                                        <IconButton onClick={dispatch.unSelectFile}>
                                            <Close />
                                        </IconButton>
                                    </Box>
                                </Card>
                                : <Button disabled={cours?.files.filter(f => f.mime.includes('pdf')).length === 0} onClick={handleSelectFile}>
                                    Select PDF
                                </Button>}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <CoursObjectifs />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box display={{ xs: 'none', lg: 'block' }}>
                        {cours?.file
                            ? <Card sx={{ position: 'relative', aspectRatio: '16/9' }}>
                                <CardMedia image={`/download/${cours.file.id}/1`} sx={{ minHeight: '100%' }} />
                                <Box sx={{ position: 'absolute', top: 0, right: 0, m: 2 }} >
                                    <IconButton onClick={dispatch.unSelectFile}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Card>
                            : <Button disabled={cours?.files.length === 0} onClick={handleSelectFile}>
                                Select PDF
                            </Button>}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TabGen