import { Box } from '@mui/material'
import React from 'react'
import EPDSIList from '../../../EPDSIList'
import ComptenceAccordion from './ComptenceAccordion'

interface ProcessusProps {
    processus: number
}
export interface ProcessusAccordionProps {
    expanded: number,
    setExpanded: (value: number) => void
}
const Processus = ({ processus }: ProcessusProps) => {
    const [expanded, setExpanded] = React.useState<number>(1)
    const process = EPDSIList.processus[processus - 1] ?? undefined

    if (!process) return (<></>)
    return (
        <Box sx={{ p: 1, gap: 2, display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}>
            {process.competences.map(competence => <Box key={competence.id}>
                <ComptenceAccordion processus={processus} competence={competence} expanded={expanded} setExpanded={setExpanded} />
            </Box>)}
        </Box>
    )
}

export default Processus