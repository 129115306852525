import { Gantt, Task, ViewMode } from 'gantt-task-react';
import React from 'react'
import "gantt-task-react/dist/index.css";
import { Box, Dialog, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { UseEpEditContext } from '../useEpsEdit';
import Edit from './Edit';

const PlannifTab = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)

    return (
        <Box
        >
            <IconButton onClick={dispatch.addPlannifTask}><Add /></IconButton>

            <Edit />

            {ep && ep.plannif.length > 0 && <Gantt
                viewMode={ViewMode.Hour}
                locale='fr'
                onDoubleClick={dispatch.selectEditTask}
                tasks={ep.plannif.map(t => ({ ...t, start: new Date(t.start), end: new Date(t.end) }))}
                onDateChange={dispatch.editTask}
                timeStep={5 * 60 * 1000}
            />}
        </Box>
    )
}

export default PlannifTab