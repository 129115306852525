import React from 'react'
import { PrimeAnalyseDataType, PrimeAnalyseType } from '../../../../../../Stores/ExploitationPratiqueType'
import { UseEpEditContext } from '../../useEpsEdit'
import { Transition } from '@dnd-kit/utilities'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, List, ListItem, ListItemText, Divider, Slide, Box, MenuItem, Menu, ListSubheader, Chip } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'
import SystemSelector from '../SystemSelector'
import InputField from '../../../../../../Components/InputField'
import systemNames from '../../systemNames'
import { v4 } from 'uuid'

const Adder = () => {
    const { ep, anaEdit, dispatch, problems } = React.useContext(UseEpEditContext)
    const [ana, setAna] = React.useState<PrimeAnalyseType>()
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)

    const handleClose = () => dispatch.setAnaEdit(null)

    const gePbName = (id: string) => problems?.map(group => group.problems).flat().find(g => g.id === id)?.name ?? ''

    const handleSave = () => {
        anaEdit === 'add'
            ? dispatch.addPrimeAna(ana!)
            : dispatch.changePrimeAna(ana!.id, ana!)
        dispatch.setAnaEdit(null)
    }

    React.useEffect(() => {
        if (anaEdit === null) {
            setAna(undefined)
            return
        } else if (anaEdit === 'add') {
            setAna({
                id: v4(), //Math.max(...(ep?.primeAna?.map(p => p.position ?? 0) ?? [-1])) + 1,
                system: null,
                data: {},
                explication: '',
                problem: [],
                prioritaire: undefined,
                position: Math.max(...(ep?.primeAna?.map(p => p.position ?? 0) ?? [-1])) + 1
            })
        } else {
            setAna(({
                ...anaEdit,
                data: typeof anaEdit.data === 'string' ? { ['Histoire de la maladie']: anaEdit.data } : anaEdit.data ?? {},
                problem: typeof anaEdit.problem === 'string' ? [anaEdit.problem] : anaEdit.problem ?? [],
            }))
        }
    }, [])

    if (!ana) return <></>
    return (
        <Dialog
            fullScreen
            open={Boolean(ana)}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {anaEdit === 'add' && 'Ajouter une analyse par système'}
                        {anaEdit && anaEdit !== 'add' && `Editer une analyse du système : ${systemNames.find(s => s.id === ana?.system)?.name ?? ''}`}
                    </Typography>
                    <Button color='inherit' onClick={handleSave}>
                        {anaEdit === 'add' ? 'Créer' : 'Sauvegarder'}
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <SystemSelector value={ana?.system ?? null} onChange={system => setAna(p => ({ ...p!, system }))} />
                <Typography variant='h5'>Recueil de données objectives/subjectives</Typography>
                {typeof ana?.data === 'string'
                    ? <>is a string</>
                    : (['Antécédents', 'Histoire de la maladie', 'Diagnostiques', 'Examen clinique', 'Examen paraclinique', 'Equipements', 'Traitements'] as (keyof PrimeAnalyseDataType)[])
                        .map((f) => <InputField
                            key={f}
                            label={f}
                            value={(f as keyof PrimeAnalyseDataType) in (ana!.data as PrimeAnalyseDataType) ? (ana!.data as PrimeAnalyseDataType)![f] ?? '' : ''}
                            onChange={({ target: { value } }) => setAna(p => ({ ...p!, data: { ...(typeof p!.data === 'string' ? {} : p!.data ?? {}), [f]: value } }))}
                            multiline
                            fullWidth
                        />)}
                <Typography variant='h5' gutterBottom={false}>
                    Interprétation des données — Mise en évidence des hypothèses<br />
                    <Typography variant='caption' gutterBottom={true}>hypothèses les-la plus probables dans la situation</Typography>
                </Typography>
                <InputField
                    label={'Explication'}
                    value={ana?.explication ?? ''}
                    onChange={({ target: { value } }) => setAna(p => ({ ...p!, explication: value }))}
                    multiline
                    fullWidth
                    minRows={4}
                />
                <Typography variant='h5' gutterBottom={false}>
                    Détermination des problèmes réels
                </Typography>
                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                    {(ana?.problem as string[]).map((p, i) =>
                        <Chip
                            key={p}
                            label={gePbName(p)}
                            onDelete={() => setAna(prev => ({ ...prev!, problem: (prev!.problem as string[]).filter(f => f !== p) }))} />)}
                    <Box>

                        <IconButton onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                            <Add />
                        </IconButton>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            onClose={() => setAnchor(null)}
                        >
                            {problems?.map(group => [group.name, ...group.problems])
                                .flat()
                                .map(problem =>
                                    typeof problem === 'string'
                                        ? <ListSubheader key={problem}>{problem}</ListSubheader>
                                        : <MenuItem
                                            key={problem.id}
                                            disabled={ana?.problem?.includes(problem.id)}
                                            onClick={() => {
                                                setAna(p => ({ ...p!, problem: typeof p?.problem === 'string' ? [p.problem, problem.id] : [...p?.problem ?? [], problem.id] }))
                                                setAnchor(null)
                                            }}>
                                            <ListItemText primary={problem.name} secondary={problem.description} />
                                        </MenuItem>)}
                        </Menu>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default Adder