import { TabList } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import { PageContext } from './Page'
import TabHeaderButton from './TabHeaderButton'

const TabHeader = () => {
  const { tab, setTab, tabs, tabButton, fileManagerConfig } = React.useContext(PageContext)
  return (
    <Box sx={{ marginBottom: -1 }}>
      <TabList
        onChange={(_, value) => setTab(value)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs?.map(t => <Tab value={t.label} label={t.label} key={t.label} sx={{ padding: 0, mr: 2, minWidth: 'unset' }} />)}
        {fileManagerConfig && <Tab value={'filelist'} label={'Fichiers'} sx={{ padding: 0, mr: 2, minWidth: 'unset' }} />}
        {tabButton && <TabHeaderButton />}
      </TabList>
    </Box>
  )
}

export default TabHeader