export type EPDSIListType = {
    examinateurs: examinateurType[]
    processus: processusType[]
    problemes: ProblemesGroupType[]
}

type examinateurType = { id: number, firstname: string, lastname: string }
export type processusType = { id: number, competences: competenceType[] }
export type competenceType = { id: number, title: string, items: competenceResourceType[] }
export type competenceResourceType = { id: number, label: string, attended: { [key: number]: number } }
export type ProblemesGroupType = { label: string, problemes: ProblemesType[] }
export type ProblemesType = { label: string, actions: [] }

const EPDSIList: EPDSIListType = {
    examinateurs: [
        { id: 1, firstname: 'Anne-Marie', lastname: '' },
        { id: 2, firstname: 'Sigrid', lastname: '' },
        { id: 3, firstname: 'Dominique', lastname: '' },
        { id: 4, firstname: 'Myriam', lastname: '' },
        { id: 5, firstname: 'Emmanuel', lastname: '' },
        { id: 6, firstname: 'David', lastname: 'Cunial' },
        { id: 7, firstname: 'Karima', lastname: '' },
        { id: 8, firstname: 'Vincent', lastname: '' },
    ],
    processus: [
        {
            "id": 1,
            "competences": [
                {
                    "id": 1,
                    "title": "Concevoir, realiser et evaluer des interventions infirmieres aupres du patient de soins intensifs",
                    "items": [
                        {
                            "label": "En se renseignant sur l'état général habituel du patient, le motif de l'admission, l'état de santé actuel, les besoins en soins et les prescriptions médicales(dans la prise en charge du patient déjà hospitalisé ou entrant dans le service)",
                            "id": 1,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En préparant l'environnement de soin et l'admission du patient selon les protocoles en vigueur et / ou en l'accueillant selon les critères de qualité dans l'institution  ",
                            "id": 2,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En procédant au recueil de données(rapport, tour du lit, dossier médico - infirmier)",
                            "id": 3,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En recourant au jugement clinique pour analyser et interpréter la situation du patient ",
                            "id": 4,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En argumentant de façon pertinente la démarche clinique, basée sur l'analyse des problèmes actuels et potentiels prioritaires ",
                            "id": 5,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En élaborant un projet de soins personnalisé, s'appuyant sur des éléments scientifiques et tenant compte des ressources et besoins du patient et de l'équipe",
                            "id": 6,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En planifiant les actions dans le respect des priorités",
                            "id": 7,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En démontrant une anticipation et un regroupement des activités de soins ",
                            "id": 8,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En réalisant, les actions infirmières qui combinent des techniques, des surveillances et des traitements selon les critères et procédures établis, assurant le bien - être et le confort du patient dans le respect des priorités",
                            "id": 9,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En démontrant habilité, rigueur, minutie et douceur dans les soins ",
                            "id": 10,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En se renseignant, avant toute administration, sur les prescriptions pharmacologiques du patient et en les administrant rigoureusement selon les principes établis",
                            "id": 11,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En ciblant les surveillances et en anticipant les éventuelles complications(pharmacologiques, médico - techniques et des interventions infirmières)",
                            "id": 12,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En informant rapidement l'équipe interdisciplinaire lorsque les objectifs thérapeutiques ne sont pas atteints",
                            "id": 13,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En adaptant continuellement et en argumentant les interventions de soins à l'évolution de l'état du patient(utilisant les outils d'évaluation approprié)",
                            "id": 14,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En respectant la philosophie de soins institutionnelle et les 5 critères de qualité ",
                            "id": 15,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En reconnaissant les situations où la vie du patient est menacée(analyse rapide des données cliniques et paracliniques, réaction, communication  précise) ",
                            "id": 16,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En analysant de façon systématique et rapide les situations critiques et en organisant et exécutant sans délais les gestes nécessaires selon les procédures établies",
                            "id": 17,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En sollicitant les personnes ressources de manière adaptée ",
                            "id": 18,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En identifiant et employant le matériel nécessaire de manière adaptée ",
                            "id": 19,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 2,
                    "title": "Concevoir, realiser et evaluer des interventions infirmieres dans l'assistance aux procedures diagnostiques ou therapeutiques aupres du patient de soins intensifs a l'interieur et l'exterieur de l'unite de soins",
                    "items": [
                        {
                            "label": "En recueillant les informations nécessaires  pour le patient (en identifiant le niveau de risque, en garantissant la sécurité)",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En planifiant, organisant et préparant le matériel, le patient  et  l'environnement pour assurer  la qualité et l'efficacité de la  procédure ",
                            "id": 2,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En garantissant la sécurité et le confort du patient et  en assurant la continuité de la surveillance et des traitements durant  la procédure",
                            "id": 3,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 3,
                    "title": "Communiquer et etablir une relation de confiance avec le patient de soins intensifs et ses proches",
                    "items": [
                        {
                            "label": "En étant réceptif à la communication verbale et non verbale du patient et de ses proches",
                            "id": 1,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En assurant une écoute active et un accompagnement du patient et de ses proches dans les situations de routine  ",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En assurant une écoute active et un accompagnement du patient et de ses proches dans les situations de  crise(qualité d'écoute, de communication, disponibilité et attention, langage adapté et compréhensible aux patient et ses proches, respect  déontologique)",
                            "id": 3,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En informant le patient de façon continue sur les actions qui vont se dérouler et en sollicitant  sa collaboration si possible dans l'application du projet de soin  ",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En établissant, une relation de soutien avec le patient favorisant son orientation personnelle, temporelle et spatiale",
                            "id": 5,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En ajustant  son attitude et sa communication au patient et/ ou à ses proches en fonction des informations recueillies sur le moment",
                            "id": 6,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En se faisant porte - parole des préoccupations du patient et de ses proches auprès de l'équipe intra et interdisciplinaire.",
                            "id": 7,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "competences": [
                {
                    "id": 1,
                    "title": "S'engager dans la cooperation et la coordination intra et interdisciplinaire",
                    "items": [
                        {
                            "label": "En identifiant son rôle et le rôle des personnes associées au projet de soins des patients",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En se renseignant sur la mise en œuvre de mesures diagnostiques et thérapeutiques  ",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En identifiant et en favorisant les besoins de coopération et de coordination par rapport aux ressources disponibles dans l'équipe, centrées sur les intérêts du patiente",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En planifiant selon des priorités la coopération, la coordination intra - inter - disciplinaire",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En vérifiant le déroulement et la qualité des actions entreprises, l'affectation des ressources utilisées",
                            "id": 5,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 2,
                    "title": "Donner des soins et gerer les risques, conformement aux attentes des patients, aux regles de l'art et au referentiel institutionnel",
                    "items": [
                        {
                            "label": "En connaissant les procédures institutionnelles  en matière de gestion de la qualité, de sécurité et de gestion des risques ",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En exerçant une analyse critique des soins donnés et des écarts par rapport aux normes de pratique dans l'état actuel des connaissances, pour le patient, ses collègues et soi - même  ",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En  identifiant et en  déclarant des situations à risques(erreurs, incidents…), concernant les patients, soi - même ou des tiers selon les règles du service",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En appliquant les procédures internes(double contrôle, Hygiène...)  de façon systématique  dans toutes les étapes de prise en charge du patient.",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En émettant des propositions visant à améliorer la qualité, à prévenir les erreurs et à garantir une utilisation durable des ressources",
                            "id": 5,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "competences": [
                {
                    "id": 1,
                    "title": "Promouvoir sa sante au travail",
                    "items": [
                        {
                            "label": "En identifiant les risques et les dangers pour soi et pour toutes les personnes concernées",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En planifiant et appliquant des mesures de protection et / ou de renforcement à envisager dans son environnement professionnel ",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En appliquant rigoureusement les normes prescrites(médicaux, de services, protocoles…) concernant la prévention des risques professionnels(aménageant l'environnement de soins de façon  ergonomique, protection contre le risque de blessures, contamination…)",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En évaluant l'efficacité et la durabilité des mesures prises individuellement ou à l'aide de tiers ∆",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 2,
                    "title": "S'impliquer dans son perfectionnement professionnel",
                    "items": [
                        {
                            "label": "En identifiant ses propres besoins de développement et en se perfectionnant de manière systématique par rapport aux exigences et évolution de la pratique professionnelle ∆",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En tenant compte des remarques des membres de l'équipe intra et interdisciplinaire  quant à sa manière de travailler  afin de la corriger  ",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En sollicitant le soutien nécessaire et en utilisant les divers moyens d'apprentissage",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En évaluant ses compétences sur la base de normes et d'expériences",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 3,
                    "title": "Concourir a la dynamique constructive du groupe",
                    "items": [
                        {
                            "label": "En s'adaptant aux personnes, quels que soient leur genre, leur origine socioculturelle, leurs valeurs religieuses et leur niveau socioéconomique",
                            "id": 1,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En démontrant des attitudes favorisantes : positivisme, saine curiosité, prise de recul, imputabilité, diplomatie",
                            "id": 2,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En transmettant des renseignements clairs et précis, aussi bien oralement que par écrit en utilisant une terminologie adaptée",
                            "id": 3,
                            "attended": { 1: 3, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En s'assurant que les informations ont été reçues et perçues correctement, en temps voulu",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En étant sensibilisé au rôle d'Infirmier Responsable d'Horaire(IRH) ∆",
                            "id": 5,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 4,
                    "title": "Soigner en respectant des principes ethiques et le cadre legal",
                    "items": [
                        {
                            "label": "En connaissant les ressources nécessaires à une action professionnelle menée sur la base de principes éthique et de normes légales / juridiques",
                            "id": 1,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En participant ou en se renseignant sur des délibérations et des décisions d'ordre éthique, en étroite collaboration avec l'équipe intra et interdisciplinaire.",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En garantissant le respect, dans sa propre pratique, des décisions d'équipe, des règles déontologiques, des principes éthiques et juridiques, ainsi que  des protocoles, des Directives institutionnelles de service.",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En évaluant sa pratique professionnelle selon des critères juridiques, déontologiques et éthiques ∆",
                            "id": 4,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                }
            ]
        },
        {
            "id": 4,
            "competences": [
                {
                    "id": 1,
                    "title": "Integrer des savoirs scientifiques dans sa pratique professionnelle",
                    "items": [
                        {
                            "label": "En questionnant le type de savoirs sur lesquelles s'appuient les pratiques infirmières quotidiennes ",
                            "id": 1,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En promouvant le développement et l'application d'une pratique fondée sur des savoirs scientifiques(EBN) ",
                            "id": 2,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En se  renseignant sur les travaux de recherche(infirmières et médicales)  en cours dans le service et  s'implique dans l'intégration de résultats de la recherche scientifique dans le milieu clinique",
                            "id": 3,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En démontrant un regard critique de ses prestations en s'appuyant sur des données probantes ",
                            "id": 4,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 2,
                    "title": "S'impliquer dans le developpement de l'expertise infirmiere",
                    "items": [
                        {
                            "label": "En s'informant sur les rôles des différents groupes ou associations professionnels internes ou externes à l'institution ∆",
                            "id": 1,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "S'engageant dans la promotion d'une image positive de la profession  tant au niveau de l'institution que de l'extérieur",
                            "id": 2,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En se renseignant sur les  différentes activités et projets de service, en y adhérant et en y participant ∆",
                            "id": 3,
                            "attended": { 1: 2, 2: 0, 3: 0 }
                        }
                    ]
                },
                {
                    "id": 3,
                    "title": "Soutenir l'encadrement et la formation des etudiants et des collaborateurs",
                    "items": [
                        {
                            "label": "En identifiant  les besoins d'apprentissage des étudiants et des collègues",
                            "id": 1,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En individualisant l'offre d'encadrement ∆",
                            "id": 2,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En mettant en œuvre l'offre d'apprentissage ∆",
                            "id": 3,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        },
                        {
                            "label": "En évaluant l'apprentissage et en donnant une appréciation sur la base de critères ∆",
                            "id": 4,
                            "attended": { 1: 1, 2: 0, 3: 0 }
                        }
                    ]
                }
            ]
        }
    ],
    problemes: [
        {
            "label": "Respiratoire",
            "problemes": [
                {
                    "label": "Échanges gazeux perturbées de type hypoxémie et/ou hypercapnie (ex : surcharge pulmonaire – OAP/ décompensation respiratoire de type ARDS/ infection pulmonaire, crise d’asthme, encombrement bronchique) ",
                    "actions": []
                },
                {
                    "label": "Altération de la mécanique respiratoire (ex :  PNO, atélectasie, surcharge pulmonaire – OAP/ décompensation respiratoire de type ARDS, infection pulmonaire, crise d’asthme)",
                    "actions": []
                },
                {
                    "label": "Sevrage ventilatoire prolongée (ex : tetraparésie, dénutrition) ",
                    "actions": []
                },
                {
                    "label": "Obstruction des voies aériennes (ex : bouchon muqueux, asthme, encombrement bronchique, probl. ORL ",
                    "actions": []
                }
            ]
        },
        {
            "label": "Cardio-vasculaire /infectieux",
            "problemes": [
                {
                    "label": "Incapacité à maintenir une TA dans les normes/ cibles ",
                    "actions": []
                },
                {
                    "label": "Incapacité à maintenir une fréquence cardiaque dans les normes/cibles",
                    "actions": []
                },
                {
                    "label": "Anomalies à l’ECG (ex : trouble du rythme cardiaque, trouble de la conduction cardiaque, ischémie coronarienne, trouble de la repolarisation…)",
                    "actions": []
                },
                {
                    "label": "Altération du débit cardiaque sur insuffisance cardiaque ou insuffisance vasculaire (présence d’état de choc - à préciser selon l’analyse, OAP cardiogénique, insuffisance D/G …) ",
                    "actions": []
                },
                {
                    "label": "Perte sanguine ",
                    "actions": []
                },
                {
                    "label": "Présence de thrombose / embolie",
                    "actions": []
                },
                {
                    "label": "Etat fébrile/ infection /sepsis",
                    "actions": []
                },
                {
                    "label": "Etat inflammatoire (maladie auto-immune, post CEC, post chirurgie…)",
                    "actions": []
                },
                {
                    "label": "Hyperthermie /Hypothermie (ex : thermorégulation inefficace en pédiatrie….)",
                    "actions": []
                }
            ]
        },
        {
            "label": "Neurologique ",
            "problemes": [
                {
                    "label": "Inconfort / douleur aiguë / chronique",
                    "actions": []
                },
                {
                    "label": "Altération de l'état de conscience hors sédation (désorientation spatio-temporelle …)",
                    "actions": []
                },
                {
                    "label": "Présence de confusion aigue/chronique (ex : délirium ….)",
                    "actions": []
                },
                {
                    "label": "Troubles sensitivo-moteur : négligence de l’hémicorps, parésie, plégie, paresthésie, visuelle, auditive, gustative, tactile, olfactive, kinesthésique, troubles de la déglutition …)",
                    "actions": []
                },
                {
                    "label": "Présence d’hypertension intra-cranienne",
                    "actions": []
                },
                {
                    "label": "Altération de la perfusion cérébrale ",
                    "actions": []
                },
                {
                    "label": "Habitudes de sommeil perturbées / Privation de sommeil ",
                    "actions": []
                },
                {
                    "label": "Troubles cognitifs (ex : troubles de la mémoire …) ",
                    "actions": []
                },
                {
                    "label": "Troubles phasiques (communication verbale altérée …)",
                    "actions": []
                }
            ]
        },
        {
            "label": "Cutané et tégumentaire ",
            "problemes": [
                {
                    "label": "Mobilité physique réduite ",
                    "actions": []
                },
                {
                    "label": "Atteinte à l’intégrité des tissus / Troubles trophiques (ex : escarre, fonte musculaire, atteinte de la muqueuse buccale, ",
                    "actions": []
                },
                {
                    "label": "Réaction d’hypersensibilité cutané (ex : prurit …)",
                    "actions": []
                }
            ]
        },
        {
            "label": "Digestif",
            "problemes": [
                {
                    "label": "Alimentation déficiente/ déséquilibre nutritionnel (ex : inappétence …)",
                    "actions": []
                },
                {
                    "label": "Trouble glycémique : hypo-, hyperglycémie",
                    "actions": []
                },
                {
                    "label": "Atteinte de la muqueuse gastro-œsophagienne  ",
                    "actions": []
                },
                {
                    "label": "Motilité gastrique dysfonctionnelle (ex : gastroparésie, présence de nausées / vomissements, reflux …) ",
                    "actions": []
                },
                {
                    "label": "Motilité intestinale dysfonctionnelle :  constipation, iléus, diarrhée, ischémie mésentérique ",
                    "actions": []
                },
                {
                    "label": "Incontinence fécale ",
                    "actions": []
                },
                {
                    "label": "Abdomen aigu",
                    "actions": []
                },
                {
                    "label": "Altération de la fonction hépatique",
                    "actions": []
                },
                {
                    "label": "Altération de la fonction pancréatique",
                    "actions": []
                }
            ]
        },
        {
            "label": "Etat hydro-électrique et rénal ",
            "problemes": [
                {
                    "label": "Altération de l’état hydro-électrolytique : déficit de volume liquidien (déshydratation), excès de volume liquidien (œdème …)",
                    "actions": []
                },
                {
                    "label": "Altération de l’état métabolique (hors trouble échanges gazeux), ex : alcalose sur vomissement …  ",
                    "actions": []
                },
                {
                    "label": "Élimination urinaire altérée (ex : incontinence urinaire, sténose urétrale …)",
                    "actions": []
                },
                {
                    "label": "Insuffisance rénale aigüe/ chronique",
                    "actions": []
                }
            ]
        },
        {
            "label": "Etat psychologique et spirituel ",
            "problemes": [
                {
                    "label": "Anxiété/peur ",
                    "actions": []
                },
                {
                    "label": "Inadaptation à un changement de l’état de santé ",
                    "actions": []
                },
                {
                    "label": "Etat dépressif",
                    "actions": []
                },
                {
                    "label": "Image corporelle altérée ",
                    "actions": []
                },
                {
                    "label": "Atteinte à la dignité humaine",
                    "actions": []
                },
                {
                    "label": "Angoisse face à la mort",
                    "actions": []
                },
                {
                    "label": "Conflit décisionnel ",
                    "actions": []
                },
                {
                    "label": "Perte d’espoir ",
                    "actions": []
                },
                {
                    "label": "Sentiment d’impuissance",
                    "actions": []
                },
                {
                    "label": "Crise situationnelle",
                    "actions": []
                }
            ]
        },
        {
            "label": "Social",
            "problemes": [
                {
                    "label": "Isolement social",
                    "actions": []
                },
                {
                    "label": "Attachement /allaitement ",
                    "actions": []
                },
                {
                    "label": "Dynamique familiale dysfonctionnelle : alcoolisme, dépendance … ",
                    "actions": []
                },
                {
                    "label": "Dynamique familiale perturbée ",
                    "actions": []
                },
                {
                    "label": "Stratégies d’adaptation inefficaces",
                    "actions": []
                },
                {
                    "label": "Connaissances insuffisantes",
                    "actions": []
                },
                {
                    "label": "Tension dans l'exercice du rôle de l'aidant naturel ",
                    "actions": []
                }
            ]
        }
    ]
}

export default EPDSIList