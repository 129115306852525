import React from 'react'
import planCiteHosp from '../../../assets/planCiteHosp.png'
import { Button, Dialog } from '@mui/material'
const Plan = () => {
    const [open, toggle] = React.useReducer(o => !o, false)

    return (
        <React.Fragment>
            <Button onClick={toggle}>Plan Cité hospitalière</Button>
            <Dialog open={Boolean(open)} onClose={toggle} fullWidth maxWidth='lg'>
                <img src={planCiteHosp} width={'100%'} />
            </Dialog>
        </React.Fragment>
    )
}

export default Plan