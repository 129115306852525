import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";

import KanbanLane from "./components/KanbanLane";
import CardItem from "./components/CardItem";
import useKanban, { KanbanContext } from "./useKanban";
import { Card, CardActionArea, Drawer, SwipeableDrawer, easing } from "@mui/material";
import { Add } from "@mui/icons-material";
import CardDrawer from "./components/CardDrawer/CardDrawer";

const Kanban = () => {
    const contextValue = useKanban()

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 2 } }),
        useSensor(TouchSensor),
    );

    return (
        <KanbanContext.Provider value={contextValue} >
            <DndContext
                sensors={sensors}
                onDragStart={contextValue.dispatch.dragStart}
                onDragCancel={contextValue.dispatch.dragCancel}
                onDragOver={contextValue.dispatch.dragOver}
                onDragEnd={contextValue.dispatch.dragEnd}
            >
                <div style={{ height: '100%', width: '100%', overflowY: 'auto' }}>

                    <div style={{ display: 'inline-flex', height: '100%', overflow: 'hidden', alignItems: 'flex-start', gap: 4 }}>
                        {contextValue.kanbans?.map(kanban => (
                            <KanbanLane
                                kanban={kanban}
                                items={kanban.cards}
                                key={kanban.id}
                            />
                        ))}
                        <Card variant="outlined" sx={{
                            m: 1,
                            mt: 10,
                            position: 'fixed',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            right: -145,
                            transition: 'right 150ms ease-out 100ms',
                            '&:hover': {
                                right: -15,
                                transition: 'right 150ms ease-out 100ms'
                            }
                        }}>
                            <CardActionArea sx={{ p: 2, display: 'flex', gap: 1 }} onClick={contextValue.dispatch.addColumn}>
                                <Add />  Ajouter une colonne
                            </CardActionArea>
                        </Card>
                    </div>
                </div>
                <DragOverlay>{contextValue.dragCard ? <CardItem id={contextValue.dragCard.id} card={contextValue.dragCard} dragOverlay /> : null}</DragOverlay>
                <CardDrawer />
            </DndContext>
        </KanbanContext.Provider>
    );
}

export default Kanban;
