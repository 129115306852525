import { Box, Divider, Drawer, IconButton, Tab } from '@mui/material'
import React from 'react'
import { KanbanContext } from '../../useKanban'
import { TabContext, TabList } from '@mui/lab'
import InputField from '../../../InputField'
import DropdownButton from '../../../DropdownButton'
import { Close, Delete, Save } from '@mui/icons-material'
import OverviewTab from './OverviewTab'
import { KanbanCard } from '../../../../Stores/KanbanType'
import CheckListTab from './CheckListTab'
import CommentsTab from './CommentsTab'

const CardDrawer = () => {
    const { editedCard, dispatch } = React.useContext(KanbanContext)
    const [tab, setTab] = React.useState<string>('overview')

    const handleClose = () => dispatch.setEditedCard(null)
    const handleDelete = () => dispatch.deleteCard(editedCard!.id)
    const handleSave = () => dispatch.updateCard(editedCard!)
    const handleUpdate = (partial: Partial<KanbanCard>) => dispatch.setEditedCard({ ...editedCard!, ...partial })

    React.useEffect(() => {
        if (!editedCard) setTab('overview')
    }, [editedCard])
    return (
        <Drawer
            anchor={'right'}
            open={Boolean(editedCard)}
            onClose={handleClose}
        >
            {editedCard &&
                <Box sx={{
                    width: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowY: 'hidden'
                }}>
                    <Box p={2} sx={{ marginTop: 7 }} display={'flex'} justifyContent={'space-between'}>
                        <DropdownButton
                            menuItems={[{ value: 1, primary: 'En cours' }, { value: 2, primary: 'Non débutée' }, { value: 3, primary: 'Finalisée' }]}
                            defaultIndex={editedCard?.status}
                            placeholder='Status'
                            onClick={(value) => value && handleUpdate({ status: value.value })}
                        />
                        <Box display={'flex'} gap={1}>
                            <Box>
                                <IconButton onClick={handleSave} size='small'>
                                    <Save fontSize='small' />
                                </IconButton>
                            </Box>
                            <Box>
                                <IconButton onClick={handleDelete} size='small' color='error'>
                                    <Delete fontSize='small' />
                                </IconButton>
                            </Box>
                            <Box>
                                <IconButton onClick={handleClose} size='small'>
                                    <Close fontSize='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <InputField
                            label="Nom"
                            value={editedCard?.name ?? ''}
                            onChange={({ target: { value: name } }) => handleUpdate({ name })}
                            fullWidth
                        />
                    </Box>
                    <TabContext value={tab}>
                        <TabList onChange={(_, value) => setTab(value)}>
                            <Tab value='overview' label='Synthèse' />
                            <Tab value='checklist' label='Check-List' />
                            <Tab value='comments' label='Commentaires' />
                        </TabList>
                        <Divider />
                        <Box sx={{ overflowY: 'auto' }}>
                            <OverviewTab />
                            <CheckListTab />
                            <CommentsTab />
                        </Box>
                    </TabContext>
                </Box>}
        </Drawer>
    )
}

export default CardDrawer