import React from "react"
import { QuestionType } from "../../../Stores/CoursType"
import { AppContext } from "../../../App"

type useQuizzType = () => useQuizzContextType
type useQuizzContextType = {
    period: number
    loading: boolean
    nbQuestions: number
    canNext: boolean

    questions: QuestionWithResponseType[]
    activeQuestion: QuestionWithResponseType | undefined

    dispatch: useQuizzDispatchType
}
type useQuizzDispatchType = {
    setPeriod: (event: React.ChangeEvent<HTMLInputElement>) => void
    changeNbQuestions: (event: React.ChangeEvent<HTMLInputElement>) => void
    loadQuestions: () => void

    setResponse: (responseId: string) => void
    proceedResponse: () => void
    next: () => void
    restart: () => void
}

type QuestionWithResponseType = QuestionType & { userResponse?: string[], correct?: boolean }


export const QuizzContext = React.createContext<useQuizzContextType>({} as useQuizzContextType)

const useQuizz: useQuizzType = () => {
    const { fetch } = React.useContext(AppContext)
    const [period, setPeriod] = React.useState<number>(0)
    const [nbQuestions, setNbQuestions] = React.useState<number>(0)
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const [questions, setQuestions] = React.useState<QuestionWithResponseType[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)


    const dispatch: useQuizzDispatchType = {
        setPeriod: ({ target: { value } }) => setPeriod(parseInt(value)),
        changeNbQuestions: ({ target: { value } }) => setNbQuestions(parseInt(value)),
        loadQuestions: () => {
            setLoading(true)
            fetch(`/api/admin/epdsiquizz${period || nbQuestions ? `?${period ? `period=${period}` : ''
                }${nbQuestions ? `${period ? '&' : ''}nbQuestions=${nbQuestions}` : ''
                }` : ''}`, 'GET')
                .then(setQuestions)
                .finally(() => setLoading(false))

        },
        setResponse: (responseId) => {
            setQuestions(prev => prev.map((question, index) => index === activeIndex ? {
                ...question,
                userResponse: question.type === 1 ? (question.userResponse ?? []).includes(responseId) ?
                    question.userResponse!.filter(id => id !== responseId) : [...(question.userResponse ?? []), responseId]
                    : [responseId]
            } : question))
        },
        proceedResponse: () => setQuestions(prev => prev.map((question, index) => index === activeIndex ? {
            ...question,
            correct:
                question.reponses.filter(r => r.correct).length === (question.userResponse ?? []).length &&
                question.reponses.filter(r => r.correct).every(r => (question.userResponse ?? []).includes(r.id))


        } : question)),
        next: () => setActiveIndex(prev => prev < questions.length - 1 ? prev + 1 : prev),
        restart: () => {
            setActiveIndex(0)
            setQuestions([])
        }
    }

    React.useEffect(() => {
        if (questions.length > 0 && activeIndex > 0 && activeIndex === questions.length - 3) {
            fetch(`/api/admin/epdsiquizz${period || nbQuestions ? `?${period ? `period=${period}` : ''
                }${nbQuestions ? `${period ? '&' : ''}nbQuestions=${nbQuestions}` : ''
                }` : ''}`, 'GET')
                .then((q: QuestionType[]) => q.filter(qq => !questions.map(quest => quest.id).includes(qq.id)))
                .then((q) => setQuestions(prev => [...prev, ...q]))
        }
    }, [questions, activeIndex])


    return {
        period,
        loading,
        nbQuestions,
        canNext: activeIndex < questions.length - 1,
        questions,
        activeQuestion: questions.length > 0 ? questions[activeIndex] : undefined,
        dispatch
    }
}

export default useQuizz