import React from 'react'
import Page from '../../../../Components/PageInterface/Page'
import { AppContext } from '../../../../App'
import CardTable from '../../../../Components/CardTable/CardTable'
import CoursType from '../../../../Stores/CoursType'
import { Add, Check, Edit, LiveTv, Person, Slideshow } from '@mui/icons-material'
import Adder from './Adder'
import { useNavigate } from 'react-router-dom'
import Avatar169 from '../../../../Components/Avatar169'
import { Avatar, AvatarGroup, Box, Tooltip, Typography } from '@mui/material'
import getWeek from '../../../../utils/getWeek'

const dateTransform = (date: string): string => {
    let dd = date.slice(0, 16).replace(':', '-').replace('T', '-').split('-');
    return [
        [dd[2], dd[1], dd[0]].join('/'),
        [dd[3], dd[4]].join(':')
    ].join(' ')
}

const CoursList = () => {
    const navigate = useNavigate()
    const { fetch, thematics } = React.useContext(AppContext)
    const [cours, setCours] = React.useState<CoursType[]>()

    const [open, toggle] = React.useReducer(o => !o, false)

    React.useEffect(() => {
        fetch('/api/admin/cours', 'GET').then(setCours)
    }, [])
    return (
        <Page
            title={'Cours'}
            subtitle={'Liste des cours'}
            loading={false}
            children={<>
                <Adder {...{ open, toggleOpen: toggle }} />
                <CardTable<CoursType>
                    customFilters={[
                        { name: 'Aujourd\'hui', filter: d => d.filter(c => c.date.startsWith(new Date().toISOString().slice(0, 10))) },
                        { name: 'Cette semaine', filter: d => d.filter(c => getWeek(new Date(c.date)) === getWeek(new Date())) },

                        { name: 'Période 1', filter: d => d.filter(c => c.period === 1) },
                        { name: 'Période 2', filter: d => d.filter(c => c.period === 2) },
                        { name: 'Période 3', filter: d => d.filter(c => c.period === 3) },
                    ]}
                    columns={[
                        {
                            field: 'fileId',
                            header: { label: '', style: { width: '1px' } },
                            body: ({ fileId }) => fileId ? <Avatar169 src={`/download/${fileId}/1`} /> : ''
                        },
                        {
                            field: 'name',
                            header: { label: 'Nom' },
                            body: d => <Box display={'flex'} flexDirection={'column'}><Typography variant={'h6'} >{d.name}</Typography ><Box>{d.subname}</Box></Box>,
                            search: (d, s) => d.name.toLocaleLowerCase().includes(s),
                            order: (d, o) => d.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? o : -o),
                            // initialOrder: 'asc'
                        },
                        { field: 'file', header: { label: <Slideshow />, style: { width: '1px' } }, body: d => d.file ? <Check fontSize={'small'} /> : '' },
                        { field: 'stream', header: { label: <LiveTv />, style: { width: '1px' } }, body: d => d.stream ? <Check fontSize={'small'} /> : '' },
                        {
                            field: 'intervenants',
                            header: { label: <Person />, style: { width: '1px' } },
                            body: d => <Box><AvatarGroup>{d.intervenants.map(i => <Tooltip key={i.id} title={`${i.firstname} ${i.lastname}`} ><Avatar alt={`${i.firstname} ${i.lastname}`} src={i.imageId ? `/download/${i.imageId}` : undefined} /></Tooltip>)}</AvatarGroup></Box>
                        },
                        {
                            field: 'thematic',
                            header: { label: 'Thématique', style: { width: '1px' } },
                            body: (data) => data.thematic ? <Box><Tooltip title={data.thematic.name}><Avatar src={`/download/${data.thematic.imageId}`} alt={data.thematic.name} /></Tooltip></Box> : '',
                            search: (d, s) => (d.thematic?.name ?? '').toLocaleLowerCase().includes(s)
                        },
                        { field: 'period', header: { label: 'Période', style: { width: '1px' } }, order: (d, o) => d.sort((a, b) => a.period < b.period ? o : -o) },
                        { field: 'date', header: { label: 'Date', style: { width: '1px' } }, body: d => <Box textAlign={'right'}>{dateTransform(d.date)}</Box>, order: (d, o) => d.sort((a, b) => new Date(a.date) < new Date(b.date) ? o : -o), search: (d, s) => dateTransform(d.date).includes(s), initialOrder: 'asc' },
                    ]}
                    datas={cours ?? []}
                    buttons={[
                        { isHeader: true, rounded: true, onClick: toggle, label: 'Ajouter un cours', icon: Add },
                        { rounded: true, onClick: (el) => navigate(`/epdsi/cours/${el.id}`), label: 'Editer', icon: Edit },
                    ]}
                    noCard={true}
                    secondaryFilters={thematics?.map(t => ({ name: t.name, filter: d => d.filter(c => c.thematicId === t.id) }))}

                />
            </>}
            fullPage
        />
    )
}

export default CoursList