import React from "react"
import { AuthTokensType, AuthUserType, FetchType, useAuthDispatchType, useAuthType } from "./types"
import jwtDecode from "jwt-decode"

const oFetch = window.fetch

const useAuth: useAuthType = (db) => {
    const [tokens, setTokens] = React.useState<AuthTokensType | undefined>(undefined)
    const user = React.useMemo(() => tokens ? jwtDecode<AuthUserType | null>(tokens.accessToken) : null, [tokens])

    const fetch: FetchType<any> = (url, method, body, killSignal, repeat = true, newAccessToken) => {
        //@ts-ignore
        return window.fetch(url, {
            method: method.toLocaleUpperCase(),
            headers: {
                Authorization: `Bearer ${newAccessToken ? newAccessToken : tokens?.accessToken ?? ''}`,
                Accept: 'application/json',
                ...(body && body instanceof FormData ? {} : { 'Content-Type': 'application/json' })
            },
            body: body ? body instanceof FormData ? body : JSON.stringify(body) : undefined
        }).then(async (resp) => {
            if (resp.status === 200 || resp.status === 201) return resp.json()
            if (resp.status === 204) return { success: true }
            if (resp.status === 401 && repeat) {
                return oFetch('/auth/refresh', { method: 'POST', body: JSON.stringify({ refreshToken: tokens?.refreshToken ?? '' }), headers: { 'Content-type': 'application/json' } })
                    .then(resp => resp.json())
                    .then(async (resp: undefined | AuthTokensType) => {
                        if (resp?.accessToken) {
                            setTokens({ ...tokens, ...resp })
                            return new Promise((resolve) => {
                                setTimeout(function () {
                                    resolve('done')
                                }, 100)
                            }).then(() => fetch(url, method, body, killSignal, false, resp.accessToken))
                        } else {
                            setTokens(undefined)
                            return undefined
                        }
                    })
                    .catch(e => { setTokens(undefined) })
            }
            if (resp.status === 401 && !repeat) {
                setTokens(undefined)
            }
            return undefined
        }).catch(e => { setTokens(undefined) })
    }

    const dispatch: useAuthDispatchType = {
        signIn: async () => { throw new Error('signIn not implemented') },
        logIn: async (email, password) => fetch('/auth/login', 'POST', { email, password }).then(resp => resp.accessToken && resp.refreshToken && setTokens(resp)),
        logOut: () => { setTokens(null); setTimeout(function () { window.location.href = '/' }, 100) }
    }

    React.useEffect(() => {
        if (tokens !== undefined) {
            if (tokens) {
                db.post('auth', { id: 0, ...tokens })
            } else {
                db.clear('auth')
            }
        }
    }, [tokens])

    React.useEffect(() => {
        db.get('auth', 0).then(idbTokens => {
            if (idbTokens) {
                setTokens({ accessToken: idbTokens.accessToken, refreshToken: idbTokens.refreshToken })
            }
        })
    }, [])

    return {
        user,
        tokens,
        dispatch,
        fetch
    }
}

export default useAuth