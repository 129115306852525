import React from 'react'
import Page from '../../../../Components/PageInterface/Page'
import { useParams } from 'react-router-dom'
import ExploitationPratiqueType from '../../../../Stores/ExploitationPratiqueType'
import useEpEdit, { UseEpEditContext } from './useEpsEdit'
import EPSetting from './Settings/EPSetting'
import EPIpas from './IPAS/EPIpas'
import FileUploader from '../../../../Components/FileUploader'
import EPFiles from './Files/EPFiles'
import { Button } from '@mui/material'
import { AppContext } from '../../../../App'
import AnaPrimaire from './Analyse/Primaire/AnaPrimaire'
import Drawer from './Drawer/Drawer'
import Plannif from './Analyse/Plannif/Plannif'
import Logo from '../../../../assets/epdsi.png'
import EpEcg from './Ecg/EpEcg'
import { Tab } from '../../../../Components/PageInterface'
import EPTraitements from './Traitements/EPTraitements'
import PlannifTab from './Plannification/PlannifTab'

export const EpsEdit = () => {
    const { id } = useParams<{ id: ExploitationPratiqueType['id'] }>()
    const { fetch } = React.useContext(AppContext)

    const contextValue = useEpEdit(id!)

    const handleClick = () => fetch(`/api/admin/exploitations-pratiques/${id}/download`, 'GET').then(buffer => {
        const el = document.createElement('A')
        el.setAttribute('href', `data:application/octet-stream;base64,${buffer.buffer}`)
        el.setAttribute('download', `Exploitation de la pratique ${contextValue.ep!.name}.docx`)
        el.click()
    })
    return (
        <UseEpEditContext.Provider value={contextValue}>
            <Page
                title={contextValue.ep ? contextValue.ep.name : 'Analyse de situation'}
                subtitle={contextValue.ep ? 'Analyse de situation' : undefined}
                rightPlace={contextValue.ep ? <Button onClick={handleClick}>Télécharger</Button> : undefined}
                loading={!contextValue.ep || !contextValue.problems}
                breadcrumb={[
                    { url: '/epdsi', name: 'EPD SI', src: Logo },
                    { url: '/epdsi/eps', name: 'Exploitations de la pratique', letters: 'EP' },
                ]}
                tabs={[
                    { label: 'Généralités', component: <EPSetting /> },
                    { label: 'IPAS', component: <EPIpas /> },
                    ...(contextValue.ep && contextValue.ep.config?.includes('dds')
                        ? [
                            { label: 'Grille d’analyse', component: <AnaPrimaire /> },
                            { label: 'Priorités', component: <Plannif field={'priority'} label={'Priorités'} /> },
                            { label: 'Soins et surveillances ', component: <Plannif field={'soins'} label="Soins et surveillance par systèmes" /> },
                            { label: 'Visite ', component: <Plannif field={'visite'} label="Visite médico-infirmière" /> },
                            { label: 'PEC psycho-sociale ', component: <Plannif field={'social'} label="Prise en charge psycho-sociale" /> }
                        ]
                        : [undefined]),
                    (contextValue.ep && contextValue.ep.config?.includes('ecg')
                        ? { label: 'ECG', component: <EpEcg /> }
                        : undefined),
                    (contextValue.ep && contextValue.ep.config?.includes('images')
                        ? { label: 'Images', component: <EPFiles /> }
                        : undefined),
                    (contextValue.ep && contextValue.ep.config?.includes('ttt')
                        ? { label: 'Traitements', component: <EPTraitements /> }
                        : undefined),
                    (contextValue.ep && contextValue.ep.config?.includes('plannif')
                        ? { label: 'Plannification', component: <PlannifTab /> }
                        : undefined),

                ].filter(t => t !== undefined) as Tab[]}
                fullPage
                tabButton={<Drawer />}
            />
        </UseEpEditContext.Provider>
    )
}
