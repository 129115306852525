import { Box, BoxProps, styled } from "@mui/material"
import UiContext from "./UiContext"
import React from "react"

interface styledBoxProps extends BoxProps {
    open: boolean
}
const StyledBox = styled(Box)<styledBoxProps>(({ theme, open }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(7),
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(7),
        height: '100%',
    },
    transition: theme.transitions.create('paddingLeft', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 300
        },
    })
}))


const MainContent = ({ children }: React.PropsWithChildren) => {
    const { open } = React.useContext(UiContext)
    return (
        <StyledBox open={open}>
            {children}
        </StyledBox>
    )
}

export default MainContent