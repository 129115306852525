import { Box, LinearProgress } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'

const Progress = () => {
    const { progress, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <Box
            sx={{
                position: 'absolute',
                top: -2,
                left: 0,
                right: 0,
            }}
        >
            <LinearProgress
                variant={progress === undefined ? undefined : 'determinate'}
                value={progress}
                onClick={({ currentTarget, clientX }) => {
                    const { left, right } = currentTarget.getBoundingClientRect()
                    const percent = (clientX - left) / (right - left)
                    dispatch.seek(percent)
                }}
            />
        </Box>

    )
}

export default Progress