import React from "react"

export type UiContextType = {
    // drawerWidth: drawerWidthType,
    open: boolean,
    toggleOpen: React.DispatchWithoutAction
}
export type drawerWidthType = number | string
const UiContext = React.createContext<UiContextType>({} as UiContextType)

export default UiContext