import React from 'react'
import Page from '../../Components/PageInterface'
import CardTable from '../../Components/CardTable/CardTable'
import { AppContext } from '../../App'
import { Box, Button, Chip } from '@mui/material'
import { Add, Edit, MenuBook } from '@mui/icons-material'

import ChuvIcon from '../../assets/favicon.ico'
import { useNavigate } from 'react-router-dom'

const ChuvGuideListe = () => {
    const { chapitres, fetch, dispatch } = React.useContext(AppContext)
    const navigate = useNavigate()
    const handleEdit = (id: string) => navigate(`/chuv/guide-de-poche/${id}`)

    const addChapitre = () => {
        fetch('/api/admin/chapitres', 'POST').then(({ id }) => handleEdit(id))
    }

    React.useEffect(() => {
        dispatch.chapitres.getAll()
    }, [])

    return (
        <Page
            loading={!chapitres}
            title={'Guide de poche'}
            subtitle='Liste des chapitres'
            breadcrumb={[
                { url: '/chuv', name: 'CHUV', src: ChuvIcon },
                { url: '/chuv/guide-de-poche', name: 'Guide de poche', icon: MenuBook }
            ]}
            rightPlace={<Box>
                <Button variant='contained' onClick={addChapitre}><Add /> Ajouter <br />un chapitre</Button>
            </Box>}
            children={<CardTable
                columns={[
                    { field: 'position', header: { label: '#' }, body: (data) => <>{data.position + 1}</> },
                    { field: 'name', header: { label: 'Nom du chapitre' }, search: (data, search) => data.name.toLocaleLowerCase('fr').includes(search) },
                    { field: 'status', header: { label: '' }, body: (data) => data.status === 2 ? <Chip label="Finalisé" size='small' variant="outlined" color='success' /> : data.status === 1 ? <Chip label="MAJ" size='small' variant="outlined" color='warning' /> : <Chip label="A faire" size='small' variant="outlined" color='error' /> },
                ]}
                datas={chapitres?.filter(c => c.parentId === null).sort((a, b) => a.position < b.position ? -1 : 1) ?? []}
                buttons={[
                    { icon: Edit, label: 'Editer', onClick: ({ id }) => handleEdit(id), rounded: true }
                ]}
                noCard
                customFilters={[
                    { name: 'A faire', filter: (datas) => datas.filter(d => d.status === 0) },
                    { name: 'A mettre à jour', filter: (datas) => datas.filter(d => d.status === 1) },
                    { name: 'Finalisé', filter: (datas) => datas.filter(d => d.status === 2) }
                ]} />}
            fullPage />
    )
}

export default ChuvGuideListe