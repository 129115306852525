import { Add } from '@mui/icons-material'
import { Box, Button, Divider, MenuItem } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { UseEpEditContext } from '../useEpsEdit'
import DeleteButton from '../../../../../Components/DeleteButton'
import Analyse from './Analyse'

const EpEcg = () => {
    const { ep, activeEcg, dispatch } = React.useContext(UseEpEditContext)
    const inputRef = React.useRef<HTMLInputElement>(null)

    const handleAddFileClick = () => inputRef.current?.click()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (!file) return
        dispatch.addEcg(file)
        inputRef.current?.value && (inputRef.current.value = '')
    }

    return (
        <Box sx={{ height: '100%', overflowY: 'auto' }} display={'flex'} flexDirection={'column'}>
            <Box sx={{ ml: 1, mr: 1 }}>
                <Button startIcon={<Add />} onClick={handleAddFileClick}>Ajouter un ECG</Button>
                <input type='file' ref={inputRef} hidden accept='image/*' onChange={handleChange} />
            </Box>
            <Divider />
            <Box sx={{ m: 1 }}>
                <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                    <Box>
                        <InputField
                            label="Choisir un ECG"
                            select
                            sx={{ minWidth: 200 }}
                            disabled={ep?.ecgs.length === 0}
                            value={activeEcg?.id ?? ''}
                            onChange={({ target: { value } }) => dispatch.setActiveEcg(value)}
                        >
                            {ep?.ecgs.length === 0 && <MenuItem value={''}>Aucun ECG</MenuItem>}
                            {ep?.ecgs.map((ecg, i) => <MenuItem key={ecg.id} value={ecg.id}>{`ECG ${i + 1}`}</MenuItem>)}
                        </InputField>
                    </Box>
                    {activeEcg && <React.Fragment>
                        <Box>
                            <DeleteButton onDelete={() => dispatch.deleteEcg()} variant={'outlined'} square confirm />
                        </Box>
                    </React.Fragment>}
                </Box>
                {activeEcg && <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} mt={2}>
                    <Box flexGrow={1}>
                        <img src={`/download/${activeEcg?.ecgId}`} alt="" style={{ width: '100%' }} />
                    </Box>
                    <Analyse key={activeEcg.id} />
                </Box>}
            </Box>
        </Box>
    )
}

export default EpEcg