import { Box, Button } from '@mui/material'
import React from 'react'
import { DocumentContext } from '../useDocument'
import { AppContext } from '../../../../../App'
import { PageContext } from '../../../../../Components/PageInterface/Page'
import SpeechRecognition from '../../../../../Components/SpeechRecognition/SpeechRecognition'
import VideoPlayer from '../../../../../Components/VideoPlayer/VideoPlayer'


const TabStream = () => {
    const { fetch } = React.useContext(AppContext)
    const { cours, dispatch } = React.useContext(DocumentContext)
    const { openFileSelector } = React.useContext(PageContext)

    const handleStreamLink = () => fetch(`/api/admin/cours/${cours?.id}/streamLink`, 'GET').then(({ token }) => {
        const addr = `?sign=${token}&coursId=${cours?.id}`
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(addr);
        }
    })

    const handleSelectFile = () => openFileSelector({
        title: 'Choisir une vidéo',
        files: cours?.files.filter(f => f.mime.includes('video')) ?? [],
        onSelect: file => dispatch.selectStream(file),
        close: () => openFileSelector(null)
    })
    return (
        <Box display={'flex'} flexDirection={'column'} p={1} gap={2} sx={{ overflowY: 'auto', overflowX: 'hidden', height: '100%' }} alignItems={'center'}>
            <Box width={'100%'}>
                <Button onClick={handleStreamLink}>Obtenir{cours?.stream ? ' un nouveau ' : ' '}lien de stream</Button>
                {cours?.stream && <Button onClick={dispatch.removeStream}>Retirer le stream</Button>}
                {!cours?.stream && cours && cours?.files.filter(f => f.mime.includes('video')).length > 0 && <Button onClick={handleSelectFile}>Choisir un fichier</Button>}
                {/* <SpeechRecognition /> */}
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ width: '50vw' }}>
                {/* {cours?.stream && <video src={`/download/${cours.stream.id}`} controls style={{ height: '50vh' }} />} */}
                {cours?.stream && <VideoPlayer videoId={cours?.stream?.id} />}
            </Box>
        </Box>
    )
}

export default TabStream