import React from 'react';
import useStore from './Stores/useStore';
import { ThemeProvider } from '@mui/material';
import { useStoreContextValueType } from './Stores/types';
import Router from './Components/Router/Router';
import Login from './Pages/Security/Login';

export const AppContext = React.createContext<useStoreContextValueType>({} as useStoreContextValueType)

const App = () => {
  const value = useStore()

  React.useEffect(() => {
    window.document.body.style.backgroundColor = '#1876d224'
  }, [])

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={value.theme}>
        {value.user ? <Router /> : <Login />}
      </ThemeProvider>
    </AppContext.Provider>

  );
}

export default App;
