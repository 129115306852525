import { Box, Grid } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { UseEpEditContext } from '../useEpsEdit'
import DeleteButton from '../../../../../Components/DeleteButton'
import ConfigMenu from '../ConfigMenu'

const EPSetting = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)

    const [name, setName] = React.useState(ep?.name ?? '')

    React.useEffect(() => {
        setName(ep?.name ?? '')
    }, [ep])
    return (
        <Box sx={{ height: '100%', overflowY: 'auto', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'Date de l\'exploitation de la pratique'}
                        type='date'
                        value={(ep?.date ?? new Date().toISOString()).split('T')[0]}
                        onChange={dispatch.changeDate}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'IPP du patient'}
                        value={ep?.ipp ?? ''}
                        onChange={dispatch.changeIpp}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DeleteButton onDelete={dispatch.deleteEp} confirm />
                    <ConfigMenu />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <InputField
                        label={'Date du recueil'}
                        type='date'
                        value={(ep?.dateRecueil ?? new Date().toISOString()).split('T')[0]}
                        onChange={dispatch.changeDateRecueil}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <InputField
                        label={'Nom du patient'}
                        value={name}
                        onChange={({ target: { value } }) => setName(value)}
                        onBlur={({ target: { value } }) => dispatch.changeName(value)}
                        /* @ts-ignore */
                        onKeyUp={({ key, target: { value } }) => key === 'Enter' && dispatch.changeName(value)}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EPSetting