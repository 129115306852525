import React from 'react'
import { configSelectorType } from './types'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Box, Card, DialogActions, CardActionArea, Tooltip } from '@mui/material'
import { Close } from '@mui/icons-material'
import File from '../../Stores/FileType'

interface FileSelectorProps {
    config: configSelectorType
}
const FileSelector = ({ config }: FileSelectorProps) => {
    const [active, setActive] = React.useState<string | null>(null)

    const onValidate = () => {
        config?.onSelect(config.files.find(f => f.id === active)!)
        config?.close()
    }

    React.useEffect(() => {
        setActive(config?.active ?? null)
    }, [config])
    return (
        <Dialog
            fullScreen
            open={Boolean(config)}
            onClose={config?.close}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={config?.close}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {config?.title ?? ''}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={1} justifyContent={'space-between'} m={1} sx={{ overflowY: 'auto' }}>
                {config?.files.map(file => <FileImage key={file.id} file={file} isActive={file.id === active} onSelect={setActive} onValidate={onValidate} />)}
            </Box>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

interface FileItemProps {
    file: File,
    isActive: boolean,
    onSelect: React.Dispatch<React.SetStateAction<string | null>>
    onValidate: () => void
}
const FileImage = ({ file, isActive, onSelect, onValidate }: FileItemProps) => {
    const handleSelect = () => onSelect(file.id)
    return (
        <Box
            sx={{
                aspectRatio: '16/9',
                width: 400,
                backgroundColor: theme => isActive
                    ? theme.palette.primary.main
                    : undefined,
                borderRadius: theme => theme.spacing(3)
            }}
        >
            <Tooltip
                title={file.name}>
                <Card
                    variant={'outlined'}
                    sx={{
                        aspectRatio: `16/9`,
                        backgroundImage: file.mime.includes('pdf')
                            ? `url('/download/${file.id}/1')`
                            : file.mime.includes('image')
                                ? `url('/download/${file.id}')`
                                : undefined,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        m: .5
                    }}
                >
                    <CardActionArea
                        onClick={handleSelect}
                        sx={{ height: '100%' }}
                        onDoubleClick={onValidate}
                    >
                        {file.mime.includes('video') && <video src={`/download/${file.id}`} controls style={{ width: '100%' }} />}

                    </CardActionArea>
                </Card>
            </Tooltip>
        </Box>
    )
}

export default FileSelector