import React from 'react'
import InputField from '../../../Components/InputField'
import { Box, Button, MenuItem } from '@mui/material'
import { QuizzContext } from './useQuizz'

const Config = () => {
    const { period, nbQuestions, dispatch } = React.useContext(QuizzContext)
    return (
        <Box display={'flex'} flexDirection={"column"} gap={2} alignItems={'center'} justifyContent={'center'} minWidth={{ xs: '100%', md: 'auto' }}>
            <InputField
                label={'Période'}
                value={period}
                onChange={dispatch.setPeriod}
                select
                fullWidth

            >
                <MenuItem value={0}>Toutes les périodes</MenuItem>
                <MenuItem value={1}>Période 1</MenuItem>
                <MenuItem value={2}>Période 2</MenuItem>
                <MenuItem value={3}>Période 3</MenuItem>
            </InputField>

            <InputField
                label={'Nombre de questions'}
                value={nbQuestions === 0 ? '' : nbQuestions}
                onChange={dispatch.changeNbQuestions}
                fullWidth
            />

            <Button onClick={dispatch.loadQuestions}>
                Commencer
            </Button>
        </Box>
    )
}

export default Config