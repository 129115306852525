import React from "react"
import { useVideoPlayerContextValueType, useVideoPlayerDispatchType, useVideoPlayerType, videoPlayerStatusType } from "./types"

export const VideoPlayerContext = React.createContext<useVideoPlayerContextValueType>({} as useVideoPlayerContextValueType)

const useVideoPlayer: useVideoPlayerType = (props) => {
    const videoRef = React.useRef<HTMLVideoElement>(null)
    const compRef = React.useRef<HTMLDivElement>(null)

    const [totalTime, setTotalTime] = React.useState<undefined | number>(0)
    const [currentTime, setCurrentTime] = React.useState<undefined | number>(0)
    const [status, setStatus] = React.useState<videoPlayerStatusType>("loading")
    const [fullScreen, setFullScreen] = React.useState<boolean>(false)
    const [buffered, setBuffered] = React.useState<undefined | number>(0)
    const [showControls, setShowControls] = React.useState<boolean>(false)

    const dispatch: useVideoPlayerDispatchType = {
        fullScreen: () => setFullScreen(true),
        exitFullScreen: () => setFullScreen(false),

        play: () => videoRef.current?.play(),
        pause: () => videoRef.current?.pause(),
        seek: (percent) => {
            if (totalTime && videoRef.current) {
                videoRef.current.currentTime = totalTime * percent
            }
        },
        restart: () => {
            if (videoRef.current) {
                videoRef.current.currentTime = 0
                dispatch.play()
            }
        },
        setVolume: (volume) => {
            if (videoRef.current) {
                videoRef.current.volume = volume
            }
        },
        setPlaybackRate: (playbackRate) => {
            if (videoRef.current) {
                videoRef.current.playbackRate = playbackRate
            }
        }
    }


    React.useEffect(() => {

        videoRef.current?.addEventListener("loadedmetadata", (any: any) => {
            setStatus("paused")
            setTotalTime(videoRef.current?.duration)
        })

        videoRef.current?.addEventListener("timeupdate", (any: any) => {
            setCurrentTime(videoRef.current?.currentTime)
        })

        videoRef.current?.addEventListener("ended", (any: any) => {
            setStatus("ended")
        })

        videoRef.current?.addEventListener("error", (any: any) => {
            setStatus("error")
        })

        videoRef.current?.addEventListener("play", (any: any) => {
            setStatus("playing")
        })

        videoRef.current?.addEventListener("pause", (any: any) => {
            setStatus("paused")
        })

        videoRef.current?.addEventListener("waiting", (any: any) => {
            setStatus("loading")
        })

        videoRef.current?.addEventListener("playing", (any: any) => {
            setStatus("playing")
        })

        videoRef.current?.addEventListener("canplay", (any: any) => {
            setStatus("paused")
        })
        return () => { }
    }, [videoRef.current])

    React.useEffect(() => {
        let timer: NodeJS.Timer | null = null
        const onMouseMove = (e: MouseEvent) => {
            if (timer) clearTimeout(timer)
            setShowControls(true)
            timer = setTimeout(() => {
                setShowControls(false)
            }, 3000)
        }

        compRef.current?.addEventListener("mousemove", onMouseMove)

        return () => {
            compRef.current?.removeEventListener("mousemove", onMouseMove)
            if (timer) clearTimeout(timer)
        }
    }, [compRef.current])

    return {
        videoPath: `/download/${props.videoId}`,
        status,
        fullScreen,
        progress: totalTime === undefined || currentTime === undefined ? undefined : (currentTime / totalTime) * 100,
        currentTime,
        totalTime,
        showControls,
        volume: videoRef.current?.volume,
        playbackRate: videoRef.current?.playbackRate,

        videoRef,
        compRef,

        dispatch
    }
}

export default useVideoPlayer