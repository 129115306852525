import { Box, TextField, InputAdornment, Divider, IconButton, MenuItem } from '@mui/material'
import { Close, Search as SearchIcon } from '@mui/icons-material'
import React from 'react'
import { TableContext } from '../useTable'
import InputField from '../../InputField'

const Search = () => {
    const { search, dispatch, secondaryFilters, secondaryFilterSelected } = React.useContext(TableContext)
    return (
        <>
            <Box p={3} display={'flex'} gap={2}>
                <TextField
                    value={search}
                    onChange={({ target: { value } }) => dispatch.setSearch(value)}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        endAdornment: search && <InputAdornment position="end"><IconButton onClick={() => dispatch.setSearch('')}><Close /></IconButton></InputAdornment>
                    }}
                />
                {secondaryFilters && <InputField
                    label='Filtre secondaire'
                    select
                    value={secondaryFilterSelected}
                    onChange={({ target: { value } }) => dispatch.setSecondaryFilter(Number(value))}
                    style={{ width: 200 }}
                >
                    <MenuItem value={-1}>Aucun</MenuItem>
                    {secondaryFilters.map((filter, index) => <MenuItem key={index} value={index}>{filter.name}</MenuItem>)}
                </InputField>}
                {/* <InputField
                    label='test'
                    select
                    style={{ width: 200 }}
                >
                    <MenuItem value={1}>Last updated (newest)</MenuItem>
                    <MenuItem value={2}>Last updated (oldest)</MenuItem>
                    <MenuItem value={3}>Total orders (newest)</MenuItem>
                    <MenuItem value={4}>Total orders (oldest)</MenuItem>
                </InputField> */}
            </Box>
            <Divider />
        </>
    )
}

export default Search