import { Avatar, Box, Dialog, IconButton, Typography } from '@mui/material'
import React from 'react'
import File from '../../../../../Stores/FileType'
import { Delete } from '@mui/icons-material'
import { UseEpEditContext } from '../useEpsEdit'

interface FileListProps {
    files: File[]
}
const FileList = ({ files }: FileListProps) => {
    const [open, setOpen] = React.useState<File | undefined>()
    const { dispatch } = React.useContext(UseEpEditContext)
    return (
        <Box display={'flex'} sx={{ mb: 'auto', height: '100%', overflowY: 'auto', flexDirection: 'column', gap: 1 }}>
            {files.map(file =>
                <Box
                    key={file.id}
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                    sx={{
                        '& [role="buttons"]': { visibility: 'hidden' },
                        '&:hover [role="buttons"]': { visibility: 'visible' }
                    }}
                >
                    <Avatar
                        variant="square"
                        src={`/download/${file.id}`}
                        alt={file.name}
                        onClick={() => setOpen(file)}
                    />
                    <Typography variant="caption">{file.name}</Typography>
                    <Box role={"buttons"} sx={{ ml: 'auto' }}>
                        <IconButton color='error' onClick={() => dispatch.deleteFile(file.id)}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>)}
            <Dialog
                open={Boolean(open)}
                onClose={() => setOpen(undefined)}>
                {open &&
                    <>
                        <img src={`/download/${open.id}`} />
                        <Typography textAlign={'center'}>{open.name}</Typography>
                    </>}
            </Dialog>
        </Box>
    )
}

export default FileList