import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import CardItemSortable from "./CardItemSortable";

import { KanbanContext } from "../useKanban";
import { Box, Button, Card, CardActions, CardContent, CardHeader, IconButton } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Kanban, KanbanCard } from "../../../Stores/KanbanType";
import LabelInput from "../../LabelInput";

interface KanbanLaneProps {
    kanban: Kanban
    items: KanbanCard[]
}
const KanbanLane = ({ kanban, items }: KanbanLaneProps) => {
    const { dispatch } = React.useContext(KanbanContext)
    const { setNodeRef } = useDroppable({ id: kanban.id });
    const [name, setName] = React.useState<string>(kanban.name)

    const handleAddCard = () => dispatch.addCard(kanban.id)
    const handleEditKanban = () => dispatch.updateColumn(kanban.id, { name })
    // const handleDeleteKanban = () => dispatch.de

    React.useEffect(() => {
        setName(kanban.name)
    }, [kanban])
    return (
        <SortableContext id={kanban.id} items={items} strategy={rectSortingStrategy}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: theme => theme.palette.grey[50],
                    maxHeight: theme => `calc(100% - ${theme.spacing(2)})`,
                    flexDirection: 'column',
                    overflow: 'hidden',
                    display: 'flex',
                    width: 300,
                    m: 1,
                    '& .MuiCardActions-root,& .visible-hover': { visibility: 'hidden' },
                    '&:hover .MuiCardActions-root, &:hover .visible-hover': { visibility: 'visible' },
                }}>
                <CardHeader title={<Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'}>
                    <Box flexGrow={1}><LabelInput value={name} onChange={setName} onValidate={handleEditKanban} /></Box>
                    <Box className="visible-hover">
                        <IconButton color="error" size="small">
                            <Delete fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>} />
                <CardContent sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }} ref={setNodeRef}>
                    {items.map((item) => (
                        <CardItemSortable key={item.id} id={item.id} card={item} />
                    ))}
                </CardContent>
                <CardActions>
                    <Button onClick={handleAddCard} size="small"><Add fontSize="small" />  tâche</Button>
                    {/* <Button onClick={handleEditKanban} size='small'><Edit fontSize="small" />  editer</Button> */}
                </CardActions>
            </Card>
        </SortableContext>
    );
};

export default KanbanLane;
