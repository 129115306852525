import React from 'react'
import Page from '../../../Components/PageInterface/Page'
import { AppContext } from '../../../App'

import ChuvIcon from '../../../assets/favicon.ico'
import { Edit, Restaurant, ToggleOff, ToggleOn } from '@mui/icons-material'
import { Box } from '@mui/material'
import CardTable from '../../../Components/CardTable/CardTable'
import { useNavigate } from 'react-router-dom'


const ListNutritiveSolution = () => {
    const navigate = useNavigate()
    const { nutritiveSolutions } = React.useContext(AppContext)

    const handleEdit = (id: string) => navigate(`/chuv/nutritive-solutions/${id}`)

    return (
        <Page
            title={'Solutions nutritives'}
            breadcrumb={[
                { url: '/chuv', name: 'CHUV', src: ChuvIcon },
                { url: '/chuv/nutritive-solutions', name: 'Solutions nutritives', icon: Restaurant }
            ]}
            loading={!nutritiveSolutions}
            children={<CardTable
                columns={[
                    { field: 'isActif', header: { label: '', style: { width: '1px' } }, body: (data) => data.isActif ? <ToggleOn color='primary' /> : <ToggleOff color='secondary' /> },
                    { field: 'name', header: { label: 'Nom de la solution' }, search: (data, search) => data.name.toLocaleLowerCase('fr').includes(search) },
                    { field: 'type', header: { label: 'Type', style: { textAlign: 'right' } }, body: data => <Box textAlign='right'>{data.type}</Box> },
                ]}
                datas={nutritiveSolutions?.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1) ?? []}
                buttons={[
                    { icon: Edit, label: 'Editer', onClick: ({ id }) => handleEdit(id), rounded: true }
                ]}
                noCard
                customFilters={[
                    { name: "N. parentérale", filter: (datas) => datas.filter(d => d.type === "N. parentérale") },
                    { name: "Supplément oral", filter: (datas) => datas.filter(d => d.type === "Supplément oral") },
                    { name: "Supplément parentéral", filter: (datas) => datas.filter(d => d.type === "Supplément parentéral") },
                    { name: "Nutrition entérale", filter: (datas) => datas.filter(d => d.type === "Nutrition entérale") },
                    { name: "Solutés d'entretien", filter: (datas) => datas.filter(d => d.type === "Solutés d'entretien") },
                    { name: "Supplément entéral", filter: (datas) => datas.filter(d => d.type === "Supplément entéral") },
                    { name: "Sédatif", filter: (datas) => datas.filter(d => d.type === "Sédatif") }
                ]}
                secondaryFilters={[
                    { name: "Actifs", filter: (datas) => datas.filter(d => d.isActif) },
                    { name: "Archivés", filter: (datas) => datas.filter(d => !d.isActif) },
                ]} />}
            fullPage
        />
    )
}

export default ListNutritiveSolution