import { BrowserRouter, Route, Routes, createBrowserRouter } from "react-router-dom";
import { Dashboard } from "../../Pages/Dashboard";
import Ui from "../Interface";
import KanbanPage from "../../Pages/KanbanPage";
import CHUVDashboard from "../../Pages/CHUV/CHUVDashboard";
import ChuvGuideListe from "../../Pages/CHUV/ChuvGuideListe";
import ChuvEditChapitre from "../../Pages/CHUV/EditChapitre/Edit";
import ListNutritiveSolution from "../../Pages/CHUV/NutritiveSolution/ListNutritiveSolution";
import EditNutritiveSolution from "../../Pages/CHUV/NutritiveSolution/Edit/EditNutritiveSolution";
import InvoicesList from "../../Pages/Invoices/List/InvoicesList";
import InvoiceEdit from "../../Pages/Invoices/Edit/InvoiceEdit";
import EpdSiDashboard from "../../Pages/EPDSI/Dashboard/Dashboard";
import ECsList from "../../Pages/EPDSI/ECs/List/ECsList";
import ECsEdit from "../../Pages/EPDSI/ECs/Edit/Edit";
import EPsList from "../../Pages/EPDSI/EPs/List/EPsList";
import { EpsEdit } from "../../Pages/EPDSI/EPs/Edit/EpsEdit";
import CoursList from "../../Pages/EPDSI/Cours/List/CoursList";
import CoursEdit from "../../Pages/EPDSI/ECs/List/ECsList";
import EditCours from "../../Pages/EPDSI/Cours/Edit/EditCours";
import NECalculator from "../../Pages/CHUV/NutritiveSolution/Calcul/NECalculator";
import Quizz from "../../Pages/EPDSI/Quizz/Quizz";


const Router = () => {
    return (
        <BrowserRouter>
            <Ui>
                <Routes>
                    <Route path="/" element={<Dashboard />} />

                    <Route path="/aleph/kanban" element={<KanbanPage />} />

                    <Route path="/aleph/invoices" element={<InvoicesList />} />
                    <Route path="/aleph/invoices/:id" element={<InvoiceEdit />} />

                    <Route path="/chuv" element={<CHUVDashboard />} />

                    <Route path="/chuv/guide-de-poche" element={<ChuvGuideListe />} />
                    <Route path="/chuv/guide-de-poche/:id" element={<ChuvEditChapitre />} />

                    <Route path="/chuv/nutritive-solutions" element={<ListNutritiveSolution />} />
                    <Route path="/chuv/nutritive-solutions/calculator" element={<NECalculator />} />
                    <Route path="/chuv/nutritive-solutions/:id" element={<EditNutritiveSolution />} />

                    <Route path="/epdsi" element={<EpdSiDashboard />} />
                    <Route path="/epdsi/cours" element={<CoursList />} />
                    <Route path="/epdsi/cours/:id" element={<EditCours />} />
                    <Route path="/epdsi/ecs" element={<ECsList />} />
                    <Route path="/epdsi/ecs/:id" element={<ECsEdit />} />
                    <Route path="/epdsi/eps" element={<EPsList />} />
                    <Route path="/epdsi/eps/:id" element={<EpsEdit />} />

                    <Route path="/epdsi/quizz" element={<Quizz />} />
                </Routes>
            </Ui>
        </BrowserRouter>)
}

export default Router