import { Box } from '@mui/material'
import ListMedicaments from './ListMedicaments'
import CardTable from '../../../../../Components/CardTable/CardTable'
import { UseEpEditContext } from '../useEpsEdit'
import React from 'react'
import { Add } from '@mui/icons-material'

const EPTraitements = () => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    const [open, setOpen] = React.useState(false)
    return (
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
            <Box>
                <ListMedicaments
                    open={open}
                    setOpen={setOpen}
                />
            </Box>
            <CardTable
                datas={ep?.medicaments.map(m => m.presentation) ?? []}
                columns={[
                    {
                        field: 'medicament',
                        header: { label: 'DCI' }, body: d => d.medicament?.dci,
                        search: (data, search) => data.medicament?.dci.toLocaleLowerCase().includes(search) ?? false,
                    },
                    {
                        field: 'name',
                        header: { label: 'Nom' },
                        search: (data, search) => data.name.toLocaleLowerCase().includes(search) ?? false,
                    },
                    {
                        field: 'voie',
                        header: { label: 'Voie' },
                        search: (data, search) => data.voie.toLocaleLowerCase().includes(search) ?? false,
                    },
                    {
                        field: 'dilution',
                        header: { label: 'Dilution' }, body: d => `${d.dilution} ${d.dilutionUnit}`,
                        search: (data, search) => `${data.dilution} ${data.dilutionUnit}`.toLocaleLowerCase().includes(search) ?? false,
                    },
                    {
                        field: 'volume',
                        header: { label: 'Volume' }, body: (d) => `${d.volume} mL`,
                        search: (data, search) => `${data.volume} mL`.toLocaleLowerCase().includes(search) ?? false,
                    },
                ]}
                noCard={true}
                buttons={[
                    { label: 'Ajouter', onClick: () => setOpen(true), isHeader: true, icon: Add },
                ]} />
        </Box>
    )
}

export default EPTraitements