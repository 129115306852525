import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Typography } from '@mui/material'
import React from 'react'
import InputField from '../../../../Components/InputField'
import { AppContext } from '../../../../App'
import { useNavigate } from 'react-router-dom'

interface AdderProps {
    open: boolean,
    toggleOpen: () => void
}
const Adder = ({ open, toggleOpen }: AdderProps) => {
    const navigate = useNavigate()
    const { fetch } = React.useContext(AppContext)
    const [value, setValue] = React.useState({ name: '', period: '2', date: new Date().toISOString().slice(0, 10) })

    const handleAdd = () => fetch('/api/admin/cours', 'POST', { ...value, date: value.date + ' 00:00:00' })
        .then(el => navigate(`/epdsi/cours/${el.id}`))

    React.useEffect(() => {
        setValue({ name: '', period: '2', date: new Date().toISOString().slice(0, 10) })
    }, [open])
    return (
        <Dialog
            open={open}
            onClose={toggleOpen}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                    <Typography variant='h5'>Créer un cours</Typography>
                    <Box>
                        <IconButton onClick={toggleOpen}>
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box>
                        <InputField
                            fullWidth
                            name={'name'}
                            value={value.name}
                            label={'Nom du cours'}
                            onChange={({ target: { name, value } }) => setValue(prev => ({ ...prev, [name]: value }))}
                            autoFocus />
                    </Box>
                    <Box>
                        <InputField
                            fullWidth
                            name={'period'}
                            value={value.period.toString()}
                            select
                            onChange={({ target: { name, value } }) => setValue(prev => ({ ...prev, [name]: value }))}>
                            <MenuItem value="1">Période 1</MenuItem>
                            <MenuItem value="2">Période 2</MenuItem>
                            <MenuItem value="3">Période 3</MenuItem>
                        </InputField>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleOpen}>Annuler</Button>
                <Button onClick={handleAdd} disabled={!value.name}>Créer</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Adder