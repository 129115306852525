import { Box, Divider, FormControlLabel, ListSubheader, MenuItem, Switch } from '@mui/material'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { NutritiveSolutionContext } from '../useNutritiveSolution'

const SettingsTab = () => {
    const { nutritiveSolution, dispatch } = React.useContext(NutritiveSolutionContext)
    const [name, setName] = React.useState<string>('')

    const handleChange = () => name && dispatch.update({ name })


    React.useEffect(() => {
        setName(nutritiveSolution?.name ?? '')
    }, [])
    return (
        <Box display={'flex'} flexDirection={'column'} gap={2} columnGap={2} p={1}>
            <Box>
                <InputField
                    label={'Nom de la solution'}
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                    onBlur={handleChange}
                    onKeyUp={({ key }) => key === 'Enter' && handleChange()}
                    fullWidth />
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box flexGrow={1}>
                    <InputField
                        label={'Type de solution'}
                        value={nutritiveSolution?.type ?? ''}
                        onChange={({ target: { value } }) => dispatch.update({ type: value })}
                        select
                        fullWidth
                    >
                        <ListSubheader >Nutritions</ListSubheader>
                        <MenuItem value='N. parentérale'>N. parentérale</MenuItem>
                        <MenuItem value='N. entérale'>Nutrition entérale</MenuItem>
                        <Divider />
                        <ListSubheader >Suppléments</ListSubheader>
                        <MenuItem value='S. entéral'>Supplément entéral</MenuItem>
                        <MenuItem value='S. oral'>Supplément oral</MenuItem>
                        <MenuItem value='S. parentéral'>Supplément parentéral</MenuItem>
                        <Divider />
                        <MenuItem value="Solutés d'entretien">Solutés d'entretien</MenuItem>
                        <MenuItem value='Sédatif'>Sédatif</MenuItem>
                    </InputField>
                </Box>
                <Box flexGrow={1}>
                    <FormControlLabel label="Archivé" control={<Switch checked={!nutritiveSolution?.isActif} onChange={(_, checked) => dispatch.update({ isActif: !checked })} />} />
                </Box>
            </Box>
        </Box>
    )
}

export default SettingsTab