import React from 'react'
import Page from '../../../../Components/PageInterface/Page'
import CardTable from '../../../../Components/CardTable/CardTable'
import { AppContext } from '../../../../App'
import Adder from './Adder'
import { Edit, MenuBook } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import Logo from '../../../../assets/epdsi.png'

const EPsList = () => {
    const { exploitationsPratiques, dispatch } = React.useContext(AppContext)
    const navigate = useNavigate()
    const [open, toggleOpen] = React.useReducer((o) => !o, false)

    React.useEffect(() => {
        dispatch.exploitationsPratiques.getAll()
    }, [])
    return (
        <Page
            title={'Lise des exploitations de la pratique'}
            rightPlace={<Adder open={open} toggle={toggleOpen} />}
            loading={false}
            breadcrumb={[
                { url: '/epdsi', name: 'EPD SI', src: Logo },
                { url: '/epdsi/eps', name: 'Exploitations de la pratique', letters: 'EP' },
            ]}
            children={
                <CardTable
                    columns={[
                        { field: 'name', header: { label: 'Nom' } },
                        { field: 'ipp', header: { label: 'ipp' } },
                        { field: 'date', header: { label: 'date' } },

                    ]}
                    datas={exploitationsPratiques ?? []}
                    buttons={[
                        {
                            icon: Edit,
                            onClick: (data) => navigate(`/epdsi/eps/${data.id}`),
                            rounded: true,
                            label: 'Editer',
                        }
                    ]}
                    noCard={true}
                />
            }
        />
    )
}

export default EPsList