import { TableHead, TableRow, TableCell, Tooltip, IconButton, Button, TableSortLabel } from '@mui/material'
import React from 'react'
import { TableContext } from './useTable'
import { CardTableColumnConfigObject, Button as ButtonType } from './types'

const Head = () => {
    const { columns, buttons, collapse, isSortable } = React.useContext(TableContext)
    return (
        <TableHead>
            <TableRow>
                {isSortable && <TableCell sx={{ width: '1px' }} />}
                {collapse && <TableCell sx={{ width: '1px' }} />}
                {columns.map(column =>
                    column.order
                        ? <SortableCell column={column} key={column.field} />
                        : <Cell column={column} key={column.field} />
                )}
                {buttons && buttons.length > 0 && <TableCell sx={{ width: '1px' }}>
                    {buttons.filter(b => typeof b !== 'function' && b.isHeader).map((button, index) => typeof button === 'function'
                        ? <></>
                        : <Tooltip key={index} title={button.label ?? ''} disableHoverListener={!button.label}>
                            {button.rounded
                                ? <IconButton onClick={() => button.onClick({})} size='small'>
                                    {button.icon && <button.icon fontSize={'small'} />}
                                </IconButton>
                                : <Button onClick={() => button.onClick({})} size='small'>
                                    {button.icon && <button.icon fontSize={'small'} />}
                                    {button.label}
                                </Button>}

                        </Tooltip>)}
                </TableCell>}
            </TableRow>
        </TableHead>
    )
}


interface CellProps {
    column: CardTableColumnConfigObject<any>
}
const SortableCell = ({ column }: CellProps) => {
    const { sort, dispatch } = React.useContext(TableContext)
    return (
        <TableCell
            sx={column.header.style ?? undefined}
            sortDirection={sort.order}
        >
            <TableSortLabel
                active={sort.field === column.field}
                direction={sort.order}
                onClick={() => dispatch.sort(column.field, sort.order)}
            >
                {column.header.label}
            </TableSortLabel>
        </TableCell>
    )
}
const Cell = ({ column }: CellProps) => {
    return (
        <TableCell
            sx={column.header.style ?? undefined}
        >
            {column.header.label}
        </TableCell>
    )
}

export default Head