import { Box, CardActionArea, CircularProgress } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from './useVideoPlayer'
import Toolbar from './Toolbar/Toolbar'
import { Pause, PlayArrow, RestartAlt } from '@mui/icons-material'
import VideoOverflow from './VideoOverflow'

const MainContainer = () => {
    const { compRef, videoPath, videoRef, status, fullScreen, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <Box
            ref={compRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                aspectRatio: '16/9',
                width: '100%',
                borderRadius: 5,
                backgroundColor: 'black',
                border: theme => `2px solid ${theme.palette.divider}`,
                '&[data-fullscreen="true"]': {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    aspectRatio: 'unset',
                    borderRadius: 0,
                    border: 'unset',
                    zIndex: 10000
                },
                '& .toolbar': {
                    top: 'calc(100% - 2px)',
                    transition: 'bottom 0.3s',
                    '&[data-show="true"]': {
                        bottom: 0,
                        top: 'unset'
                    }
                }
            }}
            data-fullscreen={fullScreen}
        >
            <Box
                flexGrow={1}
                m={fullScreen ? 2 : 0}
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    justifyContent: 'center',
                    // alignItems: 'center',
                }}
            >
                <video
                    ref={videoRef}
                    id="video"
                    style={fullScreen ? {
                        // width: '100%',
                        // height: '100%',
                        objectFit: 'contain',
                        zIndex: 1,
                    } : {
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        zIndex: 1,

                    }}
                    src={videoPath}
                />

            </Box>
            <VideoOverflow />
            <Toolbar />

        </Box>
    )
}

export default MainContainer