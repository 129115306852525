import React from 'react'
import InputField from '../InputField'
import { Help } from '@mui/icons-material'
import { Tooltip, Typography, List, ListItem } from '@mui/material'
import IPASValueType, { IPASValueSystType } from '../../Stores/IPASTypes'
import Systems from './Systems'

interface IPASProps {
    ipasValue: IPASValueType
    changeValue: (value: IPASValueType) => void
}
const IPAS = ({ ipasValue, changeValue }: IPASProps) => {

    const [I, setI] = React.useState<string>('')
    const [P, setP] = React.useState<string>('')
    const [A, setA] = React.useState<string>('')
    const [S, setS] = React.useState<string>('')

    const changeDescription = () => changeValue({ i: I, p: P, a: A, s: S, systemes: ipasValue.systemes ?? undefined })

    const changeSystem = (systemes: undefined | IPASValueSystType) => changeValue({ i: I, p: P, a: A, s: S, systemes })

    React.useEffect(() => {
        setI(ipasValue.i ?? '')
        setP(ipasValue.p ?? '')
        setA(ipasValue.a ?? '')
        setS(ipasValue.s ?? '')
    }, [ipasValue])

    return (
        <React.Fragment>
            <InputField
                label="Identification"
                value={I}
                onChange={({ target: { value } }) => setI(value)}
                onBlur={() => changeDescription()}
                multiline
                fullWidth
                InputProps={{
                    endAdornment: <Tooltip title={<>
                        <Typography>Gravité</Typography>
                        <List>
                            <ListItem>
                                Stable
                            </ListItem>
                            <ListItem>
                                Suivi rapproché
                            </ListItem>
                            <ListItem>
                                Instable
                            </ListItem>
                        </List>
                    </>}>
                        <Help />
                    </Tooltip>
                }}
            />
            <InputField
                label="Patient"
                value={P}
                onChange={({ target: { value } }) => setP(value)}
                onBlur={() => changeDescription()}
                multiline
                fullWidth
                InputProps={{
                    endAdornment: <Tooltip title={<>
                        <Typography>Résumé</Typography>
                        <List>
                            <ListItem>
                                événement ayant conduit à l'admission
                            </ListItem>
                            <ListItem>
                                parcours dans l'établissement
                            </ListItem>
                            <ListItem>
                                évaluation continue
                            </ListItem>
                            <ListItem>
                                Plan de soins
                            </ListItem>
                        </List>
                    </>}>
                        <Help />
                    </Tooltip>
                }}
            />
            <Systems ipas={ipasValue} onChange={changeSystem} />

            <InputField
                label="Actions"
                value={A}
                onChange={({ target: { value } }) => setA(value)}
                onBlur={() => changeDescription()}
                multiline
                fullWidth
                InputProps={{
                    endAdornment: <Tooltip title={<>
                        <Typography>A entreprendre</Typography>
                        <List>
                            <ListItem>
                                liste des actions à entreprendre
                            </ListItem>
                            <ListItem>
                                temporalité et responsablité
                            </ListItem>
                        </List>
                    </>}>
                        <Help />
                    </Tooltip>
                }}
            />
            <InputField
                label="Situation"
                value={S}
                onChange={({ target: { value } }) => setS(value)}
                onBlur={() => changeDescription()}
                multiline
                fullWidth
                InputProps={{
                    endAdornment: <Tooltip title={<>
                        <Typography>Conscience de la situation</Typography>
                        <List>
                            <ListItem>
                                savoir ce qui se passe
                            </ListItem>
                            <ListItem>
                                planifier en vue de ce qui pourrait se passer
                            </ListItem>
                            <ListItem>
                                passer en revue les enjeux de la sécurité
                            </ListItem>
                        </List>
                    </>}>
                        <Help />
                    </Tooltip>
                }}
            />
            {/* <Systems ipas={ipasValue} onChange={changeSystem} /> */}
        </React.Fragment>
    )
}

export default IPAS