import { Help } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'

const TextTooltip = () => {
    return (
        <Tooltip title={<TooltipHelper />}>
            <IconButton>
                <Help />
            </IconButton>
        </Tooltip>
    )
}

const TooltipHelper = () =>
    <Box>
        <Box display={'flex'}>
            <Box>
                <Typography variant="h6">Listes à puces</Typography>
                <Box display={'flex'}>
                    <code>
                        <div>- text</div>
                        <div>- text</div>
                        <div>-- text</div>
                        <div>-- text</div>
                    </code>
                    <ul>
                        <li>text</li>
                        <li>text</li>
                        <ul>
                            <li>text</li>
                            <li>text</li>
                        </ul>
                    </ul>
                </Box>
            </Box>
        </Box>
    </Box>


export default TextTooltip