import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Task } from 'gantt-task-react'
import React from 'react'
import InputField from '../../../../../Components/InputField'
import { UseEpEditContext } from '../useEpsEdit'
import { CirclePicker } from 'react-color'

const Edit = () => {
    const { editedTask, dispatch } = React.useContext(UseEpEditContext)

    const [task, setTask] = React.useState<Task>()

    React.useEffect(() => {
        setTask(editedTask)
    }, [editedTask])

    return (
        <Dialog
            open={Boolean(editedTask)}
            onClose={() => { }}
        >
            <DialogTitle>
                Edition de la tâche – {editedTask?.name ?? ''}
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box>
                        <InputField
                            label="Heure de début"
                            type='number'
                            value={task?.start.getHours()}
                            onChange={e => setTask({ ...task!, start: new Date(task!.start.setHours(Number(e.target.value))) })}
                        />
                        <InputField
                            label="Minute de début"
                            type='number'
                            value={task?.start.getMinutes()}
                            onChange={e => setTask({ ...task!, start: new Date(task!.start.setMinutes(Number(e.target.value))) })}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label="Heure de fin"
                            type='number'
                            value={task?.end.getHours()}
                            onChange={e => setTask({ ...task!, end: new Date(task!.end.setHours(Number(e.target.value))) })}
                        />
                        <InputField
                            label="Minute de fin"
                            type='number'
                            value={task?.end.getMinutes()}
                            onChange={e => setTask({ ...task!, end: new Date(task!.end.setMinutes(Number(e.target.value))) })}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label="Titre"
                            value={task?.name}
                            onChange={e => setTask({ ...task!, name: e.target.value })}
                            fullWidth
                        />
                    </Box>
                    <Box p={2}>
                        <CirclePicker
                            color={task?.styles?.backgroundColor}
                            // onChange={color => setTask({ ...task!, color: color.hex })}
                            onChangeComplete={color => setTask({ ...task!, styles: { ...task!.styles, backgroundColor: color.hex, backgroundSelectedColor: color.hex } })}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch.editTask(task!)}>Valider</Button>
                <Button onClick={() => dispatch.selectEditTask(undefined)}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Edit