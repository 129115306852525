import React from "react";
import { useSolutionDispatchType, useSolutionType } from "./types";
import { AppContext } from "../../../../App";
import NutritiveSolution from "../../../../Stores/NutritiveSolutionType";

const useSolution: useSolutionType = () => {
    const { nutritiveSolutions, dispatch: AppDispatch } = React.useContext(AppContext)

    const [cibleCalorique, setCibleCalorique] = React.useState<number>(0);
    const [availableSolutions, setAvailableSolutions] = React.useState<NutritiveSolution[] | undefined>(nutritiveSolutions ?? []);
    const [selectedSolutions, setSelectedSolutions] = React.useState<NutritiveSolution[]>([]);


    const dispatch: useSolutionDispatchType = React.useMemo(() => ({
        setCibleCalorique,

        addSolution: (solution: NutritiveSolution) => setSelectedSolutions(prev => [...prev, solution]),
        removeSolution: (solution: NutritiveSolution) => setSelectedSolutions(prev => prev.filter(el => el.id !== solution.id)),

    }), [
        cibleCalorique,
        availableSolutions,
        selectedSolutions
    ]);

    React.useEffect(() => {
        AppDispatch.nutritiveSolutions
            .getAll(el => el && setAvailableSolutions(el))
            .then(el => el && setAvailableSolutions(el))

    }, [])

    return {
        cibleCalorique,


        availableSolutions,
        selectedSolutions,


        dispatch
    }
}

export default useSolution