import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Divider, IconButton, Drawer as MuiDrawer, Typography } from '@mui/material'
import React from 'react'
import { UseEpEditContext } from '../useEpsEdit'
import ImageList from './ImageList'

const Drawer = () => {
    const [open, toggleOpen] = React.useReducer(o => !o, false)
    const { ep } = React.useContext(UseEpEditContext)
    if (!ep) return (<></>)
    return (
        <>
            <IconButton onClick={toggleOpen}>
                <Visibility color='primary' />
            </IconButton>
            <MuiDrawer
                anchor={'right'}
                open={open}
                onClose={toggleOpen}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 500,
                    maxWidth: '50vw',
                    height: '100%',
                    overflowY: 'hidden',
                }}>
                    <Box sx={{ mt: 8, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography variant='h3'>{ep?.name}</Typography>
                            <Typography variant='caption'>IPP : {ep?.ipp}</Typography>
                        </Box>
                        <Box>
                            <ImageList />
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, overflowY: 'auto' }}>
                        {ep?.ipas?.i.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'} sx={{ mt: i === 0 ? 1 : undefined }}>{t}</Typography>)}
                        {ep?.ipas?.p.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'} sx={{ mt: i === 0 ? 1 : undefined }}>{t}</Typography>)}
                        {ep?.ipas?.systemes && <>
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>HDM</Typography>
                            {ep?.ipas?.systemes.hdm.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Respiratoire</Typography>
                            {ep?.ipas?.systemes.resp.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Neurologique</Typography>
                            {ep?.ipas?.systemes.neuro.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Digestif</Typography>
                            {ep?.ipas?.systemes.dig.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Rénal</Typography>
                            {ep?.ipas?.systemes.renal.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Cutané</Typography>
                            {ep?.ipas?.systemes.cut.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                            <Typography sx={{ textDecoration: 'underline', mt: 2 }}>Social</Typography>
                            {ep?.ipas?.systemes.social.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'}>{t}</Typography>)}
                        </>}
                        {ep?.ipas?.a.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'} sx={{ mt: i === 0 ? 1 : undefined }}>{t}</Typography>)}
                        {ep?.ipas?.s.split("\n").map((t, i) => <Typography key={i} textAlign={'justify'} sx={{ mt: i === 0 ? 1 : undefined }}>{t}</Typography>)}
                    </Box>
                </Box>
            </MuiDrawer>
        </>
    )
}

export default Drawer