import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'

const Speed = () => {
    const { playbackRate, dispatch } = React.useContext(VideoPlayerContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null)
    const speeds = [1, 1.25, 1.5, 1.75, 2]
    const handleClick = (speed: number) => {
        setAnchorEl(null)
        dispatch.setPlaybackRate(speed)
    }
    return (
        <React.Fragment>
            <IconButton color={'inherit'} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                <Typography variant={'overline'}>
                    {playbackRate}x
                </Typography>
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    zIndex: 10000
                }}
            >

                {speeds.map(speed => (
                    <MenuItem key={speed} onClick={() => handleClick(speed)}>
                        {speed}x
                    </MenuItem>
                ))}

            </Menu>
        </React.Fragment>
    )
}

export default Speed