import React from "react";
import { dispatchKanbanType, useKanbanContextType, useKanbanType } from "./types";
import { arrayMove } from "@dnd-kit/sortable";
import { v4 } from 'uuid'
import { AppContext } from "../../App";
import { CheckList, CheckListElement, Kanban, KanbanCard } from "../../Stores/KanbanType";

const useKanban: useKanbanType = () => {
    const { fetch, kanbans: kanbansStore, dispatch: dispatchStore } = React.useContext(AppContext)
    const [kanbans, setKanbans] = React.useState<Kanban[]>([])
    const [dragId, setDragId] = React.useState<string | null>(null);
    const [editedCard, setEditedCard] = React.useState<KanbanCard | null>(null)
    const dragCard = React.useMemo(() => {
        if (!dragId) return null;
        for (let i = 0, mx = kanbans.length; i < mx; i++) {
            const card = kanbans[i].cards?.find(c => c.id === dragId)
            if (card) return card
        }
    }, [dragId, kanbans])
    const tags = React.useMemo(() => {
        return (kanbans?.map(k => k.cards?.map(c => c.tags ?? undefined).flat()).flat().filter(t => t !== undefined).sort((a, b) => a!.toLocaleLowerCase() < b!.toLocaleLowerCase() ? -1 : 1).filter((t, i, s) => s.indexOf(t) === i) ?? []) as string[]
    }, [kanbans])

    const dispatch: dispatchKanbanType = React.useMemo(() => ({
        dragStart: ({ active }) => setDragId(active.id as string | null),
        dragCancel: () => setDragId(null),
        dragOver: ({ active, over }) => {
            const overId = over?.id;
            if (!over?.id) {
                return;
            }
            const activeContainer = active?.data?.current?.sortable.containerId;
            const overContainer: string = over.data.current?.sortable.containerId || over.id;

            if (!activeContainer || !overContainer) return

            if (activeContainer !== overContainer) {
                const card = active!.data!.current!.card
                const activeIndex = active!.data!.current!.sortable.index;

                const overIndex =
                    kanbans.map(k => k.id).includes(over!.id as string)
                        ? kanbans.findIndex(k => k.id === overContainer) + 1
                        : over!.data?.current?.sortable.index;

                if (overIndex || overIndex === 0) dispatch.moveCardOtherKanban(activeContainer, overContainer, card, activeIndex, overIndex)
            }
        },
        dragEnd: ({ active, over }) => {
            if (!over) {
                setDragId(null);
                return;
            }

            if (active.id !== over.id) {
                const activeContainer = active?.data?.current?.sortable.containerId;
                const overContainer = over.data.current?.sortable.containerId || over.id;
                if (activeContainer && overContainer) {
                    const activeIndex = active?.data?.current?.sortable.index;
                    const overIndex =
                        kanbans.map(k => k.id).includes(over.id as string)
                            ? kanbans.findIndex(k => k.id === overContainer) + 1
                            : over?.data?.current?.sortable.index;
                    const card = active?.data?.current?.card

                    if (activeContainer === overContainer) {
                        dispatch.moveCardSameKanban(activeContainer, activeIndex, overIndex)
                    } else {
                        dispatch.moveCardOtherKanban(activeContainer, overContainer, card, activeIndex, overIndex)
                    }
                }

            }
            setDragId(null);
        },
        moveCardSameKanban: (kanbanId, oldIndex, newIndex) => {
            fetch(`/api/admin/kanbans/${kanbanId}/sort/cards`, 'PATCH', { oldIndex, newIndex })
            setKanbans(prev => prev!.map(k => k.id === kanbanId ? ({ ...k, cards: arrayMove(k.cards, oldIndex, newIndex) as Kanban['cards'] }) : k))
        },
        moveCardOtherKanban: (kanbanOldId, kanbanNewId, card, oldIndex, newIndex) => {
            fetch(`/api/admin/kanban-cards/${card.id}`, 'PATCH', { kanbanId: kanbanNewId, position: newIndex })
            setKanbans(prev => prev!.map(k =>
                k.id === kanbanOldId
                    ? ({ ...k, cards: k.cards.filter(c => c.id !== card.id) as Kanban['cards'] })
                    : k.id === kanbanNewId
                        ? ({ ...k, cards: arrayMove([...k.cards, card], k.cards.length, newIndex + 1) as Kanban['cards'] })
                        : k
            ))
        },

        addColumn: async () => fetch(`/api/admin/kanbans`, 'POST').then(kanban => kanban && setKanbans(prev => prev ? [...prev, kanban] : [kanban])),
        updateColumn: async (kanbanId, partial) => fetch(`/api/admin/kanbans/${kanbanId}`, 'PATCH', partial),
        deleteKanban: async (kanbanId) => fetch(`/api/admin/kanbans/${kanbanId}`, 'DELETE'),

        addCard: async (id: Kanban['id']) => fetch(`/api/admin/kanbans/${id}/cards`, 'POST').then((card: KanbanCard) => {
            setKanbans(prev => prev!.map(k => k.id === id ? ({ ...k, cards: [...k.cards, card] as Kanban['cards'] }) : k))
        }),
        deleteCard: async (cardId: KanbanCard['id']) => {
            fetch(`/api/admin/kanban-cards/${cardId}`, 'DELETE')
            setEditedCard(null)
            setKanbans(prev => prev!.map(k => ({ ...k, cards: k.cards.filter(c => c.id !== cardId) as Kanban['cards'] })))
        },
        setEditedCard: (card: KanbanCard | null) => setEditedCard(card),
        updateCard: async (partialCard) => {
            setKanbans(prev => prev!.map(kanban => ({ ...kanban, cards: kanban.cards.map(card => card.id === partialCard.id ? ({ ...card, ...partialCard }) : card) as Kanban['cards'] })))
            fetch(`/api/admin/kanban-cards/${partialCard.id}`, 'PATCH', partialCard)
        },

        addCheckList: async () => {
            const cl = { id: v4(), name: 'Nouvelle check-liste', els: [], comments: [] }
            setEditedCard(prev => prev ? ({ ...prev!, cl: [...(prev!.cl ?? []), cl] }) : null)
        },
        updateCheckList: async (clId: string, partial: Partial<CheckList>) => {
            setEditedCard(prev => ({ ...prev!, cl: prev!.cl!.map(c => c.id === clId ? ({ ...c, ...partial }) : c) }))
        },
        removeCheckList: async (clId: string) => {
            setEditedCard(prev => ({ ...prev!, cl: prev!.cl!.filter(c => c.id !== clId) }))
        },

        addCheckListElement: async (clId: string, name: string) => {
            const cle: CheckListElement = { id: v4(), name, status: false }
            setEditedCard(prev => prev ? ({ ...prev!, cl: prev.cl.map(cl => cl.id === clId ? ({ ...cl, els: [...cl.els, cle] }) : cl) }) : null)
        },
        removeCheckListElement: async (clElId: string) => {
            setEditedCard(prev => ({ ...prev!, cl: prev!.cl.map(cl => ({ ...cl, els: cl.els.filter(el => el.id !== clElId) })) }))
        },
        updateCheckListElement: async (clElId: string, partial: Partial<CheckListElement>) => {
            setEditedCard(prev => ({ ...prev!, cl: prev!.cl.map(cl => ({ ...cl, els: cl.els.map(el => el.id === clElId ? ({ ...el, ...partial }) : el) })) }))
        },

        addComment: async (cardId: string, message: string) => fetch(`/api/admin/kanban-cards/${cardId}/comments`, 'POST', { message })
            .then(comment => {
                setEditedCard(prev => ({ ...prev!, comments: [...prev!.comments, comment] }))
                setKanbans(prev => prev!.map(k => ({ ...k, card: k.cards.map(c => c.id === cardId ? ({ ...c, comments: [...c.comments, comment] }) : c) })))
            })


    }), [])

    React.useEffect(() => {
        if (kanbans && JSON.stringify(kanbans) !== JSON.stringify(kanbansStore)) dispatchStore.kanbans.posts(kanbans)
    }, [kanbans])

    React.useEffect(() => {
        setKanbans(kanbansStore ?? [])
        dispatchStore.kanbans.getAll().then((els) => els && setKanbans)
    }, [])

    return {
        dragId, dragCard, editedCard, kanbans, dispatch, tags
    }
}

export const KanbanContext = React.createContext<useKanbanContextType>({} as useKanbanContextType)

export default useKanban