import { Box, CircularProgress, useTheme } from "@mui/material"
import { PageInterfaceProps, usePageContextType } from "."
import usePage from "./usePage"
import React from "react"
import Header from "./Header"
import { TabContext, TabPanel } from "@mui/lab"
import Content from "./Content"
import LogoAleph from "../LogoAleph"
import useFileManager from "../FileManager/useFileManager"

export const PageContext = React.createContext<usePageContextType>({} as usePageContextType)

const Page = ({ title, subtitle, breadcrumb, children, image, rightPlace, fullPage, tabs, loading, resetTab, fileManagerConfig, tabButton }: PageInterfaceProps & React.PropsWithChildren) => {
    const pageContextValue = usePage({ title, subtitle, breadcrumb, image, rightPlace, tabs, loading, resetTab, fileManagerConfig, tabButton })
    const fileManager = useFileManager(pageContextValue.fileManagerConfig, pageContextValue?.configFileSelector)
    const theme = useTheme()

    if (loading) return (
        <Box flexGrow={1} className={'loader'}>
            <Box position={'absolute'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexGrow={1} height={'100%'} width={'100%'}>
                <CircularProgress size={200} thickness={1} />
            </Box>
            <Box position={'absolute'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexGrow={1} height={'100%'} width={'100%'}>
                <LogoAleph size={150} color={'info'} />
            </Box>
        </Box>)
    return (
        <PageContext.Provider value={pageContextValue}>
            <TabContext value={pageContextValue.tab}>
                {fileManager?.renderFileBox}
                {fileManager?.renderSelector}
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    role="PageInterface"
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            ...(fullPage ? { height: '100%', overflowY: 'hidden' } : {})
                        }
                    }}
                >
                    <Header />
                    {tabs
                        ? <>
                            {tabs.map((t) => <TabPanel value={t.label} sx={{ p: 0, flexGrow: 1, overflowY: 'auto', height: '100%' }} children={<Content children={t.component} fullPage={true} />} key={t.label} />)}
                            {fileManager?.renderFileTable && <TabPanel value={'filelist'} sx={{ p: 0, flexGrow: 1, overflowY: 'auto', height: '100%' }} children={<Content children={fileManager?.renderFileTable} fullPage={true} />} />}
                        </>
                        : <Content children={children} fullPage={fullPage} />}
                </Box>
            </TabContext>

        </PageContext.Provider>
    )
}

export default Page