import React from 'react'
import useQuizz, { QuizzContext } from './useQuizz'
import { Box } from '@mui/material'
import Config from './Config'
import DisplayQuestion from './DisplayQuestion'

const Quizz = () => {
    const context = useQuizz()
    return (
        <QuizzContext.Provider value={context}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'100%'}
                p={1}
            >
                {context.loading ? 'Chargement...'
                    : context.questions.length === 0 ? <Config /> : <DisplayQuestion />}
            </Box>
        </QuizzContext.Provider>
    )
}

export default Quizz
