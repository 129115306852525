import React from 'react'
import InvoiceClient from '../../Stores/InvoiceClientType'
import { Box, Typography } from '@mui/material'

interface InvoiceClientRepProps {
    invoiceClient: InvoiceClient
}
const InvoiceClientRep = ({ invoiceClient }: InvoiceClientRepProps) => {
    return (
        <Box sx={{p:1}}>
            <Typography variant="h6">{invoiceClient.name}</Typography>
            <Typography variant="caption" component={'div'}>{invoiceClient.adress}</Typography>
            <Typography variant="caption" component={'div'}>{invoiceClient.postalCode} {invoiceClient.city}</Typography>
            <Typography variant="caption" component={'div'}>{invoiceClient.country}</Typography>
        </Box>
    )
}

export default InvoiceClientRep