import { Add, Delete } from '@mui/icons-material'
import React from 'react'
import CardTable from '../../../../../../Components/CardTable/CardTable'
import InputField from '../../../../../../Components/InputField'
import { PlannifFieldsType, UseEpEditContext } from '../../useEpsEdit'

interface PlannifProps {
    field: PlannifFieldsType
    label: string
}
const Plannif = ({ field, label }: PlannifProps) => {
    const { ep, dispatch } = React.useContext(UseEpEditContext)
    if (!ep) return (<></>)
    return (
        <CardTable
            columns={[
                {
                    //@ts-ignore
                    field,
                    header: { label },
                    body: (data) => <InputField key={data.id} label={label} defaultValue={data.priority} onBlur={({ target: { value } }) => dispatch.changePlannif(field, data.id, { priority: value })} fullWidth />
                },
                {
                    field: 'arg',
                    header: { label: 'Argumentation (pourquoi chez ce patient, ce jour et face à quels risques)', style: { minWidth: '50%' } },
                    body: data => <InputField key={data.id} onBlur={({ target: { value } }) => dispatch.changePlannif(field, data.id, { arg: value })} label="Argumentation" defaultValue={data.arg} fullWidth multiline />
                },
            ]}
            datas={ep[field].sort((a, b) => (a.position ?? 0) < (b.position ?? 0) ? -1 : 1) ?? []}
            buttons={[
                { isHeader: true, label: 'Ajouter', icon: Add, onClick: () => dispatch.addPlannif(field), rounded: true },
                { label: 'Supprimer', icon: Delete, onClick: (data) => dispatch.removePlannif(field, data.id), color: 'error', rounded: true }
            ]}
            noCard={true}
            sortable={{
                onSortEnd: (oldIndex, newIndex) => dispatch.sortPlannif(field, oldIndex, newIndex)
            }}
        />
    )
}

export default Plannif